import { PageList } from "../../components/pageList";
import React from "react";

import ActionCellRender from "../../components/actionCellRender";
import { IconPencil, IconTrash, IconSettings } from "@tabler/icons";
import {
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import { useCellRender } from "../../hooks/useCellRender";
import FileList from "../../components/fileList";
import ActivityLog from "../../components/activityLog.js";
import RemoteSelect2 from "../../components/remoteSelect2";
import _ from "lodash";

import {
  Select,
  Checkbox,
  TextInput,
  Text,
  Textarea,
  Switch,
  Badge,
  Group,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import moment from "moment";
import QuotationDescription from "../../components/quotationDescription";
import QuotationTerms from "../../components/quotationTerm";
import DebitNoteChargeItems from "../../components/debiteNoteChargeItems.js";
import DebitNoteCostItems from "../../components/debiteNoteCostItems.js";
import DebitNoteActionList from "../../components/debitNoteActionList";
import QuotationChargeSummary from "../../components/quotationChargeSummary";
import DummyWidget from "../../components/dummyWidget";
import OrderInsuredList from "../../components/orderInsuredList";
import OrderAddressSelect from "../../components/orderAddressSelect";
import DebitNotePageStats from "../../components/debitNotePageStats";
import OrderConsultantList from "../../components/orderConsultantList";
import OrderApplicantDetail from "../../components/orderApplicantDetail";
import DebitNoteSummary from "../../components/debitNoteSummary";
import DebitNoteTimeline from "../../components/debitNoteTimeline";
import remoteSelect2 from "../../components/remoteSelect2";
import OrderClientSelect from "../../components/orderClientSelect";
import ProductSelection from "../../components/productSelection";
import ConsultantList from "../../components/consultantList";
import FormConfirmation from "../../components/formConfirmation";
import UniqueTextInput from "../../components/uniqueTextInput";
import QuoRequestFileCheckList from "../../components/quoRequestFileCheckList";
import QuoFileList from "../../components/quofileList";
import DebitNotePaymentSummary from "../../components/debitNotePaymentSummary";

import DebitNoteRelatedDocumentSection from "../../components/debitNoteRelatedDocumentSection.js";
import ClientPaymentList from "../../components/clientPaymentList.js";
import RichTextEditor from "../../components/richTextEditor";
import { useAuthUser } from "react-auth-kit";

const apiEntity = "debitNote";

const initialValues = {
  consultants: [],
  type: "NEW",
  date: moment().toISOString(),
  descriptions: [{ title: "", detail: "" }],
  terms: [{ title: "", detail: "" }],
  period: {
    start: null,
    end: null,
  },
  chargeItems: [
    { item: "Premium", currency: "HKD", qty: 1, unitPrice: 0, price: 0 },
    { item: "IA Levy", currency: "HKD", qty: 1, unitPrice: 0, price: 0 },
  ],
  costItems: [
    { item: "Net Premium", currency: "HKD", qty: 1, unitPrice: 0, price: 0 },
    { item: "IA Levy", currency: "HKD", qty: 1, unitPrice: 0, price: 0 },
  ],
  discountAmount: 0,
  total: 0,
  logs: [],
  signedBy: { _id: "64198bbc7378cf27c26f4998" },
  status: "HOLD",
  holdCover: {
    date: moment().toISOString(),
    remark: "",
  },
  insuredValue: 0,
  clientFixRate: process.env.REACT_APP_DEBIT_NOTE_CHARGE_DEFAULT_CLIENT_FIX_RATE
    ? +process.env.REACT_APP_DEBIT_NOTE_CHARGE_DEFAULT_CLIENT_FIX_RATE
    : 0,
  providerFixRate: process.env
    .REACT_APP_DEBIT_NOTE_COST_DEFAULT_PROVIDER_FIX_RATE
    ? +process.env.REACT_APP_DEBIT_NOTE_COST_DEFAULT_PROVIDER_FIX_RATE
    : 0,
};

export const addFormSchema = {
  name: "debitNoteSchema",
  title: "Create Debit Note",
  description: "Please follow the step to create a New Debit Note",
  display: {
    mode: ENUM_FORM_DISPLAY.MODAL,
  },
  apiEntity,
  showSaveBar: false,

  initialValues,
  validate: {
    client: (value) => (!value ? "Please select Client" : null),
    product: (value) => (!value ? "Please select Product" : null),
    consultants: (value) => {
      if (_.isEmpty(value)) return "Please select Consultant";

      return null;
    },
  },

  layout: {
    containers: [
      {
        key: "t1",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,

        props: {
          variant: "default", //default | pills | outline
          defaultValue: "DN Code",
          orientation: "vertical",
          style: { minHeight: "400px" },
        },
        steps: [
          { key: "t1-0", label: "DN Code", icon: IconSettings },

          { key: "t1-1", label: "Applicant", icon: IconSettings },
          { key: "t1-2", label: "Consultant", icon: IconSettings },
          { key: "t1-3", label: "Product", icon: IconSettings },
          { key: "t1-4", label: "Policy", icon: IconSettings },

          { key: "t1-5", label: "Confirmation", icon: IconSettings },
        ],
      },
    ],
    fields: [
      {
        name: "type",
        component: Select,
        parent: "t1-0",
        props: {
          label: "Type",
          data: [
            { label: "New", value: "NEW" },
            { label: "Renewal", value: "RENEW" },
            { label: "Endorsement", value: "ENDOSE" },
          ],
        },
      },
      {
        name: "code",
        component: UniqueTextInput,
        parent: "t1-0",
        props: {
          required: true,
          uniqueEntity: "debitNote",
          errorMessage: "Debit Note is already exist.",
          label: "Code (Auto if empty)",
          placeholder: "DN23100011",
          mt: 0,
        },
      },

      {
        name: "client",
        component: remoteSelect2,
        parent: "t1-1",
        props: {
          apiEntity: "client",
          required: true,
          label: "Applicant",
          placeholder: "Search Client By Name / HKID/ BR ",
          valueField: "_id",
          labelField: "name",
          allowClear: true,
          labelRender: (data) =>
            `[ ${data.id ?? " -- "} ] ${data.name} ${
              data.cname ? `(${data.cname})` : ""
            } `,
          //   labelRender: (data) => `${data.cname}`,
          multiple: false,
          // preQuery: { $and: [ {isConsultant : true}, {isActive: true} ] },
          // preQuery:  {isConsultant : true} ,
          searchFields: ["name", "id", "cname"],
          clearable: true,
          onDataChange: (value, form) => {
            //Set Consultant
            if (!value || _.isEmpty(value.consultants))
              form.setFieldValue("consultants", []);
            else {
              form.setFieldValue(
                "consultants",
                value.consultants.map((c) => c._id)
              );
            }
          },
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },
      {
        name: "client2",
        component: OrderClientSelect,
        parent: "t1-1",
        props: {},
      },

      {
        name: "consultants",
        component: remoteSelect2,
        parent: "t1-2",
        props: {
          apiEntity: "user",
          required: true,
          label: "Consultants",
          placeholder: "Please select consultant(s)",
          valueField: "_id",
          labelField: "name",
          labelRender: (data) =>
            `${data.name}, ${data.aliasName} (${data.staffId ?? " -- "})`,
          multiple: true,
          preQuery: {
            $and: [{ isConsultant: true }, { status: "ACTIVE" }],
          },
          searchFields: [
            "aliasName",
            "name",
            "staffId",
            "cname",
            "email",
            "phone",
          ],
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },

      {
        name: "consultantList",
        component: ConsultantList,
        parent: "t1-2",
        props: {
          field: "consultants",
        },
      },

      {
        name: "product",
        component: ProductSelection,
        parent: "t1-3",
        props: {
          title: "Product",
          required: true,
          showProductSnapShot: false,
        },
      },

      {
        name: "policyNumber",
        component: TextInput,
        parent: "t1-4",
        props: {
          label: "Policy Number",
          showProductSnapShot: false,
        },
      },

      {
        name: "period.start",
        component: DatePicker,
        parent: "t1-4",
        type: "datePicker",

        props: {
          label: "Period From",
          zIndex: 1000,
          placeholder: "Pick Date",
          allowFreeInput: true,
        },
      },

      {
        name: "period.end",
        component: DatePicker,
        type: "datePicker",
        parent: "t1-4",
        props: {
          label: "Period To",
          zIndex: 1000,
          placeholder: "Pick Date",
          allowFreeInput: true,
        },
      },

      {
        name: "date",
        component: DatePicker,
        type: "datePicker",
        parent: "t1-4",
        props: {
          label: "Debit Note Date",
          zIndex: 1000,
          placeholder: "Pick Date",
          allowFreeInput: true,
        },
      },
      {
        name: "holdCover.date",
        component: DatePicker,
        type: "datePicker",
        parent: "t1-4",
        props: {
          label: "Hold Cover Date",
          zIndex: 1000,
          placeholder: "Pick Date",
          allowFreeInput: true,
        },
      },

      {
        name: "confirm",
        component: FormConfirmation,
        parent: "t1-5",
        props: {
          successText: [
            "Please confirm only if the provided information is correct.",
          ],
        },
      },
    ],
  },
};

export const adjustFormSchema = {
  name: "debitNoteSchema",
  title: "Adjust Debit Note",
  description: "Please follow the step to  adjust the Debit Note",
  display: {
    mode: ENUM_FORM_DISPLAY.MODAL,
  },
  apiEntity,
  showSaveBar: false,

  initialValues,

  layout: {
    containers: [
      {
        key: "t1",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,

        props: {
          variant: "default", //default | pills | outline
          defaultValue: "DN Code",
          orientation: "vertical",
          style: { minHeight: "400px" },
        },
        steps: [
          { key: "t1-0", label: "DN Code", icon: IconSettings },
          { key: "t1-3", label: "Product", icon: IconSettings },
          { key: "t1-4", label: "Policy", icon: IconSettings },

          { key: "t1-5", label: "Confirmation ", icon: IconSettings },
        ],
      },
    ],
    fields: [
      {
        name: "type",
        component: Select,
        parent: "t1-0",
        props: {
          label: "Type",
          data: [
            { label: "New", value: "NEW" },
            { label: "Renewal", value: "RENEW" },
            { label: "Endorsement", value: "ENDOSE" },
          ],
        },
      },
      {
        name: "code",
        component: UniqueTextInput,
        parent: "t1-0",
        props: {
          required: true,
          uniqueEntity: "debitNote",
          errorMessage: "Debit Note is already exist.",
          label: "Code (Auto if empty)",
          placeholder: "DN23100011",
          mt: 0,
        },
      },

      {
        name: "product",
        component: ProductSelection,
        parent: "t1-3",
        props: {
          title: "Product",
          showProductSnapShot: false,
        },
      },

      {
        name: "policyNumber",
        component: TextInput,
        parent: "t1-4",
        props: {
          label: "Policy Number",
          showProductSnapShot: false,
        },
      },

      {
        name: "period.start",
        component: DatePicker,
        parent: "t1-4",
        type: "datePicker",

        props: {
          label: "Period From",
          zIndex: 1000,
          placeholder: "Pick Date",
          allowFreeInput: true,
        },
      },

      {
        name: "period.end",
        component: DatePicker,
        type: "datePicker",
        parent: "t1-4",
        props: {
          label: "Period To",
          zIndex: 1000,
          placeholder: "Pick Date",
          allowFreeInput: true,
        },
      },

      {
        name: "date",
        component: DatePicker,
        type: "datePicker",
        parent: "t1-4",
        props: {
          label: "Debit Note Date",
          zIndex: 1000,
          placeholder: "Pick Date",
          allowFreeInput: true,
        },
      },

      {
        name: "holdCover.date",
        component: DatePicker,
        type: "datePicker",
        parent: "t1-4",
        props: {
          label: "Hold Cover Date",
          zIndex: 1000,
          placeholder: "Pick Date",
          allowFreeInput: true,
        },
      },
      {
        name: "confirm",
        component: FormConfirmation,
        parent: "t1-5",
        props: {
          successText: [
            "Please confirm only if the provided information is correct.",
          ],
        },
      },
    ],
  },
};

const DebitNote = ({}) => {
  const [cellRender] = useCellRender();
  const auth = useAuthUser();
  const userRole = auth()?.userRole?.name?.toUpperCase();
  const showCostItems =
    _.isEmpty(process.env.REACT_APP_DEBIT_NOTE_SHOW_COST_ITEMS_USER_ROLE) ||
    process.env.REACT_APP_DEBIT_NOTE_SHOW_COST_ITEMS_USER_ROLE?.split(
      ","
    ).includes(userRole);

  const tableSchema = {
    showActionColumn: true,
    copyToNewFields: [
      "type",
      "client",
      "commissionRate",
      "product",
      "discountAmount",
      "clientPayable",
      "period",
      "consultants",
      "signedBy",
      "checkList",
      "fileList",
      "to",
      "address",
      "addressStr",
      "descriptions",
      "terms",
      "chargeItems",
      "costItems",
      "refundItems",
      "product",
      "order",
      "total",
      "costTotal",
      "profit",
      "currency",
      "files",
      "insured",
    ],
    columns: [
      {
        field: "code",
        headerName: "DN",
        sortable: true,
        cellRender: (col, data) => cellRender.DebitNoteCode(data),
        filterable: true,
        filter: {
          apiEntity: "debitNote",
          field: "status",
          label: "DN Status",
          width: 250,
          height: 300,
        },
      },
      {
        field: "paymentStatus",
        headerName: "Payment",
        sortable: true,
        filterable: true,
        filter: {
          apiEntity: "debitNote",
          field: "paymentStatus",
          label: "Payment Status",
          width: 250,
          height: 300,
        },
        cellRender: (col, data) => (
          <>
            {cellRender.DebitNotePaymentStatus(data.paymentStatus)}
            <div>{cellRender.Currency(data?.clientPayable ?? 0)}</div>

            <Text
              color={
                !data?.payment?.amount
                  ? "red"
                  : data?.payment?.amount < data.clientPayable
                  ? "red"
                  : "green"
              }
            >
              {cellRender.Currency(data?.payment?.amount ?? 0)}
            </Text>

            {data.discountAmount > 0 && (
              <span>
                <Badge size="xs" radius={0} color="red" mt="xs" mb={1}>
                  Discount
                </Badge>
                {cellRender.Currency(-data.discountAmount)}
              </span>
            )}
          </>
        ),
      },

      {
        field: "commissionRate",
        headerName: "Cost",
        sortable: true,

        cellRender: (col, data) => (
          <>
            {showCostItems && (
              <>
                <Text color={data.commissionRate > 0 ? "green" : "red"}>
                  {data.commissionRate}%
                </Text>
                <Text color="dimmed">
                  {cellRender.Currency(data.costTotal)}
                </Text>
              </>
            )}
            {!showCostItems && <Text color="dimmed">--</Text>}
          </>
        ),
      },

      {
        field: "product",
        headerName: "Product",
        sortable: true,
        cellRender: (col, data) => cellRender.ProductInfo(data.product),
      },
      // {
      //   field: "policyNumber",
      //   headerName: "Policy Number",
      //   sortable: true,
      // },
      {
        field: "policyNumber",
        headerName: "Policy No.",
        sortable: true,
        cellRender: (col, data) => (
          <div>
            <Text>{data.policyNumber ?? ""}</Text>
            {data.period?.start && data.period?.end && (
              <Text size="xs" color="dimmed">
                {moment(data.period.start).format("YYYY/MM/DD")} -{" "}
                {moment(data.period.end).format("YYYY/MM/DD")}
              </Text>
            )}
          </div>
        ),
      },

      {
        field: "client",
        headerName: "Applicant",
        sortable: true,
        cellRender: (col, data) => cellRender.ClientInfo(data.client),
      },

      {
        field: "consultants",
        headerName: "Consultants",
        sortable: true,
        cellRender: (col, data) => cellRender.Users(data.consultants, true),
      },

      // {
      //   field: "clientPayable",
      //   headerName: "Client Payable",
      //   sortable: true,
      //   cellRender: (col, data) => (
      //     <>
      //       <div>{cellRender.Currency(data?.clientPayable ?? 0)}</div>
      //       <div>
      //         {data.discountAmount == 0
      //           ? ""
      //           : "( " + cellRender.Currency(-data.discountAmount) + " )"}
      //       </div>
      //     </>
      //   ),
      // },

      // {
      //   field: "payment.amount",
      //   headerName: "Payment Amount",
      //   sortable: true,
      //   cellRender: (col, data) => (
      //     <>
      //       <Text
      //         color={
      //           !data?.payment?.amount
      //             ? "red"
      //             : data?.payment?.amount < data.clientPayable
      //             ? "red"
      //             : "green"
      //         }
      //       >
      //         {cellRender.Currency(data?.payment?.amount ?? 0)}
      //       </Text>
      //     </>
      //   ),
      // },
      // {
      //   field: "paymentStatus",
      //   headerName: "Payment Status",
      //   sortable: true,
      //   cellRender: (col, data) => (
      //     <>
      //       {data.paymentStatus
      //         ? cellRender.DebitNotePaymentStatus(data.paymentStatus)
      //         : ""}
      //     </>
      //   ),
      //   filterable: true,
      //   filter: {
      //     apiEntity: "debitNote",
      //     field: "paymentStatus",
      //     label: "Payment Status",
      //     width: 250,
      //     height: 300,
      //   },
      // },
      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
        ],
        actionMenu: [
          {
            name: "copy",
            label: "Copy",
            icon: IconTrash,
            props: {
              // color: "red",
            },
          },
          {
            name: "delete",
            label: "Delete data",
            icon: IconTrash,
            props: {
              color: "red",
            },
          },
        ],
      },
    ],
    searchableFields: ["status", "client.name", "searchText", "policyNumber"],
    select: [
      "code type period product policyNumber client consultants status payment paymentStatus clientPayable createdAt signedAt discountAmount commissionRate costTotal",
    ],
  };
  const editFormSchema = {
    display: {
      mode: ENUM_FORM_DISPLAY.LINK,
    },
    title: "Debit Note",
    titleField: "code",
    apiEntity,
    initialValues,
    layout: {
      containers: [
        {
          key: "main-g",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
          props: {
            gutter: "xl",
            columns: 24,
          },
          cols: [
            {
              key: "main-g1",
              props: {
                span: 7,
              },
            },
            {
              key: "main-g2",
              props: {
                span: 17,
              },
            },
          ],
        },

        {
          key: "main-tab",
          parent: "main-g2",
          type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Overview",
          },
          tab: [
            {
              key: "tab-overview",
              label: "Overview",
              //icon: IconSettings,
            },
            {
              key: "tab-payment",
              label: "Payments",
              //icon: IconSettings,
            },
            {
              key: "tab-insured",
              label: "Insured",
              //icon: IconSettings,
            },
            {
              key: "tab-files",
              label: "Files",
              //icon: IconSettings,
            },
            {
              key: "tab-logs",
              label: "Logs",
              // icon: IconSettings
            },
          ],
        },
        {
          key: "section-applicant",
          parent: "main-g1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Applicant Details",
            // titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            mt: 0,
            mb: "md",
          },
        },
        {
          key: "section-payment",
          parent: "tab-payment",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Payment Info",
            withBorder: false,
            mt: 0,
            mb: "md",
          },
        },
        {
          key: "section-client-payment",
          parent: "tab-payment",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Payment Records",
            // titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            mt: 0,
            mb: "md",
          },
        },

        {
          key: "section-consultant",
          parent: "main-g1",
          type: ENUM_FORM_LAYOUT_CONTAINER.BOX,
          props: {
            mt: 0,
            mb: 0,
          },
        },

        {
          key: "section-summary",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Summary",
            withBorder: false,
            mt: 0,
            mb: "md",
          },
        },

        {
          key: "section-timeline",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Timeline",
            withBorder: false,
            mt: 0,
            mb: "md",
          },
        },

        {
          key: "section-relatedDocument",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Related Documents",
            withBorder: false,
            mt: 0,
            mb: "md",
          },
        },
        {
          key: "section-remark",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Remark",
            withBorder: false,
            mt: 0,
            mb: "md",
          },
        },

        {
          key: "section-address",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Order Address",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: 0,
            mt: 20,
            mb: 20,
          },
        },

        {
          key: "section-insured",
          parent: "tab-insured",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Insured Details",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: 0,

            mt: 20,
            mb: 20,
          },
        },

        {
          key: "section-quo-files",
          parent: "tab-files",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "File List",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: "0px",
            mt: 20,
            mb: 20,
          },
        },

        {
          key: "section-files",
          parent: "tab-files",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Files",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: "0px",
            mt: 20,
            mb: 20,
          },
        },

        {
          key: "main-tab",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Descriptions",
          },
          tab: [
            {
              key: "tab-descriptions",
              label: "Descriptions",
              //icon: IconSettings,
            },
            {
              key: "tab-terms",
              label: "Terms",
              //icon: IconSettings,
            },
          ],
        },
        {
          key: "tab-payment",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Charge Items",
          },
          tab: [
            {
              key: "tab-chargeItem",
              label: "Charge Items",
              //icon: IconSettings,
            },
            {
              key: "tab-costItem",
              label: "Cost Items",
              //icon: IconSettings,
              showTab: () => showCostItems,
            },
          ],
        },
        {
          key: "section-description",
          parent: "tab-descriptions",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            // title: "Description",
            withBorder: false,
            mt: 0,
            mb: "md",
          },
        },

        {
          key: "section-term",
          parent: "tab-terms",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            withBorder: false,
            mt: 0,
            mb: "md",
          },
        },
        {
          key: "section-charges",
          parent: "tab-chargeItem",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            // title: "Charge Items",
            withBorder: false,
            mt: 0,
            mb: "md",
          },
        },
        {
          key: "section-cost",
          parent: "tab-costItem",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            // title: "Cost Items",
            withBorder: false,
            mt: 0,
            mb: "md",
          },
        },
        {
          key: "section-providerReference",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title:
              "Provider Reference (Screenshot from provider email if have)",
            withBorder: false,
            mt: 0,
            mb: "md",
          },
        },
        // {
        // 	key: "section-payments",
        // 	parent: "tab-overview",
        // 	type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        // 	props: {
        // 		title: "Payments",
        // 		withBorder: false,
        // 		mt: 0,
        // 		mb: "md",
        // 	},
        // },
        {
          key: "section-log",
          parent: "tab-logs",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            // title: "Logs",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            mt: 20,
            mb: 20,
          },
        },
        {
          key: "section-action-list",
          parent: "main-g1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Action List",
            // titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: "0px",
            mt: 0,
            mb: 20,
          },
        },
      ],
      fields: [
        {
          name: "client",
          component: OrderApplicantDetail,
          parent: "section-applicant",
          props: {},
        },
        {
          name: "consultants",
          component: OrderConsultantList,
          parent: "section-consultant",
          props: {},
        },
        {
          name: "actions",
          component: DebitNoteActionList,
          parent: "section-action-list",
          props: {},
        },

        {
          name: "summary",
          component: DebitNoteSummary,
          parent: "section-summary",
          props: {},
        },
        {
          name: "summary",
          component: DebitNoteTimeline,
          parent: "section-timeline",
          props: {},
        },
        {
          name: "summary",
          component: DebitNotePaymentSummary,
          parent: "section-payment",
          props: {},
        },
        {
          name: "relatedDocuments",
          component: DebitNoteRelatedDocumentSection,
          parent: "section-relatedDocument",
          props: {},
        },

        {
          name: "insured",
          component: OrderInsuredList,
          parent: "section-insured",
          props: {},
        },

        {
          name: "addressStr",
          // component: OrderAddressSelect,
          component: Textarea,
          parent: "section-address",
          props: {},
        },
        {
          name: "address",
          component: OrderAddressSelect,
          parent: "section-address",
          props: {
            showAddressResult: false,
            onChangeAddress: (form, address, addressStr) => {
              form.setFieldValue("addressStr", addressStr);
            },
          },
        },

        {
          name: "descriptions",
          component: QuotationDescription,
          parent: "section-description",
          props: {
            required: true,
          },
        },

        {
          name: "terms",
          component: QuotationTerms,
          parent: "section-term",
          props: {
            required: true,
            templateCategory: "DebitNote Terms",
          },
        },

        {
          name: "chargeItems",
          component: DebitNoteChargeItems,
          parent: "section-charges",
          props: {
            required: true,
          },
        },
        {
          name: "costItems",
          component: DebitNoteCostItems,
          parent: "section-cost",
          props: {
            required: true,
            allowGetRate: true,
          },
        },
        {
          name: "remark",
          component: Textarea,
          parent: "section-remark",
          props: {},
        },
        {
          name: "clientPayments",
          component: ClientPaymentList,
          parent: "section-client-payment",
          props: {},
        },
        {
          name: "summary",
          component: QuotationChargeSummary,
          parent: "section-charges",
          props: {
            required: true,
          },
        },
        {
          name: "files",
          component: FileList,
          parent: "section-files",
          props: {},
        },

        {
          name: "fileList",
          component: QuoFileList,
          parent: "section-quo-files",
          props: {
            // title: "Please upload related files.",
          },
        },

        {
          name: "log",
          component: ActivityLog,
          parent: "section-log",
          props: {},
        },
        {
          name: "providerCostReference",
          component: RichTextEditor,
          parent: "section-providerReference",
          props: {},
        },
      ],
    },
  };

  const formSchema = {
    add: addFormSchema,
    edit: editFormSchema,
  };

  return (
    <>
      {/* {userRole} */}
      <PageList
        title={"Debit Note"}
        apiEntity={apiEntity}
        tableSchema={tableSchema}
        formSchema={formSchema}
        searchByTextField={false}
        initSort={{ by: "code", order: "desc" }}
        stats={{
          component: DebitNotePageStats,
          props: {
            mb: 35,
            role: "OPERATION",
          },
        }}
      />
    </>
  );
};

export default DebitNote;
