import React, { useState, useEffect } from "react";
import { useServerApi } from "../hooks/userServerApi";
import {
  Badge,
  Group,
  Text,
  Table,
  Checkbox,
  NumberInput,
  ActionIcon,
  Select,
  Tooltip,
  Button,
  Switch,
} from "@mantine/core";
import { useForceUpdate } from "@mantine/hooks";
import { useCellRender } from "../hooks/useCellRender";
import { IconTrash, IconRefresh } from "@tabler/icons";
import _ from "lodash";
import { useFormatter } from "../hooks/useFomatter";

const DebitNotePaymentMatchingList = ({
  form,
  name,
  label,
  value,
  onChange,
  clientId,
  mode = "add",
}) => {
  const paymentAmount = form.values.amount;

  const debitNoteAssignments = _.get(form.values, "debitNoteAssignments", []);
  const [clientDNs, setClientDNs] = useState([]);

  const [selectedDN, setSelectedDN] = useState(null);

  const [api] = useServerApi();
  const forceUpdate = useForceUpdate();
  const [cellRender] = useCellRender();
  const [formatter] = useFormatter();

  useEffect(() => {
    // console.log("paymentAmount", paymentAmount);
    if (debitNoteAssignments.length == 1 && mode == "add") {
      debitNoteAssignments[0].paymentAmount = paymentAmount;
      forceUpdate();
    }
  }, [paymentAmount]);
  const [isMultipleClient, setIsMultipleClient] = useState(
    _.get(form.values, "isMultipleClient", false)
  );

  const fetchData = async (isMultipleClient) => {
    try {
      const result = await api.search({
        apiEntity: "debitNote",
        pageSize: 1000,
        searchQuery: {
          $and: !isMultipleClient
            ? [
                { client: clientId },
                {
                  paymentStatus: {
                    $in: ["DFT", "WAIT", "MATCHING", "PARTIAL"],
                  },
                },
              ]
            : [
                {
                  paymentStatus: {
                    $in: ["DFT", "WAIT", "MATCHING", "PARTIAL"],
                  },
                },
              ],
        },
        select: "code paymentStatus clientPayable product payment client",
      });
      // console.log("DebitNotePaymentMatching fetchData", result);
      const dns = result.docs.filter(
        (dn) =>
          !(
            dn.paymentStatus === "MATCHING" &&
            dn.clientPayable <= dn.payment.amount
          )
      );
      setClientDNs(dns);
      forceUpdate();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [clientId]);

  const selectedDebitNotesAmount = debitNoteAssignments.reduce(
    (sum, assignment) => sum + assignment.paymentAmount,
    0
  );
  const differenceAmount = paymentAmount - selectedDebitNotesAmount;

  const handleAddAllDN = () => {
    // console.log("Add all DN", clientDNs);
    if (_.isEmpty(clientDNs)) return;
    const newAssignments = clientDNs.map((dn) => ({
      debitNote: dn,
      paymentAmount:
        dn.payment?.amount && dn.payment.amount < dn.clientPayable
          ? dn.clientPayable - dn.payment.amount
          : dn.clientPayable,
    }));
    _.set(form.values, "debitNoteAssignments", newAssignments);
    setSelectedDN(null);
    forceUpdate();
  };
  const handleAddDN = () => {
    // console.log("Add selectedDN", selectedDN, clientDNs);
    if (!selectedDN) return;
    if (_.isEmpty(clientDNs)) return;

    const newDN = clientDNs.find((dn) => dn._id === selectedDN);
    if (newDN) {
      const newAssignments = [
        ...debitNoteAssignments,
        {
          debitNote: newDN,
          paymentAmount: newDN.clientPayable,
        },
      ];
      // console.log("newAssignments", newAssignments);
      _.set(form.values, "debitNoteAssignments", newAssignments);
      setSelectedDN(null);
      forceUpdate();
    }
  };
  const onMultipleClientChange = async (isMultipleClient) => {
    setIsMultipleClient(isMultipleClient);
    await fetchData(isMultipleClient);
    _.set(form.values, "isMultipleClient", isMultipleClient);
    forceUpdate();
  };

  return (
    <div>
      {/* DebitNotePaymentMatching clientId: {clientId} <br /> */}
      <Group position="right">
        <Switch
          size="xs"
          label="Multiple Client"
          checked={isMultipleClient}
          mb="md"
          onChange={(event) => {
            onMultipleClientChange(event.currentTarget.checked);
          }}
        />
      </Group>
      <Group my="md" position="apart" mb="xl">
        <Group noWrap>
          <Group>
            <Text size="xs">Debit Notes</Text>
            <Badge size="xs">{`${debitNoteAssignments?.length}  `}</Badge>
          </Group>
          <Group>
            <Text size="xs"> Payment Amount </Text>
            <Badge size="xs">
              {cellRender.Currency(selectedDebitNotesAmount)}
            </Badge>
          </Group>
        </Group>

        <Group spacing="xs">
          <Select
            searchable
            clearable
            w={400}
            size="xs"
            value={selectedDN}
            data={clientDNs.map((dn) => ({
              value: dn._id,
              label: `${dn.code} [${dn.paymentStatus}] : ${cellRender.Currency(
                dn.clientPayable
              )}  ${
                dn.payment?.amount
                  ? `[ ${cellRender.Currency(dn.payment?.amount)} ]`
                  : ""
              } - ${dn.client?.name}`,
            }))}
            onChange={(value) => {
              setSelectedDN(value);
            }}
          />

          <ActionIcon onClick={fetchData}>
            <IconRefresh size={16} />
          </ActionIcon>
          <Button size="xs" onClick={handleAddDN} disabled={!selectedDN}>
            Select
          </Button>
          <Button
            size="xs"
            disabled={_.isEmpty(clientDNs)}
            onClick={handleAddAllDN}
          >
            Add All
          </Button>
        </Group>
      </Group>
      <Table my="xl">
        <thead>
          <tr>
            <th>
              <Text size="xs">Code</Text>
            </th>
            <th>
              <Text size="xs">Product</Text>
            </th>
            <th>
              <Text size="xs">Client Payable</Text>
            </th>
            <th>
              <Text size="xs">Payment Amount</Text>
            </th>

            <th></th>
          </tr>
        </thead>
        <tbody>
          {debitNoteAssignments.map((assignment) => (
            <tr key={assignment.debitNote._id}>
              <td>
                {cellRender.DebitNotePaymentStatus(
                  assignment.debitNote.paymentStatus
                )}
                <Text size="xs">{assignment.debitNote.code}</Text>
              </td>
              <td>
                <Text size="xs" color="green" ml="50px">
                  {`[ ${assignment.debitNote.client?.name} ]`}
                </Text>

                {cellRender.ProductInfo(assignment.debitNote.product)}
              </td>

              <td>
                <Text size="xs">
                  {cellRender.Currency(assignment.debitNote.clientPayable)}
                </Text>
                {assignment.debitNote.payment?.amount > 0 && (
                  <Text size="xs" color="green">
                    {`[ ${cellRender.Currency(
                      assignment.debitNote.payment?.amount
                    )} ]`}
                  </Text>
                )}
              </td>
              <td>
                <NumberInput
                  value={assignment.paymentAmount}
                  onChange={(value) => {
                    assignment.paymentAmount = value;
                    forceUpdate();
                  }}
                  parser={formatter.currencyParser}
                  formatter={formatter.currencyFormat}
                  precision={2}
                  size="xs"
                />
              </td>

              <td>
                <ActionIcon
                  onClick={() => {
                    //remove the assignment
                    _.remove(debitNoteAssignments, assignment);
                    forceUpdate();
                  }}
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Group position="right">
        <div>
          <Group position="apart">
            <Text size="sm">Payment Amount:</Text>
            <Text size="sm">{cellRender.Currency(paymentAmount)}</Text>
          </Group>
          <Group position="apart">
            <Text size="sm">Selected Amount:</Text>
            <Text size="sm">
              {cellRender.Currency(selectedDebitNotesAmount)}
            </Text>
          </Group>
          <Group position="apart">
            <Text size="sm" color={differenceAmount > 0 ? "red" : "green"}>
              Difference:
            </Text>
            <Text size="sm" color={differenceAmount >= 0 ? "green" : "red"}>
              {cellRender.Currency(differenceAmount)}
              {/* {differenceAmount > 0 ? "Need to add more" : "Need to add more"} */}
            </Text>
          </Group>
        </div>
      </Group>
    </div>
  );
};

export default DebitNotePaymentMatchingList;
