import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Card, Text, Group, Badge, Button } from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons";
import { useTranslation } from "react-i18next";
import { showNotification } from "@mantine/notifications";
import { useServerApi } from "../hooks/userServerApi";
import { DATA_BANK_ACCOUNT_TYPE, DATA_BANK_WITH_CODE } from "../data/options";
import { Page } from "../layout/page";
import { useSearchParams } from "react-router-dom";
import { Tabs } from "@mantine/core";
import { IconList, IconExchange, IconSettings } from "@tabler/icons";
import FormSection from "../components/formSection";
import { useFormRender } from "../hooks/useFormRender";
import { FormSectionCard } from "../layout/page";
import { TextInput, Select, NumberInput } from "@mantine/core";
import BankAccountSetting from "../components/bankAccountSetting";
import BankAccountTransactions from "../components/bankAccountTransactions";
import BankTransactionMatching from "../components/bankTransactionMatching";

const BankAccountDetail = () => {
  const [bankAccount, setBankAccount] = useState(null);
  const location = useLocation();
  const { t } = useTranslation();
  const [api] = useServerApi();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const fetchBankAccount = async () => {
    if (!id) return;
    try {
      //   console.log("fetchBankAccount id", id);
      const data = await api.getById({
        apiEntity: "bankAccount",
        id,
      });

      //   console.log("fetchBankAccount result", data);
      if (data) {
        setBankAccount(data);
      }
    } catch (error) {
      console.error("Error fetching bank account:", error);
      showNotification({
        title: "Error",
        message: "Failed to fetch bank account details",
        color: "red",
      });
    }
  };

  useEffect(() => {
    fetchBankAccount();
  }, [id]);

  return (
    <Page title={t("Bank Account > Detail")}>
      {bankAccount && (
        <Tabs defaultValue="transactions">
          <Tabs.List>
            <Tabs.Tab value="transactions" icon={<IconList size={14} />}>
              {t("Bank Transactions")}
            </Tabs.Tab>
            <Tabs.Tab value="matching" icon={<IconExchange size={14} />}>
              {t("Matching Deposit")}
            </Tabs.Tab>
            <Tabs.Tab value="refund" icon={<IconExchange size={14} />}>
              {t("Refund Payment")}
            </Tabs.Tab>
            <Tabs.Tab value="settings" icon={<IconSettings size={14} />}>
              {t("Settings")}
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="transactions" pt="xs">
            {/* Bank Transactions content */}
            <BankAccountTransactions
              bankAccount={bankAccount}
              onImportSuccess={fetchBankAccount}
              onDeleteSuccess={fetchBankAccount}
            />
          </Tabs.Panel>

          <Tabs.Panel value="matching" pt="xs">
            {/* Matching Deposit content */}

            <BankTransactionMatching bankAccount={bankAccount} />
          </Tabs.Panel>

          <Tabs.Panel value="refund" pt="xs">
            {/* Matching Deposit content */}
            <BankTransactionMatching bankAccount={bankAccount} type="REFUND" />
          </Tabs.Panel>

          <Tabs.Panel value="settings" pt="xs">
            {/* Settings content */}
            <BankAccountSetting
              bankAccount={bankAccount}
              setBankAccount={setBankAccount}
            />
          </Tabs.Panel>
        </Tabs>
      )}
    </Page>
  );
};

export default BankAccountDetail;
