import React from "react";
import { FormSectionCard } from "../layout/page";
import { useTranslation } from "react-i18next";
import DataTable from "./dataTable";
import { Text } from "@mantine/core";

const ProviderLinkageTab = ({ provider }) => {
  const { t } = useTranslation();

  const columns = [
    { headerName: t("Label"), field: "name" },
    {
      headerName: t("URL"),
      field: "url",
      cellRender: (column, data) => (
        <a
          href={`${
            data.url?.includes("http") ? data.url : `https://${data.url}`
          }`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          {data.url
            ? data.url.includes("http")
              ? data.url
              : `https://${data.url}`
            : ""}
        </a>
      ),
    },
  ];

  const linkages = provider
    ? provider.formLinkage.filter((l) => l.isPublic)
    : [];

  return (
    <FormSectionCard mt="xl" ml="xl">
      {!linkages || linkages.length === 0 ? (
        <Text size="xs">{t("No linkage information")}</Text>
      ) : (
        <DataTable
          data={linkages}
          columns={columns}
          hidePagination
          hideSearchBar
          padding={0}
        />
      )}
    </FormSectionCard>
  );
};

export default ProviderLinkageTab;
