import React, { useState, useEffect } from "react";
import {
  Group,
  Text,
  Badge,
  CopyButton,
  Tooltip,
  ActionIcon,
} from "@mantine/core";
import { useServerApi } from "../hooks/userServerApi";
import { useCellRender } from "../hooks/useCellRender";
import {
  IconPhoto,
  IconBuildingSkyscraper,
  IconSearch,
  IconLink,
  IconCopy,
  IconCheck,
} from "@tabler/icons";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import DataTable from "./dataTable";
import { FormSectionCard } from "../layout/page";
import moment from "moment";
import { ImageThumbList } from "./clientPaymentList";
import ReactJson from "react-json-view";
import { useAuthUser } from "react-auth-kit";

const ProviderContactTab = ({ provider }) => {
  const [api] = useServerApi();
  const [providerContact, setProviderContact] = useState([]);

  const fetch = async () => {
    if (!provider?._id) return;
    const data = await api.Provider.getContactList(provider?._id);
    if (data) setProviderContact(data);
  };

  useEffect(() => {
    fetch();
  }, [provider]);

  const providerContactColumns = [
    {
      headerName: "Contact",
      field: "name",
      cellRender: (column, data) => (
        <>
          <Text size="xs">{data.name}</Text>
          <Text size="xs" c={"dimmed"}>
            {data.title}
          </Text>
        </>
      ),
    },
    {
      headerName: "Department",
      field: "department",
      cellRender: (column, data) => <Text size="xs">{data.department}</Text>,
    },
    {
      headerName: "Email",
      field: "email",
      cellRender: (column, data) => <Text size="xs">{data.email}</Text>,
    },
    {
      headerName: "Phone",
      field: "phone",
      cellRender: (column, data) => <Text size="xs">{data.phone}</Text>,
    },
  ];

  return (
    <>
      {/* <ReactJson src={auth()} style={{ background: "white" }} collapsed /> */}

      {providerContact.length === 0 && (
        <FormSectionCard mt="xl" ml="xl">
          <Text size="xs">No Contact found</Text>
        </FormSectionCard>
      )}

      {providerContact.length > 0 && (
        <FormSectionCard mt="xl" ml="xl">
          <DataTable
            data={providerContact}
            columns={providerContactColumns}
            hidePagination={true}
            hideSearchBar={true}
            showHeader={false}
            padding={0}
          />
        </FormSectionCard>
      )}
    </>
  );
};

export default ProviderContactTab;
