import React from "react";
import { FormSectionCard } from "../layout/page";
import { useTranslation } from "react-i18next";
import { Text, ScrollArea } from "@mantine/core";
import _ from "lodash";
import FileList, { K_FILE_LIST_DISPLAY_MODE } from "./fileList";

const ProviderFormTab = ({ provider }) => {
  const { t } = useTranslation();
  return (
    <>
      {!provider || _.isEmpty(provider.formFiles) ? (
        <FormSectionCard mt="xl" ml="xl">
          <Text size="xs">{t("No Form information")}</Text>
        </FormSectionCard>
      ) : (
        <FormSectionCard mt="xl" ml="xl">
          <FileList
            name="formFiles"
            staticFiles={provider.formFiles}
            disabled={true}
            showScroll={true}
            height={window.innerHeight - 300}
            displayMode={K_FILE_LIST_DISPLAY_MODE.LIST}
          />
        </FormSectionCard>
      )}
    </>
  );
};

export default ProviderFormTab;
