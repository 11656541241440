import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  TextInput,
  Select,
  Textarea,
  NumberInput,
  Group,
  Title,
  Text,
  Divider,
  Button,
  Autocomplete,
} from "@mantine/core";
import ReactJson from "react-json-view";

import ArrayTable from "./arrayTable";
import { DATA_CURRENCY } from "../data/options";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import { useFormatter } from "../hooks/useFomatter";
import { useForceUpdate } from "@mantine/hooks";
import { IconAlertTriangle } from "@tabler/icons";

import RichTextEditor from "./richTextEditor";

const calculateTotal = (charges) => {
  if (_.isEmpty(charges)) return 0;
  const total = charges?.reduce((a, c) => a + (c.qty ?? 1) * c.unitPrice, 0);
  return +total.toFixed(2);
};

const COMMON_CHARGE_ITEMS = ["Premium", "IA Levy", "EC Levy", "MIB Levy"];

const DebitNoteChargeItems = ({ form, name }) => {
  const chargeItems = _.get(form.values, "chargeItems");
  const costTotal = _.get(form.values, "costTotal");

  const [total, setTotal] = useState(0);
  const [formatter] = useFormatter();
  const [discountAmount, setDiscountAmount] = useState(
    _.get(form.values, "discountAmount")
  );
  const [clientFixRate, setClientFixRate] = useState(
    _.get(form.values, "clientFixRate")
  );
  const [insuredValue, setInsuredValue] = useState(
    _.get(form.values, "insuredValue")
  );

  const forceUpdate = useForceUpdate();

  const defaultClientFixRate = process.env
    .REACT_APP_DEBIT_NOTE_CHARGE_DEFAULT_CLIENT_FIX_RATE
    ? +process.env.REACT_APP_DEBIT_NOTE_CHARGE_DEFAULT_CLIENT_FIX_RATE
    : 0;

  const rowInitValue = {
    item: "",
    currency: "HKD",
    qty: 1,
    unitPrice: 0,
    price: 0,
  };

  const fields = [
    {
      header: "Item",
      headerWidth: "50%",
      fieldName: "item",
      component: Autocomplete,
      props: {
        data: COMMON_CHARGE_ITEMS,
        searchable: true,
        clearable: true,
      },
    },

    {
      header: "Qty",
      headerWidth: "10%",
      fieldName: "qty",
      component: NumberInput,
      props: {},
    },
    {
      header: "Currency",
      headerWidth: "12%",
      fieldName: "currency",
      component: Select,
      props: {
        data: DATA_CURRENCY,
        defaultValue: "HKD",
        searchable: true,
      },
    },
    {
      header: "Unit Price",
      headerWidth: "30%",
      fieldName: "unitPrice",
      component: NumberInput,
      props: {
        parser: formatter.currencyParser,
        formatter: formatter.currencyFormat,
        //  prefix="$"
        // decimalSeparator=","
        precision: 2,
      },
    },
  ];

  const onChargeChange = (charges) => {
    const total = calculateTotal(charges);
    setTotal(total);
  };

  useEffect(() => {
    onChargeChange(chargeItems);
  }, [chargeItems]);

  useEffect(() => {
    form.setFieldValue("total", total);
  }, [total]);

  const applyDiscount = () => {
    // console.log("discount", discountAmount);
    form.setFieldValue("discountAmount", discountAmount);
    forceUpdate();
  };

  const applyFixRate = () => {
    // console.log("applyFixRate");
    if (!clientFixRate) return;
    if (!insuredValue) return;

    const chargeItems = _.get(form.values, "chargeItems");
    if (_.isEmpty(chargeItems)) return;

    const newChargeItems = _.cloneDeep(
      chargeItems.filter((item) => item.unitPrice >= 0)
    );

    const pItem = newChargeItems.find((c) => c.item === "Premium");
    if (pItem) {
      let unitPrice = (insuredValue * clientFixRate) / 100;
      pItem.price = unitPrice * pItem.qty;
      pItem.unitPrice = unitPrice;
      pItem.item = "Premium";
    }
    form.setFieldValue("chargeItems", newChargeItems);
    form.setFieldValue("clientFixRate", clientFixRate);
    form.setFieldValue("insuredValue", insuredValue);

    setTotal(calculateTotal(newChargeItems));
    forceUpdate();
  };

  const showFixRate = process.env.REACT_APP_DEBIT_NOTE_SHOW_FIX_RATE === "true";

  return (
    <>
      {/* fixRate:{_.get(form.values, "clientFixRate")} */}
      {total > 0 && form.values.costTotal === 0 && (
        <Group mb="xl">
          <IconAlertTriangle size="14" color="red" />
          <Text color={"red"} size="md">
            Cost Items is 0
          </Text>
        </Group>
      )}
      <Group position="apart">
        <Group align="end" mb="xl">
          <NumberInput
            size="xs"
            precision={2}
            label="Exclusive Discount (HKD)"
            placeholder="10"
            value={_.get(form.values, "discountAmount")}
            onChange={setDiscountAmount}
            parser={formatter.currencyParser}
            formatter={formatter.currencyFormat}
          ></NumberInput>
          <Button size="xs" color="blue" onClick={() => applyDiscount()}>
            Apply Discount
          </Button>
        </Group>

        {showFixRate && (
          <Group align="end" mb="xl">
            <NumberInput
              size="xs"
              precision={2}
              label="Insured Value"
              placeholder="1000000"
              value={insuredValue}
              onChange={setInsuredValue}
              parser={formatter.numberParser}
              formatter={formatter.currencyFormat}
            ></NumberInput>
            <NumberInput
              size="xs"
              precision={2}
              label="Client Fix Rate (%)"
              placeholder="3.2"
              value={
                _.get(form.values, "clientFixRate") ?? defaultClientFixRate
              }
              onChange={setClientFixRate}
              parser={formatter.numberParser}
              formatter={formatter.percentage}
            ></NumberInput>

            <Button size="xs" color="teal" onClick={() => applyFixRate()}>
              Apply Rate
            </Button>
          </Group>
        )}
      </Group>
      <ArrayTable
        form={form}
        name={name}
        rowInitValue={rowInitValue}
        fields={fields}
        onDataChange={onChargeChange}
      />
    </>
  );
};

export default DebitNoteChargeItems;
