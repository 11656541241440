import React from "react";
import { Button, Text, TextInput, Chip, Select } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconExclamationMark, IconCircleCheck } from "@tabler/icons";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { useServerApi } from "../hooks/userServerApi";
const UserDisableCreateOrderButton = ({ mainForm, ...props }) => {
  const { disableCreateOrder, status, _id } = mainForm.values;
  const [api] = useServerApi();

  const handleClick = async () => {
    let newDisable = !disableCreateOrder ? true : false;
    const result = await api.User.disableCreateOrder(_id, newDisable);
    // console.log(result);
    if (result?.success) {
      showNotification({
        title: `User  ${
          newDisable ? "is not" : "is"
        } allowed to create order now.`,
        message: "Updated",
        icon: <IconCircleCheck size={18} />,
        color: "green",
      });
      //   console.log("Result", result);
      mainForm.setFieldValue("disableCreateOrder", newDisable);
    } else {
      showNotification({
        title: `User can't be updated`,
        color: "red",
        icon: <IconExclamationMark size={18} />,
        message: result?.message,
      });
    }
  };

  const isActive = () => {
    return !disableCreateOrder;
  };

  const { t } = useTranslation();
  return (
    <>
      {/* Status: {status} */}
      <Button
        fullWidth
        onClick={handleClick}
        color={isActive() ? "orange" : "green"}
        disabled={status === "TERMINATED"}
      >
        {isActive() ? t("Disable Create Order") : t("Enable Create Order")}
      </Button>
    </>
  );
};

export default UserDisableCreateOrderButton;
