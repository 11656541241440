import {
  Accordion,
  Button,
  Text,
  Title,
  Table,
  Select,
  Group,
  NumberInput,
} from "@mantine/core";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useServerApi } from "../hooks/userServerApi";
import { DatePicker } from "@mantine/dates";
import { DATA_REPORT_PRODUCT_TYPE } from "../data/options";
import { useTranslation } from "react-i18next";
const ReportProduct = ({}) => {
  const today = new Date();
  const [loading, setLoading] = useState(false);
  const [effectiveDate, setEffectiveDate] = useState(today);
  const [percent, setPercent] = useState(100);
  // const [reportType, setReportType] = useState("original");
  const { t, i18n } = useTranslation();
  const [api] = useServerApi();

  const downloadReport = async () => {
    try {
      setLoading(true);
      const result = await api.Product.getReport({ effectiveDate, percent });
      setLoading(false);

      if (result && result.data && result.data.url) {
        window.open(result.data.url, "_blank");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div>
      <Text size="xs">
        {t("Please click the below button to download the product report.")}
      </Text>

      <Group mt="xl">
        <DatePicker
          label={t("Rate Effective Date")}
          title="Commission Rate Effective Date (Sign Date)"
          size="xs"
          allowFreeInput
          placeholder="Sign Date"
          value={effectiveDate}
          onChange={(d) => setEffectiveDate(d)}
          locale={i18n.language === "en" ? "en" : "zh-tw"}
        />

        {/* <Select
          size="xs"
          label={t("Type")}
          defaultValue={"original"}
          data={DATA_REPORT_PRODUCT_TYPE}
          onChange={(type) => setReportType(type)}
        /> */}
        <NumberInput
          label="Commission %"
          value={percent}
          placeholder="100% will show the original rate from provider"
          defaultValue={0}
          min={1}
          max={100}
          step={1}
          required
          onChange={(value) => setPercent(value)}
        />
      </Group>

      <Button
        size="xs"
        mt="xl"
        onClick={() => downloadReport()}
        loading={loading}
      >
        {t("Download")}
      </Button>
      <></>
    </div>
  );
};

export default ReportProduct;
