import { Tabs, Button, Group } from "@mantine/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import ReactJson from "react-json-view";

const FormTab = ({
  getChildContainers,
  getChildFields,
  renderContainer,
  renderField,
  tabs,
  ...props
}) => {
  const { t: translate } = useTranslation();
  const visibleTabs = tabs?.tab.filter((tab) => {
    // console.log(tab, tab.showTab ? tab.showTab() : true);
    if (!tab.showTab) return true;
    if (!_.isFunction(tab.showTab)) return true;
    return tab.showTab();
  });
  return (
    <>
      {/* <ReactJson src={tabs} style={{ background: "white" }} /> */}
      <Tabs {...tabs.props} key={tabs.key}>
        <Tabs.List>
          {visibleTabs?.map(({ label, key, iconSize = 14, ...t }) => (
            <Tabs.Tab
              key={key}
              // icon={<t.icon size={iconSize}/>}
              value={label}
            >
              {translate(label)}
            </Tabs.Tab>
          ))}
        </Tabs.List>

        {tabs.tab?.map(({ label, key }, index) => (
          <Tabs.Panel key={key} value={label} pt={"xl"}>
            {getChildContainers(key).map((c) => renderContainer(c))}
            {getChildFields(key).map((f) => renderField(f))}
          </Tabs.Panel>
        ))}
      </Tabs>
    </>
  );
};

export default FormTab;
