import React from "react";
import InfoGridList from "./infoGridList";
import { useCellRender } from "../hooks/useCellRender";
import { DATA_ORDER_STATUS } from "../data/options";
import { Group } from "@mantine/core";
import _ from "lodash";

export default function OrderDNTimeLine({ form }) {
  const [cellRender] = useCellRender();
  const timeline = _.get(form.values, "dnTimeLine");
  const dataFontWeight = 600;

  const fields = [
    {
      title: "DN Date",
      value: cellRender.Date(timeline?.dnDate, "YYYY-MM-DD", dataFontWeight),
    },
    {
      title: "Period Start",
      value: cellRender.Date(
        timeline?.periodStart,
        "YYYY-MM-DD",
        dataFontWeight
      ),
    },
    {
      title: "Period End",
      value: cellRender.Date(timeline?.periodEnd, "YYYY-MM-DD", dataFontWeight),
    },
    // {
    //   title: "Hold Cover At",
    //   value: cellRender.Date(
    //     timeline?.holdCoverAt,
    //     "YYYY-MM-DD",
    //     dataFontWeight
    //   ),
    // },
    {
      title: "Confirmed At",
      value: cellRender.Date(
        timeline?.confirmedAt,
        "YYYY-MM-DD",
        dataFontWeight
      ),
    },
  ];

  return <InfoGridList fields={fields} values={timeline} cols={4} />;
}
