import {
  Text,
  Title,
  createStyles,
  Button,
  Group,
  Space,
  useMantineTheme,
  Box,
} from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import React, { useEffect, useState } from "react";

// function PageListHeader( {title, handleAdd, handleRefresh, handleClose}){
function PageListHeader({ title, actionButtons }) {
  const theme = useMantineTheme();
  const [isSticky, setIsSticky] = useState(false);

  const { width, height } = useViewportSize();
  const isMobile = width < 768;
  useEffect(() => {
    const handleScroll = () => {
      const isScrolling = window.scrollY > 0;
      setIsSticky(isScrolling);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      style={{
        position: !(isMobile && isSticky) ? "sticky" : "static",
        top: 0,
        background:
          theme.colorScheme === "dark"
            ? theme.colors.dark[8]
            : theme.colors.gray[0],
        zIndex: 10,
      }}
    >
      <Box pt={isSticky ? "lg" : 0}>
        <Group position="apart">
          <Title order={2}>{title}</Title>
          <span>
            <Group>
              {actionButtons.map(({ onClick, label, variant = "filled" }) => (
                <Button
                  size="xs"
                  key={label}
                  onClick={onClick}
                  variant={variant}
                >
                  {label}
                </Button>
              ))}
            </Group>
          </span>
        </Group>
      </Box>
      <Space h="lg" />
    </div>
  );
}

export default PageListHeader;
