import {
  Accordion,
  Button,
  Text,
  Title,
  Table,
  Select,
  Group,
} from "@mantine/core";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useServerApi } from "../hooks/userServerApi";
import { DatePicker } from "@mantine/dates";
import { useTranslation } from "react-i18next";
import _ from "lodash";

const ReportRenewal = ({}) => {
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [month, setMonth] = useState();

  const [loading, setLoading] = useState(false);

  const [api] = useServerApi();
  const { t } = useTranslation();

  const downloadReport = async () => {
    try {
      setLoading(true);

      // console.log(fromDate, toDate);

      const result = await api.Renewal.getReport(month);
      setLoading(false);
      // console.log(result);
      if (result && result.data && result.data.url) {
        window.open(result.data.url, "_blank");
      }
    } catch (error) {
      setLoading(false);
      // console.log(error);
    }
  };

  //Get the possible months to select , from last 2 months to next 2 months
  const prevMonths = 6;
  const nextMonths = 6;
  const possibleMonths = [];
  for (let i = -prevMonths; i <= nextMonths; i++) {
    const month = moment().add(i, "months").format("YYYY-MM");
    possibleMonths.push({
      label: month,
      value: month,
    });
  }

  return (
    <div>
      <Text size="xs">{t("Please select the period of records")}</Text>

      <Group mt="xs">
        <Select
          size="xs"
          data={possibleMonths}
          placeholder="Select Month"
          onChange={(v) => setMonth(v)}
        />
      </Group>
      <Button
        size="xs"
        mt="xl"
        onClick={() => downloadReport()}
        loading={loading}
      >
        {t("Download")}
      </Button>
      <></>
    </div>
  );
};

export default ReportRenewal;
