import { useTranslation } from "react-i18next";
import { useServerApi } from "../hooks/userServerApi";
import { TextInput, Select, NumberInput, Button, Group } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { FormSectionCard } from "../layout/page";
import { DATA_BANK_ACCOUNT_TYPE, DATA_BANK_WITH_CODE } from "../data/options";

const BankAccountSetting = ({ bankAccount, setBankAccount }) => {
  const { t } = useTranslation();
  const [api] = useServerApi();

  const handleUpdate = async () => {
    try {
      const result = await api.updateById({
        apiEntity: "bankAccount",
        id: bankAccount._id,
        values: bankAccount,
      });
      if (result.success) {
        showNotification({
          title: "Success",
          message: "Bank account updated successfully",
          color: "green",
        });
      }
    } catch (error) {
      console.error("Error updating bank account:", error);
      showNotification({
        title: "Error",
        message: "Failed to update bank account details",
        color: "red",
      });
    }
  };

  return (
    <>
      {/* {renderForm()} */}

      <FormSectionCard>
        {bankAccount && (
          <form>
            <Group grow>
              <TextInput
                label={t("Account Number")}
                value={bankAccount.accountNumber}
                onChange={(event) =>
                  setBankAccount({
                    ...bankAccount,
                    accountNumber: event.currentTarget.value,
                  })
                }
              />
              <TextInput
                label={t("Account Holder Name")}
                value={bankAccount.accountHolderName}
                onChange={(event) =>
                  setBankAccount({
                    ...bankAccount,
                    accountHolderName: event.currentTarget.value,
                  })
                }
              />
            </Group>
            <Group grow mt="md">
              <Select
                label={t("Bank")}
                data={DATA_BANK_WITH_CODE}
                value={bankAccount.bank}
                onChange={(value) =>
                  setBankAccount({ ...bankAccount, bank: value })
                }
              />
              <Select
                label={t("Account Type")}
                data={DATA_BANK_ACCOUNT_TYPE}
                value={bankAccount.accountType}
                onChange={(value) =>
                  setBankAccount({ ...bankAccount, accountType: value })
                }
              />
            </Group>
            <Group grow mt="md">
              <TextInput
                label={t("Short Name")}
                value={bankAccount.shortName}
                onChange={(event) =>
                  setBankAccount({
                    ...bankAccount,
                    shortName: event.currentTarget.value,
                  })
                }
              />
              <NumberInput
                label={t("Balance")}
                value={bankAccount.balance}
                onChange={(value) =>
                  setBankAccount({ ...bankAccount, balance: value })
                }
                precision={2}
                min={0}
                mt="md"
              />
            </Group>

            <Group position="right" mt="xl">
              <Button size="xs" onClick={handleUpdate}>
                {t("Update")}
              </Button>
            </Group>
          </form>
        )}
      </FormSectionCard>
    </>
  );
};

export default BankAccountSetting;
