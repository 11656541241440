import { TextInput } from "@mantine/core";
import React, { useEffect } from "react";

import _ from "lodash";
import { useServerApi } from "../hooks/userServerApi";
import { useTranslation } from "react-i18next";

const UniqueTextInput = ({
  form,
  name,
  uniqueEntity,
  errorMessage = "Not unqiue", //ID not Unqiue error message
  formatMessage = "Please enter a valid ID", //ID format error message
  validation,
  ...props
}) => {
  const value = _.get(form.values, name);
  const id = _.get(form.values, "_id");
  const { t } = useTranslation();

  const [api] = useServerApi();

  const checkDuplicate = async () => {
    if (_.isEmpty(value)) return;
    const result = await api.search({
      apiEntity: uniqueEntity,
      searchQuery: { [name]: value?.trim() },
    });

    if (!_.isEmpty(result.docs) && id !== result.docs[0]._id) {
      form.setFieldError(name, t(errorMessage));
    }
  };

  const checkFormat = () => {
    if (!validation) return;
    if (!validation(value, form.values)) {
      form.setFieldError(name, t(formatMessage));
    }
  };

  useEffect(() => {
    checkDuplicate();
    checkFormat();
  }, [value]);

  return <TextInput {...props}></TextInput>;
};

export default UniqueTextInput;
