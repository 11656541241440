import React from "react";
import {
  PageList,
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";

import { useCellRender } from "../../hooks/useCellRender";
import { useFormatter } from "../../hooks/useFomatter";

import ActionCellRender from "../../components/actionCellRender";

import { IconTrash, IconPencil, IconSettings } from "@tabler/icons";
import { NumberInput, TextInput, Select, Text } from "@mantine/core";
import RemoteSelect2 from "../../components/remoteSelect2";

import _ from "lodash";
import moment from "moment";
import { DatePicker } from "@mantine/dates";
import { DATA_INCENTIVE_TYPE } from "../../data/options";
import AutoCodeInput from "../../components/autoCodeInput";

const apiEntity = "incentive";

const initialValues = {
  status: "PENDING",
  rows: [],
  total: 0,
  verified: 0,
  missing: 0,
  policyNotFound: 0,
  notMatch: 0,
  period: moment().format("YYYYMM"),
};

const Incentive = () => {
  const [cellRender] = useCellRender();
  const [formatter] = useFormatter();

  const tableSchema = {
    showActionColumn: true,
    columns: [
      // {
      //   field: "status",
      //   cellRender: (col, data) => cellRender.Status(data.status),
      // },
      { field: "code", headerName: "Code", sortable: true },
      { field: "incentiveType", headerName: "Type", sortable: true },
      {
        field: "title",
        headerName: "Title",
        sortable: true,
        cellRender: (col, data) => (
          <Text size="xs" lineClamp={2} maw={300}>
            {data.title}
          </Text>
        ),
      },
      {
        field: "amount",
        headerName: "Amount",
        sortable: true,
        cellRender: (col, data) => cellRender.Currency(data.amount),
      },
      {
        field: "consultant",
        headerName: "Consultant",
        sortable: true,
        cellRender: (col, data) => cellRender.User(data.consultant),
      },
      {
        field: "issueDate",
        headerName: "Issue Date",
        sortable: true,
        cellRender: (col, data) => moment(data.issueDate).format("LLL"),
      },

      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
        ],
        actionMenu: [
          {
            name: "delete",
            label: "Delete data",
            icon: IconTrash,
            props: {
              color: "red",
            },
          },
        ],
      },
    ],
    searchableFields: [
      "code",
      "type",
      "consultantSnapshot.name",
      "consultantSnapshot.cname",
      "consultantSnapshot.staffId",
      "remark",
      "title",
    ],
  };

  const formSchema = {
    title: "Incentive",
    display: {
      mode: ENUM_FORM_DISPLAY.DRAWER,
      size: "xl", //xs, sm, xl  ....100%
      position: "right",
    },
    apiEntity: "incentive",

    initialValues: {
      code: "",
      title: "",
      descirption: "",
    },

    validate: {
      consultant: (value) => (!value ? "No Consultant selected" : null),
    },

    layout: {
      fields: [
        {
          name: "code",
          component: AutoCodeInput,
          props: {
            required: true,
            label: "Code",
            placeholder: "Auto Code",
          },
        },

        {
          name: "incentiveType",
          component: Select,
          props: {
            required: true,
            label: "Type",
            placeholder: "",
            searchable: true,
            data: DATA_INCENTIVE_TYPE,
            default: "MPF INCENTIVE",
          },
        },
        {
          name: "issueDate",
          component: DatePicker,
          type: "datePicker",
          props: {
            required: true,
            label: "Issue Date",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },
        {
          name: "consultant",
          component: RemoteSelect2,

          props: {
            apiEntity: "user",
            required: true,
            label: "Consultant",
            placeholder: "Please select consultant(s)",
            valueField: "_id",
            labelField: "name",
            labelRender: (data) =>
              `${data.name}, ${data.aliasName} (${data.staffId ?? " -- "})`,
            multiple: false,
            preQuery: {
              $and: [{ isConsultant: true }, { status: "ACTIVE" }],
            },
            searchFields: [
              "aliasName",
              "name",
              "staffId",
              "cname",
              "email",
              "phone",
            ],
            sort: {
              by: "name",
              order: "asc",
            },
          },
        },

        {
          name: "title",
          component: TextInput,
          props: {
            required: true,
            label: "Title",
            placeholder: "",
          },
        },
        {
          name: "description",
          component: TextInput,
          props: {
            // required: true,
            label: "Description",
            placeholder: "",
          },
        },

        {
          name: "amount",
          component: NumberInput,

          props: {
            required: true,
            label: "Incentive Amounts",

            precision: 2,
            // parser: formatter.currencyParser,
            // formatter: formatter.currencyFormat,
          },
        },
      ],
    },
  };

  return (
    <>
      {/* {
				<ReactJson
					src={initialValues}
					style={{ background: "white" }}
					collapsed
				/>
			} */}

      <PageList
        title={"Incentive"}
        apiEntity={apiEntity}
        tableSchema={tableSchema}
        formSchema={formSchema}
        showImport={true}
      />
    </>
  );
};

export default Incentive;
