import { Button, Group } from "@mantine/core";
import React from "react";

import UserDisableCreateOrderButton from "./userDisableCreateOrderButton";
import UserActiveEnableButton from "./userActiveEnableButton";
import UserLeaveActionButton from "./userLeaveActionButton";
import UserWalletActiveButton from "./userWalletActiveButton";
import UserEditButton from "./userEditButton";
import UserTransferClientButton from "./userTransferClientButton";

const UserActionList = ({ form, role }) => {
  const showEditOrderButton = role == "HR" || role === "MANAGEMENT";

  return (
    <Group mt={10}>
      {showEditOrderButton && <UserEditButton mainForm={form} />}

      <UserDisableCreateOrderButton mainForm={form} />
      <UserActiveEnableButton mainForm={form} />
      <UserWalletActiveButton mainForm={form} />
      <UserLeaveActionButton mainForm={form} />
      <UserTransferClientButton mainForm={form} />
    </Group>
  );
};

export default UserActionList;
