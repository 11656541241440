import React from "react";
import { useTranslation } from "react-i18next";
import { UnstyledButton, Group, Text } from "@mantine/core";
import { IconCirclePlus } from "@tabler/icons";
import { useFormRender } from "../hooks/useFormRender";
import { useServerApi } from "../hooks/userServerApi";
import { AddActionButton, ENUM_FORM_DISPLAY } from "./pageList";
import { useAuthUser } from "react-auth-kit";

const AddNewButton = ({
  formSchema,
  label = "Add New",
  onSubmit,
  onSubmitSuccess,
  onChange,
  mt,
}) => {
  const { t } = useTranslation();

  const postSubmitSuccess = (data) => {
    if (onSubmitSuccess) {
      onSubmitSuccess(data);
    }
    formAction.close();
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    postSubmitSuccess,
    onSubmit,
    onChange
  );

  const addNew = () => {
    if (!formSchema) return;
    // console.log("add new", formSchema);
    formAction.open({ mode: "add" });
  };

  return (
    <>
      <AddActionButton
        mt={mt}
        justify="left"
        mb="0"
        handleActionClick={addNew}
        label={label}
      />
      {renderForm()}
    </>
  );
};

export default AddNewButton;
