import React, { useEffect, useState, useContext } from "react";
import { SocketContext } from "../App";
import { useTranslation } from "react-i18next";
import {
  Navbar,
  ScrollArea,
  createStyles,
  Code,
  UnstyledButton,
  Image,
  Badge,
  Text,
  Group,
  ActionIcon,
  Tooltip,
  TextInput,
  useMantineColorScheme,
  useMantineTheme,
  Switch,
  Header,
  MediaQuery,
  Title,
  Burger,
  Divider,
} from "@mantine/core";
import { mainLinks, functionLinks } from "../data/navLinks";
import {
  IconNotes,
  IconCalendarStats,
  IconGauge,
  IconPresentationAnalytics,
  IconFileAnalytics,
  IconAdjustments,
  IconLock,
  IconBulb,
  IconUser,
  IconCheckbox,
  IconSearch,
  IconPlus,
  IconSelector,
  IconDashboard,
  IconBrandGoogleDrive,
  IconFileSearch,
  IconFileText,
  IconLogout,
  IconSun,
  IconMoonStars,
  IconSettings,
  IconMessage,
  IconHome,
} from "@tabler/icons";
import _ from "lodash";

import { useSignOut, useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import UserPopupButton from "./userPopupButton";
import FrontierHeader from "../components/header";
import ReactJson from "react-json-view";
import { useForceUpdate } from "@mantine/hooks";
import SearchBox from "./searchBox";

const useStyles = createStyles((theme) => ({
  navbar: {
    paddingTop: 0,
  },

  section: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    marginBottom: theme.spacing.md,

    "&:not(:last-of-type)": {
      borderBottom: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[3]
      }`,
    },
  },

  searchCode: {
    fontWeight: 700,
    fontSize: 10,
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[7]
        : theme.colors.gray[0],
    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[2]
    }`,
  },

  mainLinks: {
    paddingLeft: theme.spacing.md - theme.spacing.xs,
    paddingRight: theme.spacing.md - theme.spacing.xs,
    paddingBottom: theme.spacing.md,
  },

  mainLink: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    fontSize: theme.fontSizes.xs,
    padding: `8px ${theme.spacing.xs}px`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },

  mainLinkInner: {
    display: "flex",
    alignItems: "center",
    flex: 1,
  },

  mainLinkIcon: {
    marginRight: theme.spacing.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[2]
        : theme.colors.gray[6],
  },

  collectionIcons: {
    marginRight: theme.spacing.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[2]
        : theme.colors.gray[6],
  },

  mainLinkBadge: {
    padding: 0,
    width: 20,
    height: 20,
    pointerEvents: "none",
  },

  collections: {
    paddingLeft: theme.spacing.md - 6,
    paddingRight: theme.spacing.md - 6,
    paddingBottom: theme.spacing.md,
  },

  collectionsHeader: {
    paddingLeft: theme.spacing.md + 2,
    paddingRight: theme.spacing.md,
    marginBottom: 5,
  },

  collectionLink: {
    display: "block",
    padding: `8px ${theme.spacing.xs}px`,
    textDecoration: "none",
    borderRadius: theme.radius.sm,
    fontSize: theme.fontSizes.xs,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    lineHeight: 1,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },

  header: {
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
    paddingTop: theme.spacing.md,
  },

  footer: {
    // borderTop: `1px solid ${
    //     theme.colorScheme === "dark"
    //         ? theme.colors.dark[4]
    //         : theme.colors.gray[3]
    // }`,
    // paddingTop: theme.spacing.md,
  },
}));

function FNavBar({ hidden, width, setOpened }) {
  const { classes } = useStyles();
  const auth = useAuthUser();
  const socket = useContext(SocketContext);
  const [openingTicketCount, setOpeningTicketCount] = useState(0);
  const { userRole } = auth();
  const Navigate = useNavigate();
  const forceUpdate = useForceUpdate();
  const { t } = useTranslation();

  const sendSocketgetOpenTicketCount = async () => {
    try {
      if (!socket) throw new Error("No Socket Connection");
      const data = {
        userId: auth().id,
      };

      socket.emit("getOpenTicketCount", data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    sendSocketgetOpenTicketCount();

    socket.on("updateOpenTicketCount", (count) => {
      // console.log("updateOpenTicketCount", count);
      setOpeningTicketCount(count);
      const l = functionLinks.find((l) => l.link === "/chat");
      if (l.notifications === count) return;

      l.notifications = count;
      forceUpdate();
    });

    socket.on("needUpdateCount", () => {
      // console.log("needUpdateCount");
      sendSocketgetOpenTicketCount();
    });

    return () => {
      socket.off("updateOpenTicketCount");
      socket.off("needUpdateCount");
    };
  }, []);

  const availableFunctionLink = functionLinks
    .filter((l) =>
      userRole.links.find(
        (roleLink) => roleLink.link === l.linkId && roleLink.accessRight > 0
      )
    )
    ?.filter((l) => l.showOnNavBar === true);

  const renderLinks = availableFunctionLink.map((link, index) => {
    return (
      <span key={link.linkId}>
        {(index === 0 ||
          link.group !== availableFunctionLink[index - 1].group) &&
          link.group && (
            <Text size={"xs"} weight={500} mt="xl" mb={"xs"}>
              {t(link.group)}
            </Text>
          )}
        <UnstyledButton
          key={link.linkId}
          className={classes.mainLink}
          onClick={() => Navigate(link.link)}
          h={35}
        >
          <div className={classes.mainLinkInner}>
            <link.icon
              size={20}
              className={classes.mainLinkIcon}
              stroke={1.5}
            />
            <a
              className={classes.collectionLink}
              href={link.link}
              // onClick={(event) => event.preventDefault()}
            >
              <span>{t(link.label)}</span>
            </a>
          </div>
          {link.notifications != null && (
            <Badge size="sm" variant="filled" className={classes.mainLinkBadge}>
              {link.notifications}
            </Badge>
          )}
        </UnstyledButton>
        {link.showDivider && <Divider mt={"md"}></Divider>}
      </span>
    );
  });

  return (
    <>
      <Navbar
        p="md"
        hiddenBreakpoint="lg"
        hidden={hidden}
        width={width}
        className={classes.navbar}
        zIndex={1000}
      >
        {/* <ReactJson src={userRole.links} style={{ background: "white" }} /> */}
        <Navbar.Section className={classes.section}>
          <div className={classes.mainLinks}>
            <Group position="apart" mx={"md"} align="start">
              <Group>
                <span>
                  <Group spacing={0} noWrap>
                    {/* {process.env.REACT_APP_CLIENT_SHOW_LOGO_IN_NAVBAR && (
                      <Image
                        src={`${process.env.REACT_APP_FILE_URL}/logo.png`}
                        width={process.env.REACT_APP_CLIENT_LOGO_WIDTH}
                        pt={10}
                        mr={10}
                      />
                    )} */}
                    <span>
                      <Group>
                        <Title order={2}>{process.env.REACT_APP_NAME}</Title>
                        <Code sx={{ fontWeight: 700 }} ml="sm">
                          {process.env.REACT_APP_VERSION}
                        </Code>
                      </Group>
                      <Text size={"11px"} lineClamp={1}>
                        {t(process.env.REACT_APP_SUBTITLE)}
                      </Text>
                    </span>
                  </Group>
                </span>
              </Group>
            </Group>
          </div>
        </Navbar.Section>

        <Navbar.Section className={classes.section} grow component={ScrollArea}>
          <SearchBox />

          <div className={classes.mainLinks}>{renderLinks}</div>
        </Navbar.Section>

        <Navbar.Section className={classes.footer}>
          <UserPopupButton user={auth()} />
        </Navbar.Section>
      </Navbar>
    </>
  );
}

export default React.memo(FNavBar);
