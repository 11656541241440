import React from "react";
import { useEffect, useState } from "react";
import { Page } from "../layout/page";
import { useServerApi } from "../hooks/userServerApi";
import _ from "lodash";
import moment from "moment";
import {
  ActionIcon,
  Group,
  Text,
  UnstyledButton,
  Button,
  TextInput,
  Textarea,
  Select,
  NumberInput,
  Card,
  Badge,
} from "@mantine/core";
import { IconCirclePlus, IconSettings } from "@tabler/icons";
import { useTranslation } from "react-i18next";
import { useFormRender } from "../hooks/useFormRender";
import FormConfirmation from "../components/formConfirmation";
import { DATA_BANK_WITH_CODE, DATA_BANK_ACCOUNT_TYPE } from "../data/options";
import { showNotification } from "@mantine/notifications";
import { IconExclamationMark } from "@tabler/icons";
import { useFormatter } from "../hooks/useFomatter";
import {
  ENUM_FORM_LAYOUT_CONTAINER,
  ENUM_FORM_DISPLAY,
} from "../components/pageList";

const BankAccountCard = ({ bankAccount, onClick }) => {
  const [formatter] = useFormatter();
  const getBankCode = (label) =>
    DATA_BANK_WITH_CODE.find((option) => option.label === label)?.value || "";
  const getBankLabel = (value) =>
    DATA_BANK_WITH_CODE.find((option) => option.value === value)?.label.replace(
      /\[\s*\d+\s*\]\s*/,
      ""
    ) || "";
  return (
    <UnstyledButton onClick={() => onClick(bankAccount._id)}>
      <Card
        shadow="sm"
        padding="lg"
        radius="md"
        withBorder
        mb="md"
        style={{ width: 350 }}
      >
        <Group position="apart" align="flex-start">
          <Text size="sm" weight={800}>
            {bankAccount.accountNumber}
          </Text>

          <Badge
            size="xs"
            radius={0}
            color={
              bankAccount.accountType === "Checking"
                ? "blue"
                : bankAccount.accountType === "SAVINGS"
                ? "green"
                : "red"
            }
          >
            {bankAccount.accountType}
          </Badge>
        </Group>

        <Text size="sm" color="dimmed" lineClamp={1}>
          {getBankLabel(bankAccount.bank)}
        </Text>
        {/* <Text color="dimmed">{bankAccount.accountHolderName}</Text> */}
        <Group position="apart" mt="md">
          <></>
          <Text size="xs" w={700}>
            Balance: {formatter.currency(bankAccount.balance)}
          </Text>
        </Group>
      </Card>
    </UnstyledButton>
  );
};

const BankAccount = () => {
  const [api] = useServerApi();
  const [bankAccounts, setBankAccounts] = useState([]);
  const { t } = useTranslation();

  const fetchBankAccounts = async () => {
    let result = await api.search({
      apiEntity: "bankAccount",
      pageSize: 20,
      currentPage: 1,
    });
    // console.log("bankAccounts", result);
    if (!result || !result.docs) return;

    setBankAccounts(result.docs);
  };

  useEffect(() => {
    fetchBankAccounts();
  }, []);

  const newBankAccountFormSchema = {
    title: "Bank Account",
    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "xl", //xs, sm, xl  ....100%
    },

    showSaveBar: true,
    apiEntity: "userRole",
    layout: {
      containers: [
        {
          key: "1",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            // title: "Bank Account Info",
            description: "Please fill in the bank account information",
          },
        },
      ],
      fields: [
        {
          name: "accountType",
          component: Select,
          parent: "1",
          props: {
            required: true,
            label: "Account Type",
            placeholder: "Select account type",
            data: DATA_BANK_ACCOUNT_TYPE,
          },
        },
        {
          name: "bank",
          component: Select,
          parent: "1",
          props: {
            required: true,
            label: "Bank",
            placeholder: "Select",
            data: DATA_BANK_WITH_CODE,
            searchable: true,
            clearable: true,
          },
        },
        {
          name: "shortName",
          component: TextInput,
          parent: "1",
          props: {
            required: true,
            placeholder: "Enter short name / alias Name for the bank account",
            label: "Short Name",
          },
        },

        {
          name: "accountNumber",
          component: TextInput,
          parent: "1",
          props: {
            required: true,
            placeholder: "Enter account number",
            label: "Account Number",
          },
        },
        {
          name: "accountHolderName",
          component: TextInput,
          parent: "1",
          props: {
            required: true,
            placeholder: "Enter account holder name",
            label: "Account Holder Name",
          },
        },
      ],
    },
  };

  const onSubmit = async ({ values }) => {
    try {
      //   console.log("values x", values);
      const result = await api.add({
        apiEntity: "bankAccount",
        values: values,
      });
      //   console.log("result", result);
      if (result.success) {
        formAction.close();
        fetchBankAccounts();
        showNotification({
          title: "Success",
          message: "Bank account added successfully",
          color: "green",
        });
      }
    } catch (error) {
      console.log("error", error);
      showNotification({
        title: "Error",
        message: error.message,
        color: "red",
      });
    }
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    newBankAccountFormSchema,
    null,
    onSubmit
  );

  const handleAddBankAccount = () => {
    // console.log("add bank account");
    formAction.open({ mode: "add" });
  };

  const onBankClick = (bankAccountId) => {
    //console.log("bankAccountId", bankAccountId);
    // Navigate to bank account detail page in a new window
    window.location.href = `/m/bankAccountDetail?id=${bankAccountId}`;
  };

  return (
    <>
      {renderForm()}
      {/* bankAccounts:{bankAccounts.length}{" "} */}
      <Page title="Bank Account">
        <Group>
          {bankAccounts.map((bankAccount) => (
            <BankAccountCard bankAccount={bankAccount} onClick={onBankClick} />
          ))}
        </Group>

        <Button
          size="xs"
          variant="subtle"
          onClick={handleAddBankAccount}
          mt="xl"
        >
          <IconCirclePlus size={15} />
          {t("New Bank Account")}
        </Button>
      </Page>
    </>
  );
};

export default BankAccount;
