import React, { useState, useEffect } from "react";
import { useServerApi } from "../hooks/userServerApi";
import _ from "lodash";
import moment from "moment";
import { PageList } from "./pageList";

import ReactJson from "react-json-view";
import { useCellRender } from "../hooks/useCellRender";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";

const UserOrderHistory = ({ form, id, ...props }) => {
  const userId = _.get(form?.values, "_id") ?? id;
  const [api] = useServerApi();
  const [cellRender] = useCellRender();
  const location = useLocation();
  const auth = useAuthUser();
  const [preQuery, setPreQuery] = useState();

  const getEntityLink = () => {
    const { userRole } = auth();
    if (!userRole) return null;
    const roleLink = userRole.entityLinks?.find((l) => l.entity === "order");
    if (!roleLink || !roleLink.link) return null;
    return roleLink.link;
    // return `../${roleLink.link}?id=${i}`;
  };

  const orderFormSchema = {};

  const tableSchema = {
    name: "userOrderHistory",
    preQuery: { consultants: userId },
    searchableFields: ["status", "searchText", "consultants"],

    columns: [
      {
        field: "signedDate",

        headerName: "Sign Date",
        cellRender: (col, order) => moment(order.signedDate).format("ll"),
      },
      {
        field: "code",

        cellRender: (col, order) => (
          <div>
            <div>
              <a
                href={`${getEntityLink()}?id=${order._id}&mode=${"edit"}`}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                {order.code}
              </a>
            </div>
            <div>{order.policyNumber}</div>
          </div>
        ),
      },
      { field: "product.type.name", headerName: "Type" },
      { field: "client.name", headerName: "Client" },
      { field: "payment.payMode", headerName: "Pay Mode" },
      {
        field: "payment.premium",

        headerName: "Premium",
        cellRender: (col, order) =>
          cellRender.Currency(order.payment?.premium, order.payment?.currency),
      },

      {
        field: "payment.yearPremium",

        headerName: "FY Premium",
        cellRender: (col, order) =>
          cellRender.Currency(order.payment?.yearPremium, "HKD"),
      },

      // { field: 'client',          filter: true },

      {
        field: "status",

        cellRender: (col, order) => cellRender.Order.status(order.status),
      },
    ],
    // select: [
    //   "code product policyNumber client consultants status createdAt signedAt type pendingCases",
    // ],
  };

  return (
    <>
      {/* <ReactJson src={tableSchema} style={{ background: "white" }} collapsed /> */}
      {/* {userId} */}

      {userId && (
        <PageList
          title=""
          apiEntity="order"
          hideSearchBar={true}
          initSort={{ by: "code", order: "desc" }}
          hideActionButton={true}
          tableSchema={tableSchema}
          formSchema={orderFormSchema}
          tablePadding={-10}
        />
      )}
    </>
  );
};

export default UserOrderHistory;
