import React, { useState, useRef, useEffect } from "react";
import {
  Text,
  Title,
  Button,
  TextInput,
  ActionIcon,
  Group,
  Menu,
  Card,
  Space,
  Grid,
  Center,
  FileButton,
  Box,
  UnstyledButton,
  Image,
  Avatar,
  Input,
  Loader,
  Table,
  Checkbox,
  ScrollArea,
} from "@mantine/core";

import { useForceUpdate, useHotkeys, randomId } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import moment from "moment";
import {
  IconTrash,
  IconCheck,
  IconSettings,
  IconExclamationMark,
  IconMessageCircle,
  IconCoin,
  IconDownload,
  IconDotsVertical,
  IconList,
  IconGridPattern,
  IconPencil,
  IconLayoutGrid,
  IconRefresh,
} from "@tabler/icons";
import { useTranslation } from "react-i18next";
import { Dropzone, DropzoneProps, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import ReactJson from "react-json-view";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import {
  faFileImage,
  faFileVideo,
  faFileZipper,
  faFileCsv,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import { useServerApi } from "../hooks/userServerApi";
import { useDateRender } from "../hooks/useDateRender";
import { useClickOutside } from "@mantine/hooks";
import generatePdfThumbnails from "pdf-thumbnails-generator";
import DataTable from "./dataTable";
import { useAuthUser } from "react-auth-kit";

const filesize = require("file-size");

const PdfPreviewer = ({ fileUrl, pageIndex = 1 }) => {
  const generateThumbnail = async (file) => {
    // console.log("generateThumbnail", file);
    try {
      const thumbnails = await generatePdfThumbnails(file, 150);
      // console.log(thumbnails);
    } catch (error) {
      console.log(error);
    }
    const thumbnails = generatePdfThumbnails(file, 150);
  };

  useEffect(() => {
    if (!fileUrl) return;
    generateThumbnail(fileUrl);
  }, [fileUrl]);
  return <>PDF</>;
};

export const isImageFile = (mimetype) => {
  return [
    "image/png",
    "image/gif",
    "image/jpg",
    "image/jpeg",
    "image/svg+xml",
    "image/webp",
    "video/mp4",
  ].includes(mimetype);
};

export const isPDFFile = (mimetype) => {
  return ["application/pdf"].includes(mimetype);
};

export const supportFiles = [
  { ext: "png", icon: faFileImage, color: "#8479F1", mimetype: "image/png" },
  { ext: "gif", icon: faFileImage, color: "#8479F1", mimetype: "image/gif" },
  {
    ext: "jpeg",
    icon: faFileImage,
    color: "#8479F1",
    mimetype: "image/jpeg",
  },
  { ext: "jpg", icon: faFileImage, color: "#8479F1", mimetype: "image/jpg" },
  { ext: "svg", icon: faFileImage, color: "#aaa", mimetype: "image/svg+xml" },
  { ext: "webp", icon: faFileImage, color: "#aaa", mimetype: "image/webp" },
  { ext: "mp4", icon: faFileVideo, color: "#aaa", mimetype: "video/mp4" },
  {
    ext: "zip",
    icon: faFileZipper,
    color: "#aaa",
    mimetype: "application/zip",
  },
  { ext: "csv", icon: faFileCsv, color: "#aaa", mimetype: "text/csv" },
  {
    ext: "pdf",
    icon: faFilePdf,
    color: "#EA5455",
    mimetype: "application/pdf",
  },
  {
    ext: "doc",
    icon: faFileWord,
    color: "#467FFF",
    mimetype: "application/msword",
  },
  {
    ext: "docx",
    icon: faFileWord,
    color: "#467FFF",
    mimetype:
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  },
  {
    ext: "xls",
    icon: faFileExcel,
    color: "green",
    mimetype: "application/vnd.ms-excel",
  },
  {
    ext: "xlsx",
    icon: faFileExcel,
    color: "green",
    mimetype:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  },
  {
    ext: "ppt",
    icon: faFilePowerpoint,
    color: "#EA5455",
    mimetype: "application/vnd.ms-powerpoint",
  },
  {
    ext: "pptx",
    icon: faFilePowerpoint,
    color: "#EA5455",
    mimetype:
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  },
  {
    ext: "exe",
    icon: faFile,
    color: "black",
    mimetype: "application/vnd.microsoft.portable-executable",
  },
];

export const getFileIcon = (mimetype) => {
  let file = supportFiles.find((f) => f.mimetype === mimetype);
  if (!file) return faFile;
  return file.icon;
};

export const getFileIconColor = (mimetype) => {
  let file = supportFiles.find((f) => f.mimetype === mimetype);
  if (!file) return "#ccc";
  return file.color;
};

export const K_FILE_LIST_DISPLAY_MODE = {
  GRID: 1,
  LIST: 2,
};

const FileList = ({
  form,
  name,
  staticFiles,
  title,
  columnSpan = 4,
  disabled = false,
  uploadLabel = "Upload Files",
  tableMarginBottom = 300,
  showNoFilesMessage = true,
  defaultDisplayMode = K_FILE_LIST_DISPLAY_MODE.LIST,
  height = 600,
  showScroll = false,
  ...props
}) => {
  const forceUpdate = useForceUpdate();
  const [api] = useServerApi();
  const [uploading, setUploading] = useState(false);
  const [displayMode, setDisplayMode] = useState(defaultDisplayMode);

  const resetRef = useRef(null);
  let files = _.get(form?.values, name) || staticFiles || [];
  const entityId = _.get(form?.values, "_id") || [];
  const NO_FILES_MESSAGE = "No files upload yet.";
  const auth = useAuthUser();
  const { t } = useTranslation();
  const role = auth()?.userRole?.name?.toUpperCase() ?? "CONSULTANT";

  const [allowEdit, setAllowEdit] = useState(false);

  useEffect(() => {
    if (role === "CONSULTANT") return setAllowEdit(false);
    setAllowEdit(true);
  }, [role]);

  useHotkeys([
    ["ctrl+A", () => selectAll()],
    ["mod+A", () => selectAll()],
    ["backspace", () => deleteSelected()],
    ["delete", () => deleteSelected()],
    ["escape", () => unSelectAll()],
  ]);

  const resetFileButton = () => resetRef.current?.();

  const selectAll = () => {
    files.forEach((f) => (f.selected = true));
    forceUpdate();
  };

  const unSelectAll = () => {
    console.log("unSelectAll");
    files.forEach((f) => (f.selected = false));
    forceUpdate();
  };

  const getSelectedFiles = () => files.filter((f) => f.selected);

  const deleteSelected = () => {
    if (!window.confirm("Are you sure to remove the files?")) return;
    for (let i = files.length - 1; i >= 0; i--) {
      if (files[i].selected) {
        handleRemove(files[i], false);
      }
    }
  };

  const downloadSelected = async () => {
    const links = getSelectedFiles().map((f) => f.link);
    if (!links || links.length == 0) return;
    downloadZipFiles(links);
  };

  const downloadAll = () => {
    const links = files.map((f) => f.link);
    downloadZipFiles(links);
  };

  const downloadZipFiles = async (links) => {
    const filename = "zipFiles";

    try {
      let result = await api.downloadZipFiles(filename, links);
      if (result && result.success) {
        return window.open(result.data.link, "_blank");
      } else {
        // console.log(result);
        showNotification({
          title: `Download Zip Fail`,
          color: "red",
          icon: <IconExclamationMark size={18} />,
          message: "Download Fail",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUploadFiles = async (uploadFiles) => {
    try {
      // console.log("handleUploadFiles", uploadFiles, files);
      let newFiles = [];
      for (const file of uploadFiles) {
        if (!file) continue;

        setUploading(true);
        let result = await api.uploadFile("files", file, entityId, files);
        setUploading(false);

        let { data, success } = result;
        if (success) {
          showNotification({
            title: `Upload successfully`,
            message: "Upload Success",
          });
          newFiles.push(data);
        } else {
          showNotification({
            title: `${file.name} upload unsuccessful`,
            color: "red",
            icon: <IconExclamationMark size={18} />,
            message: "Upload Fail",
          });
        }
      }
      resetFileButton();
      form.updateAndSave({ [name]: [...files, ...newFiles] });
    } catch (error) {
      // console.log(error);
      setUploading(false);
      showNotification({
        title: `Files upload unsuccessful`,
        color: "red",
        icon: <IconExclamationMark size={18} />,
        message: "Upload Fail",
      });
    }
  };

  const handleRemove = (file, needConfirm = true) => {
    if (
      needConfirm &&
      !window.confirm(`Are you sure to remove the file ${file.name}?`)
    )
      return;

    let index = -1;
    if (file._id) {
      index = files.findIndex((f) => f._id === file._id);
      if (index === -1) return;
    } else if (file.name) {
      index = files.findIndex((f) => f.name === file.name);
      if (index === -1) return;
    }

    files.splice(index, 1);
    form.updateAndSave({ [name]: [...files] });
  };

  const handleEditFileName = (file, editing) => {
    file.editing = editing;
    forceUpdate();
  };

  const setSelected = (file) => {
    if (file.selected) return openFile(file);
    unSelectAll();
    file.selected = true;
    forceUpdate();
  };

  const FilesActionButton = () => (
    <Menu shadow="md" width={200}>
      <Menu.Target>
        <ActionIcon variant="default">
          <IconDotsVertical size="1rem" />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>{`Actions`}</Menu.Label>
        <Menu.Item onClick={downloadSelected} icon={<IconDownload size={14} />}>
          Download Selected
        </Menu.Item>
        <Menu.Item onClick={downloadAll} icon={<IconDownload size={14} />}>
          Download All
        </Menu.Item>
        <Menu.Item onClick={deleteSelected} icon={<IconTrash size={14} />}>
          Delete
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );

  const openFile = (file) => {
    // console.log("open FIle", file);
    //if (!file.url) return;
    // console.log("openFile", file);
    if (!file.link) return;
    const url = `${process.env.REACT_APP_FILE_URL}${file.link}`;
    window.open(url, "_blank");
  };

  const handleCardOnClick = (event, file) => {
    if (event.altKey) {
      // console.log("Alt, yay!");
      file.selected = true;
      return forceUpdate();
    }
    setSelected(file);
  };

  const Previewer = ({ file }) => {
    const isImage = isImageFile(file.mimetype);
    const isPdf = isPDFFile(file.mimetype);

    return (
      <>
        {isImage && (
          <div style={{ height: "140px", bakcgorund: "#F8F8F8" }}>
            <Image
              src={`${process.env.REACT_APP_FILE_URL}${file.link}`}
              height={100}
            />

            {/* {`${process.env.REACT_APP_FILE_URL}${file.link}`} */}
          </div>
        )}

        {/* {isPdf && file && file.url && <PdfPreviewer fileUrl={file.url} />} */}

        {!isImage && (
          <Center style={{ height: "140px", background: "#F8F8F8" }}>
            <FontAwesomeIcon
              icon={getFileIcon(file.mimetype)}
              color={getFileIconColor(file.mimetype)}
              size="xl"
            ></FontAwesomeIcon>
          </Center>
        )}
      </>
    );
  };

  const FileCard = ({ file }) => (
    <Card
      withBorder
      shadow="sm"
      radius="md"
      style={{ borderColor: file.selected ? "#A5D8FF" : "" }}
      onClick={(e) => {
        handleCardOnClick(e, file);
      }}
    >
      <Card.Section>
        <Previewer file={file} />
      </Card.Section>
      <Text mt="sm" size="sm">
        {file.name}
      </Text>
      <Group position="apart">
        <Text color="dimmed" size="xs">
          {moment(file.created).format("YYYY/MM/DD")}
        </Text>
        <Text mt="sm" color="dimmed" size="xs">
          {filesize(file.size, { fixed: 0 }).human("si")}
        </Text>
      </Group>
    </Card>
  );

  const FilesGrid = ({ files }) => (
    <Grid mb={50}>
      {files &&
        files?.map((file) => (
          <Grid.Col key={file._id || file.id} span={columnSpan}>
            <div style={{ zIndex: 200 }}>
              <FileCard file={file} />
            </div>
          </Grid.Col>
        ))}
      <Center w={300} h={200}>
        {uploading && <Loader variant="bars" />}
      </Center>
    </Grid>
  );

  const FileTableHeader = ({ files, textSize = "xs" }) => {
    const [checked, setChecked] = useState(
      files.filter((f) => f.selected).length === files.length
    );

    return (
      <thead>
        <tr>
          <th>
            {/* {checked ? "checked" : "unchecked"} */}
            <Checkbox
              // onClick={toogleCheck}
              checked={checked}
              onChange={(event) => {
                setChecked(event.target.checked);
                files.forEach((f) => (f.selected = event.target.checked));
                forceUpdate();
              }}
            />
          </th>

          <th>
            <Text size={textSize}> File</Text>
          </th>

          <th>
            <Text size={textSize}> Size</Text>
          </th>
          <th>
            <Text size={textSize}> Date</Text>
          </th>
          <th></th>
        </tr>
      </thead>
    );
  };

  const FileTableBody = ({ files, textSize = "xs" }) => {
    const [rows, setRows] = useState([]);

    useState(() => {
      if (!files) setRows([]);
      setRows(_.cloneDeep(files));
    }, [files]);

    const setRowEdit = (row) => {
      row.editing = true;
      setRows([...rows]);
    };

    const changeFileName = async (index) => {
      try {
        if (index === -1 || !rows[index]) return;
        // console.log("changeFileName", rows, index);
        const r = rows[index];
        const result = await api.renameFile(r._id, r.link, r.name);
        // console.log("renameFile", result);
        if (result && !result.success) {
          throw new Error(result.message);
        }
        if (result.success && result.data) {
          rows[index].name = r.name;
          rows[index].link = result.data.newLink;
          setRows([...rows]);
          await form.updateAndSave({ [name]: rows });
        }
        // console.log("renameFile", result);
      } catch (error) {
        // console.log(error);
        showNotification({
          title: `Rename file ${rows[index].name} unsuccessful`,
          color: "red",
          icon: <IconExclamationMark size={18} />,
          message: "Rename Fail",
        });
      }
    };

    const readFileRemote = async (file, index) => {
      return "--";
    };

    const readFileCreateAt = (file, index) => {
      readFileRemote(file, index);
      return "--";
    };

    return (
      <>
        <tbody>
          {rows &&
            rows?.map((t, index) => (
              <tr key={index} onDoubleClick={() => setRowEdit(t, true)}>
                <td>
                  <Checkbox
                    checked={files[index].selected}
                    onChange={(e) => {
                      files[index].selected = e.target.checked;
                      forceUpdate();
                      // setRows([...rows]);
                    }}
                  />
                </td>
                <td align="left" style={{ minWidth: 250 }}>
                  {!t.editing && (
                    <Group noWrap spacing={"xs"}>
                      {/* <Checkbox
                        onChange={(v) => {
                          t.selected = true;
                          setRows([...rows]);
                        }}
                      /> */}
                      <FontAwesomeIcon
                        icon={getFileIcon(t.mimetype)}
                        color={getFileIconColor(t.mimetype)}
                        size="xl"
                      ></FontAwesomeIcon>
                      <UnstyledButton onClick={() => openFile(t)}>
                        <Text size={textSize}> {t.name} </Text>
                      </UnstyledButton>
                    </Group>
                  )}
                  {t.editing && (
                    <TextInput
                      size="xs"
                      autoFocus={true}
                      value={t.name}
                      onBlur={() => {
                        t.editing = false;
                        setRows([...rows]);
                        changeFileName(index);
                      }}
                      onKeyDown={(e) => {
                        // console.log("onKeyDown", e.key);
                        if (e.key === "Enter") {
                          t.editing = false;
                          setRows([...rows]);
                          changeFileName(index);
                          return;
                        }
                      }}
                      onChange={(e) => {
                        t.name = e.currentTarget.value;
                        setRows([...rows]);
                      }}
                    />
                  )}
                </td>
                <td>
                  <Text size={textSize}>
                    {filesize(t.size, { fixed: 0 }).human("si")}
                  </Text>
                </td>
                <td>
                  <Text size={textSize}>
                    {t.created
                      ? moment(t.created).format("LLL")
                      : readFileCreateAt(t, index)}
                  </Text>
                </td>
                <td>
                  {!disabled && allowEdit && (
                    <Group noWrap spacing={1}>
                      <ActionIcon onClick={() => setRowEdit(t, true)}>
                        <IconPencil size="1rem" />
                      </ActionIcon>
                      <ActionIcon onClick={() => handleRemove(t)}>
                        <IconTrash size="1rem" />
                      </ActionIcon>
                    </Group>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </>
    );
  };

  const FilesTable = ({ files }) => {
    return (
      <>
        {/* <ReactJson src={files} style={{ background: "white" }} /> */}
        <Table mb={tableMarginBottom} striped highlightOnHover>
          <FileTableHeader files={files} />
          <FileTableBody files={files} />
        </Table>
      </>
    );
  };

  const renderFiles = (mt = 10, mb = 20) => (
    <>
      <Group position="right" mb={mb} mt={mt}>
        {/* <Title order={5} mb={10}>{title}</Title> */}
        <Group spacing="xs">
          <ActionIcon variant="default" onClick={() => form.reload()}>
            <IconRefresh size="1rem" />
          </ActionIcon>
          <ActionIcon
            variant="default"
            onClick={() => setDisplayMode(K_FILE_LIST_DISPLAY_MODE.LIST)}
          >
            <IconList size="1rem" />
          </ActionIcon>
          <ActionIcon
            variant="default"
            onClick={() => setDisplayMode(K_FILE_LIST_DISPLAY_MODE.GRID)}
          >
            <IconLayoutGrid size="1rem" />
          </ActionIcon>
          <FilesActionButton />
        </Group>
      </Group>
      {showScroll && (
        <ScrollArea h={height}>
          {displayMode === K_FILE_LIST_DISPLAY_MODE.GRID && (
            <FilesGrid files={files} />
          )}
          {displayMode === K_FILE_LIST_DISPLAY_MODE.LIST && (
            <FilesTable files={files} />
          )}
        </ScrollArea>
      )}
      {!showScroll && (
        <>
          {displayMode === K_FILE_LIST_DISPLAY_MODE.GRID && (
            <FilesGrid files={files} />
          )}
          {displayMode === K_FILE_LIST_DISPLAY_MODE.LIST && (
            <FilesTable files={files} />
          )}
        </>
      )}
    </>
  );

  return (
    <Box>
      {/* FileList */}
      {/* <ReactJson
        src={form.values}
        name="files"
        style={{ background: "white" }}
        collapsed
      /> */}
      {/* entityId:{entityId} */}
      {/* <ReactJson src={files.filter(f =>f.selected)} name="files" style={{background:"white"}} collapsed/> */}
      {title && <Input.Label> {title}</Input.Label>}
      {!disabled && (
        <Dropzone
          onDrop={handleUploadFiles}
          onReject={(files) => console.log("rejected files", files)}
          // accept={{
          // 	"image/*": [], // All images
          // 	"text/html": [".html", ".htm"],

          // }}

          // activateOnClick={false}
          {...props}
        >
          <FileButton resetRef={resetRef} onChange={handleUploadFiles} multiple>
            {(props) => (
              <Button {...props} size="xs" loading={uploading}>
                {t(uploadLabel)}
              </Button>
            )}
          </FileButton>
        </Dropzone>
      )}
      {!_.isEmpty(files) && renderFiles()}
      {showNoFilesMessage && _.isEmpty(files) && (
        <Text size="xs" mb={"xl"}>
          {t(NO_FILES_MESSAGE)}
        </Text>
      )}
    </Box>
  );
};

export default FileList;
