import React from "react";
import {
  Text,
  TextField,
  Group,
  TextInput,
  Checkbox,
  Divider,
  Input,
  Card,
} from "@mantine/core";
import { useForceUpdate } from "@mantine/hooks";

const ContactInfoRow = ({ item, form, name }) => {
  const forceUpdate = useForceUpdate();

  return (
    <Card pt={0}>
      <Group mt={"sm"} noWrap>
        <TextInput
          size="xs"
          placeholder="Name"
          label="Name"
          value={item.content["name"]}
          onChange={(e) => {
            item.content["name"] = e.target.value;
            forceUpdate();
          }}
        />
        <TextInput
          size="xs"
          placeholder="Department"
          label="Department"
          value={item.content["department"]}
          miw={250}
          onChange={(e) => {
            item.content["department"] = e.target.value;
            forceUpdate();
          }}
        />
        <TextInput
          size="xs"
          placeholder="Title"
          label="Title"
          miw={220}
          value={item.content["title"]}
          onChange={(e) => {
            item.content["title"] = e.target.value;
            forceUpdate();
          }}
        />
        <TextInput
          size="xs"
          placeholder="Phone"
          label="Phone"
          value={item.content["phone"]}
          maw={100}
          onChange={(e) => {
            item.content["phone"] = e.target.value;
            forceUpdate();
          }}
        />
        <TextInput
          size="xs"
          placeholder="Email"
          label="Email"
          miw={200}
          value={item.content["email"]}
          onChange={(e) => {
            item.content["email"] = e.target.value;
            forceUpdate();
          }}
        />
        <Input.Wrapper label="Public" size="xs">
          <Checkbox
            mt={8}
            checked={item.content["isPublic"]}
            onChange={(e) => {
              item.content["isPublic"] = e.target.checked;
              forceUpdate();
            }}
          />
        </Input.Wrapper>
      </Group>
    </Card>
  );
};

export default ContactInfoRow;
