import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useImperativeHandle,
  useCallback,
} from "react";

import _ from "lodash";
import { Button, Select, MultiSelect, Box, Group, Paper } from "@mantine/core";
import { useServerApi } from "../hooks/userServerApi";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import MultiRemoteSelect from "./multiRemoteSelect";
import AddNewButton from "./addNewButton";
import { useForceUpdate } from "@mantine/hooks";

// window.addEventListener("error", function (e) {
//   console.error(e.message);
//   // prevent React's listener from firing
//   e.stopImmediatePropagation("ResizeObserver loop limit exceeded");
//   // prevent the browser's console error message
//   e.preventDefault();
// });

const RemoteSelect2 = (
  {
    form,
    name,
    label,
    value,
    sort,
    apiEntity,
    valueField = "_id",
    labelField,
    labelRender,
    placeholder = "Pick One",
    clearable = true,
    searchFields = ["code"],
    preQuery,
    required,
    multiple = false,
    onDataChange,
    onKeydown,
    clearAfterChange = false,
    disabled = false,
    variant = "default",
    pageSize = 20,
    size = "sm",
    w = "100%",
    allowAdd = false,
    addFormSchema,
    ...props
  },
  ref
) => {
  useImperativeHandle(
    ref,
    () => {
      return {
        clear: clearSelect,
        refresh,
      };
    },
    []
  );
  const [fieldValue, setFieldValue] = useState(); //selected value
  const [options, setOptions] = useState([]); //Converted to Options
  const [data, setData] = useState([]); //Orginal data sets

  const [searchQuery, setSearchQuery] = useState({});
  const [api] = useServerApi();
  const forceUpdate = useForceUpdate();

  const currentPage = 1; //Always display first page

  const getDisplayValue = () => {
    let v = value || _.get(form?.values, name);
    if (!v) return null;

    if (!multiple) {
      v = typeof v === "object" ? v._id : v;
    }
    if (multiple && Array.isArray(v)) {
      v = v.map((value) => value._id || value);
    }
    return v;
  };

  const displayValue = getDisplayValue();

  useDeepCompareEffect(() => {
    setFieldValue(displayValue);
  }, [displayValue]);

  const handleChange = useCallback((v) => {
    console.log("handleChange", v);
    setFieldValue(v);
    setFormValue(v);
    notifyDataChange(v);
  });

  const clearSelect = () => {
    setFieldValue(null);
  };
  const refresh = () => {
    fetchData();
  };

  const setFormValue = (v) => {
    if (!form || !name) return;
    // if (!value || _.isEmpty(value)) return;
    form.setFieldValue(name, v);
  };

  const notifyDataChange = (v) => {
    if (!_.isFunction(onDataChange)) return;
    // if (_.isEmpty(data)) return;
    onDataChange(
      data.find((d) => _.get(d, valueField) === v),
      form
    );
    if (clearAfterChange) {
      setFieldValue(null);
    }
  };

  const getLabel = (data) => {
    // console.log("getLabel", name, d);
    if (labelRender) return labelRender(data);
    if (labelField) return _.get(data, labelField);
    return "";
  };

  const fetchFieldValueData = async () => {
    if (!fieldValue) return;
    // console.log("fetchFieldValueData before", name, options);
    if (options.findIndex((o) => o.value === fieldValue) !== -1) return;

    let result = await api.search({
      apiEntity,
      pageSize,
      currentPage: 1,
      sort,
      searchQuery: { [valueField]: fieldValue },
      byAggregation: false,
    });
    // console.log("fetchFieldValueData", name, result);
    const rows = result.docs;
    if (_.isEmpty(rows)) return;

    let o = rows.map((r) => ({
      value: _.get(r, valueField),
      label: getLabel(r),
    }));
    setOptions([...o, ...options]);
  };

  const fetchData = async () => {
    // console.log("fetchData", searchQuery);
    try {
      let result = await api.search({
        apiEntity,
        pageSize,
        currentPage,
        sort,
        searchQuery,
        byAggregation: false,
      });

      handleFetchResult(result.docs);
      fetchFieldValueData();
    } catch (e) {
      setOptions([]);
      setData([]);
    }
  };

  const handleFetchResult = (rows) => {
    setData(rows);
    let options = rows.map((r) => ({
      value: _.get(r, valueField),
      label: getLabel(r),
    }));
    setOptions(options);
  };

  const handleRegexSpecialChar = (text) => {
    // console.log("handleRegexSpecialChar", text);
    const specialTxt = [
      "\\",
      "^",
      "$",
      ".",
      "|",
      "?",
      "*",
      "+",
      "(",
      ")",
      "[",
      "]",
      "{",
      "}",
    ];

    let result = text;
    specialTxt.forEach((special) => {
      result = result.replace(special, `\\${special}`);
    });
    return result;
  };
  const buildSearchQuery = (searchText, searchFields) => {
    if (!searchText) {
      return preQuery ? preQuery : {};
    }

    const txt = handleRegexSpecialChar(searchText)?.trim();

    let searchArr = searchFields?.map((field) => ({
      [field]: { $regex: txt, $options: "i" },
    }));

    if (!preQuery) return { $or: searchArr };
    return { $and: [preQuery, { $or: searchArr }] };
  };

  const handleSearchChange = useCallback(
    (searchText) => {
      let query = buildSearchQuery(searchText, searchFields);
      setSearchQuery(query);
    },
    [searchFields]
  );

  useDeepCompareEffect(() => {
    // console.log("fetchData");
    fetchData();
  }, [searchQuery]);

  useEffect(() => {
    // console.log("fetchData");
    // fetchData();
    fetchFieldValueData();
  }, [fieldValue]);

  // console.log("Render remote Select2");
  return (
    <>
      {/* <JsonViewer src={{ fieldValue }} /> */}
      {/* fieldValue:{JSON.stringify(fieldValue)} */}
      {/* displayValue: {JSON.stringify(displayValue)} <br></br> */}
      {/* multiple: {multiple ? "true" : "false"} */}
      {/* sort: {JSON.stringify(sort)} <br></br>  */}
      {/* {pageSize} */}
      {multiple ? (
        <MultiRemoteSelect
          form={form}
          name={name}
          label={label}
          value={value}
          sort={sort}
          pageSize={pageSize}
          apiEntity={apiEntity}
          valueField={valueField}
          labelField={labelField}
          labelRender={labelRender}
          searchFields={searchFields}
          onDataChange={onDataChange}
        />
      ) : (
        <Group wrap="nowrap">
          <Select
            // {...props}
            value={fieldValue}
            label={label}
            placeholder={placeholder}
            clearable={true}
            required={required}
            searchable
            onChange={handleChange}
            onSearchChange={handleSearchChange}
            onKeyDown={onKeydown}
            data={options}
            disabled={disabled}
            variant={variant}
            searchFields={searchFields}
            size={size}
            w={w}
          />
          {allowAdd && (
            <AddNewButton
              label=""
              mt="22"
              formSchema={addFormSchema}
              onSubmitSuccess={(data) => {
                if (!data || !data.values) return;
                handleChange(data?.values?._id);
                form.setFieldValue(name, data?.values);
              }}
              // onSubmit={(v) => console.log(v)}
            />
          )}
        </Group>
      )}
    </>
  );
};

export default React.forwardRef(RemoteSelect2);
