import React, { useState, useCallback, useEffect } from "react";
import { useServerApi } from "../hooks/userServerApi";
import {
  Group,
  Switch,
  Text,
  Title,
  Accordion,
  Badge,
  Container,
} from "@mantine/core";
import _ from "lodash";
import ReactJson from "react-json-view";

const FunctionList = ({ form, name, ...props }) => {
  const [functions, setFunctions] = useState([]);
  const [category, setCategory] = useState([]);

  const [api] = useServerApi();

  const fetchFunctions = async () => {
    let data = await api.User.getAllFunctions();
    setFunctions(data);
    // console.log(data);

    let cat = data.map((d) => d.name);
    setCategory(Array.from(new Set(cat)));
  };

  useEffect(() => {
    fetchFunctions();
  }, []);

  const FunctionCategory = () => {
    const getColor = (verb) => {
      switch (verb) {
        case "get":
          return "green";
        case "delete":
          return "red";
        case "put":
          return "orange";
        default:
          return "blue";
      }
    };
    return (
      <>
        {/* <Title order={6}> {_.capitalize(cat)}</Title>; */}
        <Accordion variant="separated" radius="xs" defaultValue="" ml={10}>
          {category &&
            category.map((cat) => {
              let funcs = functions.filter((func) => func.name === cat);
              return (
                <Accordion.Item value={cat}>
                  <Accordion.Control>
                    <Title order={6}>{_.capitalize(cat)}</Title>
                  </Accordion.Control>
                  <Accordion.Panel>
                    {funcs.map((func) => (
                      <Group position="apart" style={{ width: "100%" }}>
                        <div style={{ marginBottom: 20 }}>
                          <Group>
                            <Text fontWeight={800} size="sm">
                              {func.desc}
                            </Text>
                            <Badge size="xs" color={getColor(func.verb)}>
                              {func.verb}
                            </Badge>
                          </Group>
                          <Text size={"xs"} color="dimmed">
                            {`/api${func.endPoint}`}
                          </Text>
                        </div>
                        <Switch defaultChecked={true}></Switch>
                      </Group>
                    ))}
                  </Accordion.Panel>
                </Accordion.Item>
              );
            })}
        </Accordion>
      </>
    );
  };

  return <>{!_.isEmpty(functions) && <FunctionCategory {...props} />}</>;
};

export default FunctionList;
