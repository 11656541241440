import ReactJson from "react-json-view";
import _ from "lodash";
import React, { useState, useCallback, useEffect } from "react";
import { useServerApi } from "../hooks/userServerApi";
import { openWhatsapp, useCellRender } from "../hooks/useCellRender";
import {
  createStyles,
  Avatar,
  Text,
  Group,
  Title,
  Badge,
  UnstyledButton,
  Center,
} from "@mantine/core";
import {
  IconPhoneCall,
  IconAt,
  IconAlertTriangle,
  IconMailbox,
  IconMail,
} from "@tabler/icons";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  icon: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[3]
        : theme.colors.gray[5],
  },

  name: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
}));

export function UserCard({ value = {}, ...props }) {
  const { classes } = useStyles();
  const {
    image,
    name,
    workTitle,
    phone,
    email,
    cname,
    aliasName,
    staffId,
    status,
  } = value ?? {};

  const [cellRender] = useCellRender();
  return (
    <>
      {/* {JSON.stringify(value, null, 2)} */}

      <Group noWrap {...props}>
        <div>
          <Avatar
            src={`${process.env.REACT_APP_FILE_URL}/${image}`}
            size={80}
            radius="md"
          />
        </div>
        <div>
          <Group mb={5}>
            {/* {status && <Group>{cellRender.Status(status)}</Group>} */}

            <Text
              size="xs"
              sx={{ textTransform: "uppercase" }}
              color="dimmed"
              mb={0}
            >
              {workTitle}
            </Text>
          </Group>

          <Title order={6} mb={0}>
            {name}
            {aliasName ? `, ${aliasName}` : ""}
          </Title>

          <Group noWrap spacing={5} mt={3}>
            <IconMail stroke={1.5} size={16} className={classes.icon} />
            <Text size="xs" color="dimmed" lineClamp={1}>
              {email}
            </Text>
          </Group>

          <Group spacing={10} mt={0}>
            <Group spacing={5}>
              <IconPhoneCall stroke={1.5} size={16} className={classes.icon} />
              <UnstyledButton>
                <Text
                  size="xs"
                  color="dimmed"
                  lineClamp={1}
                  onClick={() => openWhatsapp(phone)}
                >
                  {phone}
                </Text>
              </UnstyledButton>
            </Group>
            <Group spacing={5}>
              <IconAt stroke={1.5} size={16} className={classes.icon} />

              <Text size="xs" color="dimmed" lineClamp={1}>
                {staffId ? ` ${staffId}` : ""}
              </Text>
            </Group>
          </Group>
        </div>
      </Group>
    </>
  );
}

const ConsultantList = ({
  form,
  name,
  consultantIds,
  showClientTransfer = false, //show only in client as main form

  ...props
}) => {
  const ids = consultantIds || _.get(form?.values, props.field);
  const [api] = useServerApi();
  const [consultants, setConsultants] = useState([]);
  const [clientTrans, setClientTrans] = useState(null);

  const apiEntity = "user";
  const NO_CONSULTANT_MSG = "No consultants selected.";
  const fetchData = async () => {
    try {
      if (!_.isArray(ids) || _.isEmpty(ids)) return setConsultants([]);
      let carr = [];

      //set is the client Transfer (only for client page)
      if (showClientTransfer) {
        setClientTrans(_.get(form.values, "isTransfer"));
      }

      for (const id of ids) {
        if (typeof id === "string") {
          // console.log("consultantList fetchData", id, typeof [], name);
          let c = await api.getById({ apiEntity, id });
          if (c) carr.push(c);
          if (c && c?.disableCreateOrder) {
            form.setFieldValue(
              "error",
              `Consultant is not allowed to create new order.`
            );
          }
        } else {
          if (_.isObject(id)) carr.push(id);
        }
      }
      setConsultants(carr);
    } catch (e) {
      setConsultants([]);
    }
  };

  useDeepCompareEffect(() => {
    fetchData();
  }, [ids]);

  const { t } = useTranslation();

  return (
    <>
      {/* <ReactJson
        src={form.values}
        name="formValue"
        collcapsed
        style={{ background: "white" }}
      /> */}

      {/* {_.isEmpty(consultants) && (
				<Group spacing={"xs"}>
					<IconAlertTriangle size={15} />
					<Text size={"xs"}>{NO_CONSULTANT_MSG}</Text>
				</Group>
			)} */}
      {!_.isEmpty(consultants) && (
        <Group spacing={50}>
          {consultants.map(
            (consultant, index) =>
              consultant && (
                <span key={index}>
                  <Group mb={"xs"} spacing={3}>
                    {index == 0 ? (
                      <Badge size="xs" color={"green"} radius={0}>
                        Primary
                      </Badge>
                    ) : (
                      <Badge size="xs" color={"red"} radius={0}>
                        Secondary
                      </Badge>
                    )}

                    {clientTrans !== null &&
                      (clientTrans ? (
                        <Badge size="xs" radius={0} color="red">
                          Transfer
                        </Badge>
                      ) : (
                        <Badge size="xs" radius={0}>
                          Direct
                        </Badge>
                      ))}
                  </Group>
                  <UserCard key={consultant._id} value={consultant} />
                  {consultant?.disableCreateOrder && (
                    <Group mt="xl">
                      <IconAlertTriangle color="red" />
                      <Text size="xs">
                        {t(
                          "Sorry, this consultant is not allowed to create new order"
                        )}
                      </Text>
                    </Group>
                  )}
                </span>
              )
          )}
        </Group>
      )}
    </>
  );
};

export default ConsultantList;
