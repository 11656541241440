import React, { useState, useEffect } from "react";
import {
  Tabs,
  ScrollArea,
  Grid,
  Button,
  Card,
  UnstyledButton,
  Avatar,
  Group,
  Text,
  Stack,
  SegmentedControl,
  TextInput,
} from "@mantine/core";
import { useServerApi } from "../hooks/userServerApi";
import { useCellRender } from "../hooks/useCellRender";
import { IconPhoto, IconBuildingSkyscraper, IconSearch } from "@tabler/icons";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import PageList from "./pageList";

import ProductTab from "./productTab";
import ClientPromotionTab from "./clientPromotionTab";
import ConsultantIncentiveTab from "./consultantIncentive";
import PortalLoginTab from "./portalLoginTab";
import PaymentInfoTab from "./paymentInfoTab";
import ProviderFormTab from "./providerFormTab";
import ProviderLinkageTab from "./providerLinkageTab";
import ProviderContactTab from "./providerContactTab";

const BusinessTab = ({ mt = "xl", height = 600 }) => {
  const [activeProvider, setActiveProvider] = useState(null);
  const [activeTab, setActiveTab] = useState("products");
  const [providers, setProviders] = useState([]);
  const [clientPromotions, setClientPromotions] = useState([]);
  const [consultantIncentives, setConsultantIncentives] = useState([]);
  const [api] = useServerApi();
  const [cellRender] = useCellRender();
  const { t } = useTranslation();
  const [search, setSearch] = useState("");

  const fetchProviders = async () => {
    const res = await api.search({
      apiEntity: "provider",
      pageSize: 1000,
      sort: { by: "code", order: "asc" },
      select:
        "shortName name cname image code _id paymentInfo formFiles formLinkage isActive",

      preQuery: {
        isActive: true,
      },
      searchQuery: _.isEmpty(search)
        ? { isActive: { $ne: false } }
        : {
            $and: [
              { isActive: { $ne: false } },
              {
                $or: [
                  { shortName: { $regex: search, $options: "i" } },
                  { name: { $regex: search, $options: "i" } },
                  { cname: { $regex: search, $options: "i" } },
                ],
              },
            ],
          },
    });
    if (!res.docs) return;
    setProviders(res.docs ?? []);
    setActiveProvider(res.docs[0] ?? null);
  };

  const fetchClientPromotions = async () => {
    const data = await api.ClientPromotion.getLatest();
    console.log(data);
    setClientPromotions(data ?? []);
  };

  const fetchConsultantIncentives = async () => {
    const data = await api.ConsultantIncentive.getLatest();
    setConsultantIncentives(data);
  };

  useEffect(() => {
    fetchProviders();
  }, [search]);

  useEffect(() => {
    fetchClientPromotions();
    fetchConsultantIncentives();
  }, []);

  const getProviderDisplayName = (provider) => {
    return provider.shortName ?? provider.code.replaceAll("-", " ");
  };

  return (
    <>
      <Grid mt={mt}>
        <Grid.Col span={3} h="100vh">
          <TextInput
            placeholder={t("Search providers...")}
            icon={<IconSearch size={14} />}
            w={300}
            mb="md"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <ScrollArea h="calc(100vh - 150px)">
            <Stack
              bg="var(--mantine-color-body)"
              align="stretch"
              justify="flex-start"
              gap="md"
            >
              {providers.map((provider) => (
                <Button
                  key={provider.id}
                  w={300}
                  h={40}
                  variant="default"
                  justify="left"
                  onClick={() => {
                    setActiveProvider(provider);
                  }}
                  style={{
                    border:
                      activeProvider._id === provider._id
                        ? "1px solid teal"
                        : " ",
                  }}
                >
                  <Group w={300}>
                    <Avatar
                      src={
                        provider.image
                          ? `${process.env.REACT_APP_FILE_URL}/${provider?.image}`
                          : null
                      }
                      radius={"xl"}
                      size={30}
                    >
                      <IconBuildingSkyscraper size="1.5rem" />
                    </Avatar>
                    <Text>{getProviderDisplayName(provider)}</Text>
                  </Group>
                </Button>
              ))}
            </Stack>
          </ScrollArea>
        </Grid.Col>
        <Grid.Col span={9}>
          <SegmentedControl
            value={activeTab}
            fullWidth
            withItemsBorders={false}
            onChange={setActiveTab}
            data={[
              { label: t("Products"), value: "products" },
              { label: t("Client Promotion"), value: "clientPromotion" },
              {
                label: t("Consultant Incentive"),
                value: "consultantIncentive",
              },
              { label: t("Contact List"), value: "contactList" },
              { label: t("Portal Login"), value: "portalLogin" },
              { label: t("Payment Information"), value: "paymentInfo" },
              { label: t("Forms"), value: "providerForm" },
              { label: t("Linkage"), value: "linkage" },
            ]}
          />

          {activeTab === "products" && <ProductTab provider={activeProvider} />}
          {activeTab === "clientPromotion" && (
            <ClientPromotionTab
              provider={activeProvider}
              clientPromotions={clientPromotions}
            />
          )}
          {activeTab === "consultantIncentive" && (
            <ConsultantIncentiveTab
              provider={activeProvider}
              consultantIncentives={consultantIncentives}
            />
          )}
          {activeTab === "contactList" && (
            <ProviderContactTab provider={activeProvider} />
          )}
          {activeTab === "portalLogin" && (
            <PortalLoginTab provider={activeProvider} />
          )}
          {activeTab === "paymentInfo" && (
            <PaymentInfoTab provider={activeProvider} />
          )}
          {activeTab === "providerForm" && (
            <ProviderFormTab provider={activeProvider} />
          )}
          {activeTab === "linkage" && (
            <ProviderLinkageTab provider={activeProvider} />
          )}
        </Grid.Col>
      </Grid>
    </>
  );
};

export default BusinessTab;
