import {
  Button,
  Text,
  TextInput,
  Select,
  Textarea,
  Switch,
} from "@mantine/core";
import React from "react";
import { useFormRender } from "../hooks/useFormRender";
import {
  PageList,
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "./pageList";
import { addFormSchema } from "../pages/operation/order";

import RemoteSelect2 from "./remoteSelect2";
import { DatePicker } from "@mantine/dates";

import { DATA_ORDER_TYPE, DATA_COUNTRY } from "../data/options";
import Chips from "./chips";
import ConsultantList from "./consultantList";
import OrderPaymentDetailForm from "./orderPaymentDetailForm";
import OrderProductVariation from "./orderProductVariation";
import ProductSelection from "./productSelection";
import { IconSettings } from "@tabler/icons";
import FormConfirmation from "./formConfirmation";
import OrderClientSelect from "../components/orderClientSelect";
import OrderInsuredList from "../components/orderInsuredList";

import _ from "lodash";
import moment from "moment";

const apiEntity = "order";
const initialValues = {
  name: "",
  payment: {},
  termination: {},
};

const OrderAdjustButton = ({ mainForm, label = "Adjust Order Detail" }) => {
  const formSchema = {
    name: "AddOrderSchema",
    title: "Adjust Order Details",
    description: "Please follow the step to adjust Order",

    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "1000px", //xs, sm, xl  ....100%
    },

    apiEntity,
    initialValues,

    validate: {
      client: (value) => (!value ? "Please select Client" : null),
      product: (value) => (!value ? "Please select Product" : null),
      consultants: (value) => {
        if (_.isEmpty(value)) return "Please select Consultant";

        return null;
      },
      // "payment.premium": (value) =>
      // 	value <= 0 || !value ? "Premium must > 0" : null,
      // "payment.currency": (value) =>
      // 	_.isEmpty(value) ? "Currency is empty" : null,
      // "payment.payMode": (value) =>
      // 	_.isEmpty(value) ? "Please select Payment Mode" : null,
      // "payment.paymentTerm": (value) =>
      // 	value <= 0 || !value ? "Please select Payment Term" : null,
      signedDate: (value) => {
        if (value && moment(value).isAfter(moment()))
          return "Please make sure Signed Date is before today.";
        return null;
      },

      error: (value) => {
        // console.log("error", value);
        return value;
      },
    },
    showSaveBar: false,

    layout: {
      containers: [
        {
          key: "t1",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,

          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Order Type",
            orientation: "vertical",
            style: { minHeight: "400px" },
          },
          steps: [
            { key: "t1-1", label: "Order Type", icon: IconSettings },
            { key: "t1-10", label: "Policy ", icon: IconSettings },

            { key: "t1-2", label: "Client", icon: IconSettings },
            { key: "t1-9", label: "Insured", icon: IconSettings },
            // { key: "t1-4", label: "Consultants", icon: IconSettings },

            { key: "t1-3", label: "Product", icon: IconSettings },
            {
              key: "t1-8",
              label: "Product Variation",
              icon: IconSettings,
            },
            { key: "t1-5", label: "Sign Detail", icon: IconSettings },
            {
              key: "t1-6",
              label: "Payment Details",
              icon: IconSettings,
            },
            { key: "t1-7", label: "Confirmation", icon: IconSettings },
          ],
        },
        {
          key: "section-type",
          parent: "t1-1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Order Types",
            description: "Pleaes select the order type",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },
        {
          key: "section-policy",
          parent: "t1-10",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Policy",
            description: "",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },
        {
          key: "section-client",
          parent: "t1-2",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Client",
            description: "Pleaes select the client",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },
        // {
        // 	key: "section-insured",
        // 	parent: "t1-9",
        // 	type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        // 	props: {
        // 		title: "Insured",
        // 		description: "Pleaes fillin the insured details",
        // 		withBorder: false,
        // 		mt: 0,
        // 		ml: 20,
        // 	},
        // },
        {
          key: "section-consultant",
          parent: "t1-4",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Consultants",
            description: "Please select the consultants",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },

        {
          key: "section-product",
          parent: "t1-3",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Product",
            description: "Please select the product",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },

        {
          key: "section-variation",
          parent: "t1-8",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Product Variation",
            description: "Please select the product variation",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },

        {
          key: "section-sign",
          parent: "t1-5",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Sign Person",
            description: "Please select consultant who sign the order",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },
        {
          key: "section-payment",
          parent: "t1-6",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Payment Details",
            description: "Please fillin the payment details",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },
        {
          key: "section-confirm",
          parent: "t1-7",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Confirmation",
            description: "Please confirm the creation of order",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },

        {
          key: "payment-g",
          parent: "section-payment-details",
          type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
          cols: [
            {
              key: "payment-g1",
              props: {
                span: 6,
              },
            },
            {
              key: "payment-g2",
              props: {
                span: 6,
              },
            },
          ],
        },
      ],
      fields: [
        {
          name: "type",
          component: Chips,
          parent: "section-type",
          props: {
            required: true,
            // label: "Order Types",
            labelOrder: 5,
            // description: "Please Select the type of order.",
            items: DATA_ORDER_TYPE,
            defaultValue: "NEW",
            multiple: false,
          },
        },
        {
          name: "policyNumber",
          component: TextInput,
          parent: "section-policy",
          props: {
            required: true,
            label: "Policy Number",
          },
        },

        {
          name: "client",
          component: RemoteSelect2,
          parent: "section-client",
          props: {
            apiEntity: "client",
            required: true,
            placeholder: "Search Client By Name / HKID/ BR ",
            valueField: "_id",
            labelField: "name",
            allowClear: true,
            labelRender: (data) =>
              `${data.name} ${data.cname} [ ${data.id ?? " -- "} ]`,
            //   labelRender: (data) => `${data.cname}`,
            multiple: false,
            // preQuery: { $and: [ {isConsultant : true}, {isActive: true} ] },
            // preQuery:  {isConsultant : true} ,
            searchFields: ["name", "id", "cname"],
            clearable: true,
            onDataChange: (value, form) => {
              // console.log("Client Change", value);
              //Set Consultant
              if (!value || _.isEmpty(value.consultants))
                form.setFieldValue("consultants", []);
              else {
                form.setFieldValue(
                  "consultants",
                  value.consultants.map((c) => c._id)
                );
              }
              //Set Clientsnap shot for search
              form.setFieldValue("clientSnapShot", value ?? {});
            },
            sort: {
              by: "name",
              order: "asc",
            },
          },
        },
        {
          name: "client2",
          component: OrderClientSelect,
          parent: "section-client",
          props: {},
        },

        {
          name: "product",
          component: ProductSelection,
          parent: "section-product",
        },

        {
          name: "consultants",
          component: RemoteSelect2,
          parent: "section-consultant",
          props: {
            apiEntity: "user",
            required: true,
            // label: "Consultant",
            placeholder: "Please select consultant(s)",
            valueField: "_id",
            labelField: "name",
            labelRender: (data) =>
              `${data.name}, ${data.aliasName} (${data.staffId ?? " -- "})`,
            multiple: true,
            preQuery: {
              $and: [{ isConsultant: true }, { status: "ACTIVE" }],
            },
            searchFields: [
              "aliasName",
              "name",
              "staffId",
              "cname",
              "email",
              "phone",
            ],
            sort: {
              by: "name",
              order: "asc",
            },
          },
        },
        {
          name: "consultantList",
          component: ConsultantList,
          parent: "section-consultant",
          props: {
            field: "consultants",
          },
        },

        {
          name: "signedDate",
          component: DatePicker,
          parent: "section-sign",
          type: "datePicker",
          props: {
            required: true,
            label: "Sign Date",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },

        {
          name: "signedPlace",
          component: Select,
          parent: "section-sign",
          props: {
            required: true,
            label: "Sign Place",
            placeholder: "",
            data: DATA_COUNTRY,
            defaultValue: "Hong Kong",
            searchable: true,
          },
        },
        {
          name: "signedBy",
          component: RemoteSelect2,
          parent: "section-sign",
          props: {
            apiEntity: "user",
            required: true,
            label: "Sign By",
            placeholder: "Please select consultant who sign the order",
            valueField: "_id",
            labelField: "name",
            labelRender: (data) =>
              `${data.name}, ${data.aliasName} (${data.staffId ?? " -- "})`,
            multiple: false,
            preQuery: {
              $and: [{ isConsultant: true }, { status: "ACTIVE" }],
            },
            searchFields: ["name", "staffId", "cname", "email", "aliasName"],
            sort: {
              by: "name",
              order: "asc",
            },
          },
        },
        {
          name: "signedBy2",
          component: RemoteSelect2,
          parent: "section-sign",
          props: {
            apiEntity: "user",
            required: false,
            label: "Sign By (2nd)",
            placeholder: "Please select consultant who sign the order",
            valueField: "_id",
            labelField: "name",
            labelRender: (data) =>
              `${data.name}, ${data.aliasName} (${data.staffId ?? " -- "})`,
            multiple: false,
            preQuery: {
              $and: [{ isConsultant: true }, { status: "ACTIVE" }],
            },
            searchFields: ["name", "staffId", "cname", "email", "aliasName"],
            sort: {
              by: "name",
              order: "asc",
            },
          },
        },

        {
          name: "consultantList",
          component: ConsultantList,
          parent: "section-sign",
          props: {
            field: "signedBy",
          },
        },
        {
          name: "hasNoPayment",
          component: Switch,
          parent: "section-payment",
          props: {
            label: "This order has no confirmed payment details (e.g. MPF)",
            mb: "xl",
            size: "xs",
          },
        },
        {
          name: "payment",
          component: OrderPaymentDetailForm,
          parent: "section-payment",
          props: {},
        },

        {
          name: "variation",
          component: OrderProductVariation,
          parent: "section-variation",
          props: {},
        },

        {
          name: "confirm",
          component: FormConfirmation,
          parent: "section-confirm",
          props: {},
        },
        {
          name: "insured",
          component: OrderInsuredList,
          parent: "t1-9",
          props: {},
        },
      ],
    },
  };

  const onSubmit = async ({ values, formMode }) => {
    console.log(
      "%corderEditButton.js line:9 values",
      "color: #007acc;",
      values
    );
    // mainForm.setValues(values);
    await mainForm.saveValues(values);

    mainForm.reload();
    formAction.close();
    //ToDo: recreate order commission receveiable
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    null,
    onSubmit
  );

  const handleActionClick = () => {
    const id = mainForm?.values._id;
    if (!id) return;
    formAction.open({ mode: "edit", id, data: mainForm.values });
  };

  return (
    <>
      {/* {JSON.stringify(mainForm.values.termination)} */}
      <Button fullWidth onClick={handleActionClick}>
        {label}
      </Button>
      {renderForm()}
    </>
  );
};

export default OrderAdjustButton;
