import { Text } from "@mantine/core";
import React, { useState, useEffect } from "react";
import { MantineProvider, ColorSchemeProvider } from "@mantine/core";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Router,
  useNavigate,
} from "react-router-dom";
import { SpotlightProvider } from "@mantine/spotlight";
import { Group, Button, Badge } from "@mantine/core";

import Layout from "./pages/layout";
import Dashboard from "./pages/dashboard";
import _ from "lodash";

import Consultant from "./pages/operation/consultant";
import Client from "./pages/operation/client";
import Order from "./pages/operation/order";
import OrderService from "./pages/operation/orderService";
import OrderRenewal from "./pages/consultant/orderRenewal";
import OrderPending from "./pages/consultant/orderPending";
import Product from "./pages/operation/product";
import Provider from "./pages/operation/provider";
import ProductCategory from "./pages/operation/productCategory";
import ProductType from "./pages/operation/productType";
import User from "./pages/operation/user";
import UserGroup from "./pages/operation/userGroup";
import CommissionOverridingPlan from "./pages/management/commissionOverridingPlan";
import AllowancePlan from "./pages/management/allowancePlan";
import AllowanceList from "./pages/management/allowanceList";
import TicketTypes from "./pages/management/ticketTypes";
import OrderServiceType from "./pages/management/orderServiceType";
import ExchangeRate from "./pages/management/exchangeRate";
import Ticket from "./pages/ticket";
import MyTicket from "./pages/myTicket";

import PayrollPayment from "./pages/consultant/payrollPayment";
import WalletPage from "./pages/consultant/walletPage";
import ConsultantProduct from "./pages/consultant/product";

import CommissionStatement from "./pages/account/commissionStatement";
import Payroll from "./pages/account/payroll";
import ProfolioImport from "./pages/management/profolioImport";
import Login from "./pages/login";
import ForgetPassword from "./pages/forgetPassword";
import MyTeam from "./pages/consultant/myTeam";
import Home from "./pages/home";
import CalendarEvent from "./pages/management/calendarEvent";
import ConsultantIncentive from "./pages/management/consultantIncentive";
import ClientPromotion from "./pages/management/clientPromotion";
import TopRanking from "./pages/management/topRanking";
import Incentive from "./pages/account/incentive";
import BankAccount from "./pages/bankAccount";

import ResetPassword from "./pages/resetPassword";
import Chat from "./pages/chat";
import { NotificationsProvider } from "@mantine/notifications";
import { AuthProvider, RequireAuth, useSignOut } from "react-auth-kit";
import { theme } from "./theme";
import socketIO from "socket.io-client";
import { createContext, useContext } from "react";

import MSetting from "./pages/management/setting";
import OSetting from "./pages/operation/setting";
import Organization from "./pages/management/organization";
import DebitNote from "./pages/gi/debitNote";
import CreditNote from "./pages/gi/creditNote";
import Quotation from "./pages/gi/quotation";
import Renewal from "./pages/gi/renewal";
import GIMatchPayment from "./pages/gi/matchPayment";
import GISettlement from "./pages/gi/settlement";
import BankTransaction from "./pages/account/bankTransaction";
import BankAccountDetail from "./pages/bankAccountDetail";

import DataFeed from "./pages/dataFeed";
import GIReport from "./pages/giReport";

import QuoRequest from "./pages/gi/quoRequest";
import ConsultantQuoRequest from "./pages/gi/consultantQuoRequest";

import { IconSearch } from "@tabler/icons";
import Cpd from "./pages/operation/cpd";
import { useLocalStorage } from "@mantine/hooks";

import MyCPD from "./pages/consultant/myCPD";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";

import { useSpotLight } from "./hooks/useSpotLight";
import BidList from "./pages/management/bidList";
import Report from "./pages/report";
import SearchPage from "./pages/searchPage";
// import { LanguageProvider } from "./context/language";
import { LanguageProvider } from "./context/language";

const socket = socketIO.connect(`${process.env.REACT_APP_SOCKET_URL}`);
export const SocketContext = createContext(null);

export const authName = "_mbid";
const languageFromLocalStorage = localStorage.getItem("language") || "en";

const defaultColorScheme = localStorage.getItem(authName + "color-scheme");

function App() {
  const [colorScheme, setColorScheme] = useState(defaultColorScheme ?? "dark");

  // const [colorScheme, setColorScheme] = useState("dark");
  const toggleColorScheme = (value) => {
    // console.log("toogleColorScheme", value)
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
    localStorage.setItem(authName + "color-scheme", value);
  };
  // disableReactDevTools();

  const [onSpotLightQueryChange, spotLightActions] = useSpotLight();

  useEffect(() => {
    document.title = `${process.env?.REACT_APP_WEB_TITLE}` ?? "";
    window.addEventListener("error", (e) => {
      console.log(e);
      if (e.message === "ResizeObserver loop limit exceeded") {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
      //   console.error(e.message);
      //   // prevent React's listener from firing
      e.stopImmediatePropagation("ResizeObserver loop limit exceeded");
      //   // prevent the browser's console error message
      e.preventDefault();
    });
  }, []);

  return (
    <SocketContext.Provider value={socket}>
      <LanguageProvider initialLanguage={languageFromLocalStorage}>
        <AuthProvider authType={"localstorage"} authName={authName}>
          <ColorSchemeProvider
            colorScheme={colorScheme}
            toggleColorScheme={toggleColorScheme}
          >
            <MantineProvider
              inherit
              theme={{
                colorScheme,
                ...theme,
              }}
              withGlobalStyles
              withNormalizeCSS
            >
              <NotificationsProvider zIndex={2077}>
                <SpotlightProvider
                  actions={spotLightActions}
                  searchIcon={<IconSearch size="1.2rem" />}
                  searchPlaceholder="Search..."
                  // shortcut="mod + shift + 1"
                  shortcut="mod + 1"
                  nothingFoundMessage="No Results"
                  onQueryChange={onSpotLightQueryChange}
                  limit={30}
                >
                  <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route
                      path="/forgetPassword"
                      element={<ForgetPassword />}
                    />

                    <Route
                      path="/"
                      element={
                        <RequireAuth loginPath="/login">
                          <Layout />
                        </RequireAuth>
                      }
                    >
                      <Route index path="/" element={<Home />} />

                      <Route index path="/home" element={<Home />} />

                      <Route path="/dashboard" element={<Dashboard />} />

                      <Route
                        path="operation/client"
                        element={<Client role="OPERATION" />}
                      />

                      <Route
                        path="gi/client"
                        element={<Client role="OPERATION" />}
                      />

                      <Route
                        path="management/client"
                        element={<Client role="OPERATION" />}
                      />

                      <Route
                        path="account/client"
                        element={<Client role="OPERATION" />}
                      />

                      <Route path="/m/setting" element={<MSetting />} />
                      <Route path="/o/setting" element={<OSetting />} />

                      <Route
                        path="/resetPassword"
                        element={<ResetPassword />}
                      />
                      <Route
                        path="operation/order"
                        element={<Order role="OPERATION" />}
                      />

                      <Route
                        path="gi/order"
                        element={<Order role="OPERATION" />}
                      />

                      <Route
                        path="management/order"
                        element={<Order role="MANAGEMENT" />}
                      />

                      <Route
                        path="account/order"
                        element={<Order role="MANAGEMENT" />}
                      />

                      <Route
                        path="operation/orderService"
                        element={<OrderService role="OPERATION" />}
                      />
                      <Route
                        path="/management/orderService"
                        element={<OrderService role="OPERATION" />}
                      />

                      <Route path="management/product" element={<Product />} />
                      <Route path="gi/product" element={<Product />} />
                      <Route path="operation/product" element={<Product />} />
                      <Route path="account/product" element={<Product />} />
                      <Route path="operation/provider" element={<Provider />} />
                      <Route path="gi/provider" element={<Provider />} />

                      <Route
                        path="management/provider"
                        element={<Provider />}
                      />
                      <Route
                        path="operation/productCategory"
                        element={<ProductCategory />}
                      />
                      <Route
                        path="gi/productCategory"
                        element={<ProductCategory />}
                      />
                      <Route
                        path="management/productCategory"
                        element={<ProductCategory />}
                      />
                      <Route
                        path="operation/productType"
                        element={<ProductType />}
                      />
                      <Route path="gi/productType" element={<ProductType />} />

                      <Route path="operation/cpd" element={<Cpd />} />
                      <Route path="management/cpd" element={<Cpd />} />

                      <Route path="consultant/myTeam" element={<MyTeam />} />
                      <Route
                        path="operation/user"
                        element={<User role="OPERATION" />}
                      />
                      <Route
                        path="gi/user"
                        element={<User role="OPERATION" />}
                      />
                      <Route
                        path="management/user"
                        element={<User role="MANAGEMENT" />}
                      />

                      <Route
                        path="account/user"
                        element={<User role="MANAGEMENT" />}
                      />

                      <Route path="hr/user" element={<User role="HR" />} />

                      <Route
                        path="operation/userGroup"
                        element={<UserGroup />}
                      />

                      <Route path="account/userGroup" element={<UserGroup />} />

                      <Route
                        path="management/calendarEvent"
                        element={<CalendarEvent />}
                      />
                      <Route
                        path="management/commissionOverridingPlan"
                        element={<CommissionOverridingPlan />}
                      />

                      <Route
                        path="management/ticketTypes"
                        element={<TicketTypes />}
                      />

                      <Route
                        path="management/orderServiceType"
                        element={<OrderServiceType />}
                      />

                      <Route
                        path="m/bankAccountDetail"
                        element={<BankAccountDetail />}
                      />

                      <Route
                        path="account/bankAccountDetail"
                        element={<BankAccountDetail />}
                      />

                      <Route
                        path="management/allowance"
                        element={<AllowanceList />}
                      />
                      <Route
                        path="management/allowancePlan"
                        element={<AllowancePlan />}
                      />

                      <Route
                        path="account/allowance"
                        element={<AllowanceList />}
                      />
                      <Route
                        path="account/allowancePlan"
                        element={<AllowancePlan />}
                      />

                      <Route
                        path="management/exchangeRate"
                        element={<ExchangeRate />}
                      />

                      <Route
                        path="management/profolioImport"
                        element={<ProfolioImport />}
                      />
                      <Route
                        path="account/commissionStatement"
                        element={<CommissionStatement />}
                      />
                      <Route
                        path="management/commissionStatement"
                        element={<CommissionStatement />}
                      />

                      <Route path="account/payroll" element={<Payroll />} />
                      <Route path="management/payroll" element={<Payroll />} />
                      <Route path="account/incentive" element={<Incentive />} />
                      <Route
                        path="management/incentive"
                        element={<Incentive />}
                      />

                      <Route
                        path="account/bankTransaction"
                        element={<BankTransaction />}
                      />
                      <Route
                        path="management/bankTransaction"
                        element={<BankTransaction />}
                      />
                      <Route
                        path="consultant/client"
                        element={<Client role="CONSULTANT" />}
                      />

                      <Route
                        path="consultant/order"
                        element={<Order role="CONSULTANT" />}
                      />

                      <Route
                        path="consultant/orderPending"
                        element={<OrderPending />}
                      />

                      <Route
                        path="consultant/orderRenewal"
                        element={<OrderRenewal role="CONSULTANT" />}
                      />
                      <Route
                        path="consultant/payrollPayment"
                        element={<PayrollPayment />}
                      />
                      <Route
                        path="gi/quoRequest"
                        element={<QuoRequest role="OPERATION" />}
                      />
                      <Route
                        path="gi/order"
                        element={<Order role="GI-OPERATION" />}
                      />
                      <Route
                        path="management/consultantIncentive"
                        element={<ConsultantIncentive />}
                      />

                      <Route
                        path="management/clientPromotion"
                        element={<ClientPromotion />}
                      />

                      <Route
                        path="consultant/wallet"
                        element={<WalletPage />}
                      />

                      <Route
                        path="consultant/product"
                        element={<ConsultantProduct />}
                      />
                      <Route
                        path="management/topRanking"
                        element={<TopRanking />}
                      />
                      <Route
                        path="management/organization"
                        element={<Organization />}
                      />
                      <Route
                        path="consultant/quoRequest"
                        element={<ConsultantQuoRequest />}
                      />

                      <Route path="consultant/myCPD" element={<MyCPD />} />
                      <Route path="management/myCPD" element={<MyCPD />} />

                      <Route path="management/bids" element={<BidList />} />
                      <Route path="account/bids" element={<BidList />} />

                      <Route path="gi/quotation" element={<Quotation />} />
                      <Route path="m/gi/quotation" element={<Quotation />} />
                      <Route path="gi/renewal" element={<Renewal />} />
                      <Route path="m/gi/renewal" element={<Renewal />} />

                      <Route path="gi/debitNote" element={<DebitNote />} />
                      <Route path="m/gi/debitNote" element={<DebitNote />} />

                      <Route
                        path="gi/matchPayment"
                        element={<GIMatchPayment />}
                      />
                      <Route
                        path="m/gi/matchPayment"
                        element={<GIMatchPayment />}
                      />
                      <Route path="gi/settlement" element={<GISettlement />} />
                      <Route
                        path="m/gi/settlement"
                        element={<GISettlement />}
                      />
                      <Route
                        path="management/bankAccount"
                        element={<BankAccount />}
                      />
                      <Route
                        path="account/bankAccount"
                        element={<BankAccount />}
                      />
                      <Route path="gi/creditNote" element={<CreditNote />} />
                      <Route path="m/gi/creditNote" element={<CreditNote />} />

                      {/* <Route path="/chat" element={<Chat />} /> */}
                      <Route path="/ticket" element={<Ticket />} />
                      <Route path="/chat" element={<MyTicket />} />

                      <Route path="/dataFeed" element={<DataFeed />} />
                      <Route
                        path="/management/dataFeed"
                        element={<DataFeed />}
                      />
                      <Route path="/report" element={<Report />} />
                      <Route path="/gi/report" element={<GIReport />} />
                      <Route path="/account/report" element={<Report />} />
                      <Route path="/management/report" element={<Report />} />

                      <Route index path="/search" element={<SearchPage />} />

                      {/* <Route path="/setting" element={<Setting />} /> */}
                    </Route>
                  </Routes>
                </SpotlightProvider>
              </NotificationsProvider>
            </MantineProvider>
          </ColorSchemeProvider>
        </AuthProvider>
      </LanguageProvider>
    </SocketContext.Provider>
  );
}

export default App;
