import React from "react";
import _ from "lodash";
import ReactJson from "react-json-view";
import { Text } from "@mantine/core";
import { FormSectionCard } from "../layout/page";
import { useTranslation } from "react-i18next";
const PaymentInfoTab = ({ provider }) => {
  const { t } = useTranslation();
  return (
    <>
      {/* <ReactJson src={provider} style={{ background: "white" }} collapsed /> */}

      <FormSectionCard mt="xl" ml="xl">
        {!provider || _.isEmpty(provider.paymentInfo) ? (
          <Text size="xs">{t("No payment information")}</Text>
        ) : (
          <Text dangerouslySetInnerHTML={{ __html: provider.paymentInfo }} />
        )}
      </FormSectionCard>
    </>
  );
};

export default PaymentInfoTab;
