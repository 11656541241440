import {
  Text,
  TextInput,
  Select,
  Chip,
  Checkbox,
  Badge,
  NumberInput,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import React, { useState, useEffect } from "react";
import {
  PageList,
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import { IconTrash, IconPencil, IconSettings, IconEye } from "@tabler/icons";
import ActionCellRender from "../../components/actionCellRender";
import Chips from "../../components/chips";
import {
  DATA_COUNTRY,
  DATA_EDUCATION_LEVEL,
  DATA_LANGUAGE,
  DATA_NATIONALITY,
  DATA_SALUATATION,
  DATA_ID_TYPE,
  DATA_CLIENT_TYPES,
  DATA_GENDERS,
  DATA_MARIAGE_STATUS,
} from "../../data/options";
import { useTranslation } from "react-i18next";
import RemoteSelect2 from "../../components/remoteSelect2";
import ConsultantList from "../../components/consultantList";
import ClientBasicInfo from "../../components/clientBasicInfo";
import ClientActionList from "../../components/clientActionList";
import ClientOrderHistory from "../../components/clientOrderHistory";
import ClientRiskStats from "../../components/clientRiskStats";
import ClientAMLList from "../../components/clientAMLList";
import ClientRPQList from "../../components/clientRPQList";

import ClientPageStats from "../../components/clientPageStats";
import AddressList from "../../components/addressList";

import ActivityLog from "../../components/activityLog.js";
import FollowupActionTable from "../../components/followupActionTable.js";
import UniqueTextInput from "../../components/uniqueTextInput";
import FormConfirmation from "../../components/formConfirmation";
import { useCellRender } from "../../hooks/useCellRender";

import FileList from "../../components/fileList";
import { useAuthHeader, useAuthUser } from "react-auth-kit";

import { authName } from "../../App";
import { useNavigate } from "react-router-dom";
import RemarkList from "../../components/remarkList";
import ClientMajorInvestmentPortfolio from "../../components/clientMajorInvestmentPortfolio";
import ClientIncomeSource from "../../components/clientIncomeSource";
import { useAccessRight } from "../../hooks/useAccessRight";
import _ from "lodash";

const apiEntity = "client";
const initialValues = {
  name: "",
  cname: "",
  type: "INDIVIDUAL",
  address: {
    country: "Hong Kong",
  },
  consultants: [],
  language: "CANTONESE",
  idIsUnqiue: false,
  pep: {
    isPep: false,
    remark: "",
  },
  gender: "Male",
  mariage: "Single",
  educationLevel: "",
  nationality: "Chinese",
  residentialCountry: "Hong Kong",
  placeOfBirth: "Hong Kong",
  status: process.env.REACT_APP_CLIENT_INIT_STATUS ?? "ACTIVE",
};

export default function Client({ role = "OPERATION" }) {
  const [cellRender] = useCellRender();
  const [preQuery, setPreQuery] = useState(null);
  const auth = useAuthUser();
  const navigate = useNavigate();
  const [formSchema, setFormSchema] = useState({});
  const { t } = useTranslation();
  const [getAccessRight, accessRight] = useAccessRight();

  const userRoleName = auth().userRole?.name?.toUpperCase() ?? "";
  const currentURL = window.location.pathname;

  const isConsultant = currentURL.includes("consultant");

  const editFormSchema = {
    title: "Client",
    titleField: "name",

    display: {
      mode: ENUM_FORM_DISPLAY.LINK,
      size: "xl", //xs, sm, xl  ....100%
      // position:"right"
    },
    apiEntity,
    initialValues,

    validate: {
      code: (value) =>
        value?.length < 2 ? "Code must have at least 2 letters" : null,
      name: (value) =>
        value?.length < 4 ? "Name must have at least 4 letters" : null,
      id: (value, formValues) => {
        const regrex = /^[A-Z]{1,2}[0-9]{6}\([0-9A]\)$/;
        if (formValues.idType === "HKID") {
          if (!regrex.test(value)) return t("It is not a valid HKID");
        }
        return null;
      },
    },

    layout: {
      containers: [
        {
          key: "main-g",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
          cols: [
            {
              key: "main-g1",
              props: {
                span: 3,
              },
            },
            {
              key: "main-g2",
              props: {
                span: 9,
              },
            },
          ],
        },

        {
          key: "section-consultant-info",
          parent: "main-g1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Consultant",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: "0px",
            mt: 0,
            mb: 20,
          },
        },

        {
          key: "section-action-list",
          parent: "main-g1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            // title: "Action List",
            // titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: "0px",
            mt: 0,
            mb: 20,
          },
        },

        {
          key: "main-tab",
          parent: "main-g2",
          type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Overview",
          },
          tab: [
            {
              key: "tab-overview",
              label: "Overview",
              icon: IconSettings,
            },
            // {
            // 	key: "tab-orders",
            // 	label: "Orders",
            // 	icon: IconSettings,
            // },
            {
              key: "tab-risk",
              label: "Risk Control",
              icon: IconSettings,
            },
            {
              key: "tab-files",
              label: "Files",
              icon: IconSettings,
            },
            {
              key: "tab-log",
              label: "Logs",
              icon: IconSettings,
            },
          ],
        },
        {
          key: "section-basic-info",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Basic Info",
            // titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            mt: 0,
            mb: 20,
          },
        },
        {
          key: "section-order-history",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Order History",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            mt: 20,
            mb: 20,
          },
        },
        {
          key: "section-remarks",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Client Remarks",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: 0,
            mt: 20,
            mb: 20,
          },
        },
        {
          key: "section-addressList",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Address",
            // titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            mt: 0,
            mb: 20,
          },
        },

        {
          key: "section-followup",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Followup Tickets",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,

            mt: 20,
            mb: 20,
          },
        },

        {
          key: "section-client-log",
          parent: "tab-log",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Client Log",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: 350,
            mt: 20,
            mb: 20,
          },
        },

        {
          key: "section-files",
          parent: "tab-files",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Files",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: "0px",
            mt: 20,
            mb: 20,
          },
        },

        {
          key: "section-risk-stats",
          parent: "tab-risk",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Risk Summary",
            minHeight: "0px",
          },
        },

        {
          key: "section-risk-kyc",
          parent: "tab-risk",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Financial Status",
          },
        },

        {
          key: "section-risk-pep",
          parent: "tab-risk",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "PEP",
          },
        },

        {
          key: "section-risk-aml",
          parent: "tab-risk",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "AML",
          },
        },
        {
          key: "section-risk-rpq",
          parent: "tab-risk",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "RPQ",
          },
        },
      ],

      fields: [
        {
          name: "display-basic-info",
          parent: "section-basic-info",
          component: ClientBasicInfo,
          props: {},
        },
        {
          name: "orders",
          component: ClientOrderHistory,
          parent: "section-order-history",
          props: {},
        },
        {
          name: "addresses",
          parent: "section-addressList",
          component: AddressList,
          props: {
            readOnly: isConsultant,
          },
        },
        {
          name: "consultantList",
          component: ConsultantList,
          parent: "section-consultant-info",
          props: {
            field: "consultants",
            mt: 20,
            showClientTransfer: true,
          },
        },

        {
          name: "clientActionList",
          component: ClientActionList,
          parent: "section-action-list",
          props: {},
        },

        {
          name: "files",
          component: FileList,
          parent: "section-files",
          props: {
            disabled: isConsultant,
          },
        },

        {
          name: "riskStats",
          component: ClientRiskStats,
          parent: "section-risk-stats",
          props: {},
        },

        {
          name: "pep.isPep",
          component: Checkbox,
          parent: "section-risk-pep",
          props: {
            label: "is Political Exposure Person? (PEP)",
            type: "checkbox",
            disabled: isConsultant,
          },
        },
        {
          name: "pep.remark",
          component: TextInput,
          parent: "section-risk-pep",
          props: {
            label: "Remark",
            disabled: isConsultant,
          },
        },

        {
          name: "annualIncome",
          component: NumberInput,
          parent: "section-risk-kyc",
          props: {
            label: "Annual Income (USD)",
            disabled: isConsultant,
          },
        },
        {
          name: "currentAssets",
          component: NumberInput,
          parent: "section-risk-kyc",
          props: {
            label: "Current Assets (USD)",
            disabled: isConsultant,
          },
        },
        {
          name: "majorInvestmentPortfolio",
          component: ClientMajorInvestmentPortfolio,
          parent: "section-risk-kyc",
          props: {
            label: "Major Investment Portfolio",
            disabled: isConsultant,
          },
        },
        {
          name: "incomeSource",
          component: ClientIncomeSource,
          parent: "section-risk-kyc",
          props: {
            label: "Client Income Source",
            disabled: isConsultant,
          },
        },
        {
          name: "riskAML",
          component: ClientAMLList,
          parent: "section-risk-aml",
          props: {
            disabled: isConsultant,
          },
        },

        {
          name: "riskRPQ",
          component: ClientRPQList,
          parent: "section-risk-rpq",
          props: {
            disabled: isConsultant,
          },
        },
        {
          name: "clientRemarks",
          component: RemarkList,
          parent: "section-remarks",
          props: {
            scrollHeight: 100,
            disabled: isConsultant,
          },
        },
        {
          name: "log",
          component: ActivityLog,
          parent: "section-client-log",
          props: {},
        },

        {
          name: "followup",
          component: FollowupActionTable,
          parent: "section-followup",
          props: {},
        },
        {
          name: "confirm",
          component: FormConfirmation,
          parent: "section-confirm",
          props: {},
        },
      ],
    },
  };

  const consultantEditFormSchema = _.cloneDeep(editFormSchema);
  consultantEditFormSchema.layout.containers =
    consultantEditFormSchema.layout.containers.filter(
      (c) => c.key !== "section-action-list"
    );

  const tableSchema = {
    showActionColumn: true,
    preQuery,
    columns: [
      {
        field: "name",
        sortable: true,
        headerName: "Client",
        cellRender: (col, data) => cellRender.ClientInfo(data),
      },

      {
        field: "id",
        sortable: true,
        headerName: "ID No.",
      },
      {
        field: "isTransfer",
        sortable: true,
        headerName: "Source",

        cellRender: (col, data) =>
          data && data.isTransfer ? (
            <Badge size="xs" color={"red"} radius={0}>
              Transfer
            </Badge>
          ) : (
            <Badge size="xs" color={"teal"} radius={0}>
              Direct
            </Badge>
          ),
      },
      {
        field: "consultants",
        sortable: true,
        headerName: "Consultants",
        cellRender: (col, data) => cellRender.Users(data.consultants),
      },

      // {
      // 	field: "order",
      // 	headerName: "Orders",
      // 	sortable: true,
      // 	cellRender: (col, data) => data.orders.length,
      // },
      // { field: "phone", sortable: true },

      {
        field: "status",
        sortable: true,
        cellRender: (col, data) => cellRender.Status(data.status),
        filterable: true,
        filter: {
          apiEntity: "client",
          field: "status",
          label: "Client Status",
          width: 250,
          height: 300,
        },
      },
      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
        ],
        actionMenu:
          accessRight === 15
            ? [
                {
                  name: "delete",
                  label: "Delete",
                  icon: IconTrash,
                  props: {
                    color: "red",
                  },
                },
              ]
            : null,
      },
    ],
    searchableFields: [
      "code",
      "name",
      "cname",
      "email",
      "phone",
      "id",
      "status",
      // "consultants.name",
      // "consultants.cname",
      "consultants.staffId",
      "consultants.aliasName",
    ],
  };

  const addFormSchema = {
    title: "New Client",
    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "1000px", //xs, sm, xl  ....100%
      // position:"right"
    },

    showSaveBar: false,
    apiEntity,
    initialValues,

    validate: {
      name: (value) =>
        value?.length < 4 ? "Name must have at least 4 letters" : null,
      id: (value, formValues) => {
        const regrex = /^[A-Z]{1,2}[0-9]{6}\([0-9A]\)$/;
        if (formValues.idType === "HKID") {
          if (!regrex.test(value)) return t("It is not a valid HKID");
        }
        return null;
      },
      idIsUnqiue: (value) => value,
    },

    layout: {
      containers: [
        {
          key: "t1",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,
          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Type",
            orientation: "vertical",
            style: { minHeight: "400px" },
          },
          steps: [
            { key: "t1-1", label: "Type", icon: IconSettings },
            { key: "t1-2", label: "Client ID", icon: IconSettings },
            { key: "t1-3", label: "Basic Info", icon: IconSettings },
            { key: "t1-6", label: "Address", icon: IconSettings },
            { key: "t1-4", label: "Consultant", icon: IconSettings },
            { key: "t1-5", label: "Confirm", icon: IconSettings },
          ],
        },
        {
          key: "section-type",
          parent: "t1-1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Client Types",
            description: "Please choose type for client",
            withBorder: false,
            ml: 10,
            mt: 0,
          },
        },
        {
          key: "section-ID",
          parent: "t1-2",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Client Identity",
            titleOrder: 5,
            description: "Please enter the primary ID of client",
            withBorder: false,
            ml: 10,
            mt: 0,
            mb: 10,
          },
        },
        {
          key: "section-basicInfo",
          parent: "t1-3",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          visibleOnly: (formValues) => {
            return formValues.type != "CORPORATE";
          },
          props: {
            title: "Basic Info",
            titleOrder: 5,
            description: "Please enter basic info of client",
            withBorder: false,
            ml: 10,
            mt: 0,
            mb: 10,
          },
        },
        {
          key: "section-basicInfo-corporate",
          parent: "t1-3",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          visibleOnly: (formValues) => {
            return formValues.type == "CORPORATE";
          },
          props: {
            title: "Basic Info",
            titleOrder: 5,
            description: "Please enter basic info of client",
            withBorder: false,
            ml: 10,
            mt: 0,
            mb: 10,
          },
        },

        {
          key: "basicInfo-g",
          parent: "section-basicInfo",
          type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
          cols: [
            {
              key: "basicInfo-g1",
              props: {
                span: 6,
              },
            },
            {
              key: "basicInfo-g2",
              props: {
                span: 6,
              },
            },
          ],
        },

        {
          key: "section-consultant",
          parent: "t1-4",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Consultant",
            titleOrder: 5,
            description: "Please assign the corresponding consultant",
            withBorder: false,
            ml: 10,
            mt: 0,
            mb: 10,
          },
        },

        {
          key: "section-confirm",
          parent: "t1-5",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Confirm",
            titleOrder: 3,
            description:
              "Please confirm to add client or you can go back to revise.",
            withBorder: false,
            ml: 10,
            mt: 0,
            mb: 10,
          },
        },

        {
          key: "section-address",
          parent: "t1-6",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Address",
            titleOrder: 3,
            description: "Please fill in the address info",
            withBorder: false,
            ml: 10,
            mt: 0,
            mb: 10,
          },
        },

        // {
        //     key:'basicInfo-address-g',
        //     parent: "section-address",
        //     type:ENUM_FORM_LAYOUT_CONTAINER.GRID,
        //     cols: [
        //         {
        //             key:"basicInfo-address-g-1",
        //             props:{
        //                 span: 12,
        //                 mt:0
        //             }
        //         },
        //         {
        //             key:"basicInfo-address-g-2",
        //             props:{
        //                 span: 6,
        //             }
        //         },
        //         {
        //             key:"basicInfo-address-g-3",
        //             props:{
        //                 span: 6,
        //             }
        //         },

        //     ]
        // },
      ],

      fields: [
        {
          name: "type",
          component: Chips,
          parent: "section-type",
          props: {
            required: true,
            labelOrder: 5,
            description: "Please Select the type of client.",
            items: DATA_CLIENT_TYPES,
            defaultValue: "INDIVIDUAL",
            multiple: false,
          },
        },

        // {
        // 	name: "id",
        // 	component: TextInput,
        // 	parent: "section-ID",
        // 	props: {
        // 		required: true,
        // 		// label: "Please enter client ID ( HKID / PASSPORT / BR )",
        // 		placeholder: "HKID / PASSPORT / BR / Mainland Travel Permit",
        // 		mt: "xl",
        // 	},
        // },
        {
          name: "idType",
          component: Select,
          parent: "section-ID",
          props: {
            required: true,
            label: "ID Type",
            placeholder: "",
            data: DATA_ID_TYPE,
            defaultValue: "ID No.",
            searchable: true,
          },
        },
        {
          name: "id",
          component: UniqueTextInput,
          parent: "section-ID",
          props: {
            required: true,
            uniqueEntity: "client",
            errorMessage:
              "Servicing Rights of the client are already taken. Please check the with operation team for details.",

            label:
              "Please enter the ID ( HKID / PASSPORT / BR / Mainland Travel Permit )",
            placeholder: "HKID / PASSPORT / BR / Mainland Travel Permit",
            mt: 0,
          },
        },

        {
          name: "idIssueCountry",
          component: Select,

          parent: "section-ID",
          props: {
            required: true,
            label: "ID Issue Country",
            placeholder: "",
            data: DATA_COUNTRY,
            defaultValue: "Hong Kong",
            searchable: true,
          },
        },

        {
          name: "salutation",
          component: Select,
          parent: "basicInfo-g1",
          props: {
            required: true,
            label: "Saluation",
            placeholder: "",
            data: DATA_SALUATATION,
            defaultValue: "Mr.",
            searchable: true,
          },
        },
        {
          name: "name",
          component: TextInput,
          parent: "basicInfo-g1",
          props: {
            required: true,
            placeholder: "Chan Tai Man",
            label: "English Name",
          },
        },
        {
          name: "name",
          component: TextInput,
          parent: "section-basicInfo-corporate",
          props: {
            required: true,
            placeholder: "Chan Tai Man",
            label: "English Name",
          },
        },
        {
          name: "cname",
          component: TextInput,
          parent: "basicInfo-g1",
          props: {
            required: true,
            placeholder: "陳大文",
            label: "Chinese Name",
          },
        },
        {
          name: "cname",
          component: TextInput,
          parent: "section-basicInfo-corporate",
          props: {
            required: true,
            placeholder: "陳大文",
            label: "Chinese Name",
          },
        },
        {
          name: "birthday",
          component: DatePicker,
          type: "datePicker",
          parent: "basicInfo-g1",
          props: {
            required: true,
            label: "Date of Birth",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },
        {
          name: "email",
          component: TextInput,
          parent: "basicInfo-g2",
          props: {
            // required: true,
            placeholder: "taiman@gmail.com",
            label: "Email",
          },
        },
        {
          name: "email",
          component: TextInput,
          parent: "section-basicInfo-corporate",
          props: {
            // required: true,
            placeholder: "taiman@gmail.com",
            label: "Email",
          },
        },
        {
          name: "phone",
          component: TextInput,
          parent: "basicInfo-g1",
          props: {
            required: true,
            placeholder: "61210001",
            label: "Phone",
          },
        },
        {
          name: "phone",
          component: TextInput,
          parent: "section-basicInfo-corporate",
          props: {
            required: true,
            placeholder: "61210001",
            label: "Phone",
          },
        },
        {
          name: "gender",
          component: Select,
          parent: "basicInfo-g1",
          props: {
            required: true,
            label: "Gender",
            placeholder: "",
            data: DATA_GENDERS,
            defaultValue: "Male",
            searchable: true,
          },
        },

        {
          name: "occupation",
          component: TextInput,
          parent: "basicInfo-g1",
          props: {
            // required: true,
            placeholder: "Teacher",
            label: "Occupation",
          },
        },
        {
          name: "educationLevel",
          component: Select,
          parent: "basicInfo-g2",
          props: {
            // required: true,
            label: "Education Level",
            placeholder: "",
            data: DATA_EDUCATION_LEVEL,
            defaultValue: "UG",
            searchable: true,
            clearable: true,
          },
        },
        {
          name: "language",
          component: Select,
          parent: "basicInfo-g2",
          props: {
            // required: true,
            label: "Language",
            placeholder: "",
            data: DATA_LANGUAGE,
            defaultValue: "UG",
            searchable: true,
          },
        },

        {
          name: "maritalStatus",
          component: Select,
          parent: "basicInfo-g2",
          props: {
            required: true,
            label: "Marital Status",
            placeholder: "",
            data: DATA_MARIAGE_STATUS,
            defaultValue: "Single",
            searchable: true,
            clearable: true,
          },
        },

        {
          name: "nationality",
          component: Select,

          parent: "basicInfo-g2",
          props: {
            required: true,
            label: "Nationality",
            placeholder: "",
            data: DATA_NATIONALITY,
            defaultValue: "Chinese",
            searchable: true,
            clearable: true,
          },
        },

        {
          name: "residentialCountry",
          component: Select,

          parent: "basicInfo-g2",
          props: {
            required: true,
            label: "Residential Country",
            placeholder: "",
            data: DATA_COUNTRY,
            defaultValue: "Hong Kong",
            searchable: true,
            clearable: true,
          },
        },

        {
          name: "placeOfBirth",
          component: Select,

          parent: "basicInfo-g2",
          props: {
            required: true,
            label: "Country of Birth",
            placeholder: "",
            data: DATA_COUNTRY,
            defaultValue: "Hong Kong",
            searchable: true,
            clearable: true,
          },
        },

        {
          name: "addresses",
          component: AddressList,
          parent: "section-address",
          props: {},
        },

        {
          name: "consultants",
          component: RemoteSelect2,
          parent: "section-consultant",
          props: {
            apiEntity: "user",
            required: true,
            // label: "Consultant",
            placeholder: "Assign consultant(s)",
            valueField: "_id",
            labelField: "name",
            labelRender: (data) =>
              `[${data.staffId ?? " -- "}] ${data.name} ${
                data.aliasName ? "," + data.aliasName : ""
              } ${data.cname}`,
            multiple: true,
            // preQuery,
            preQuery: {
              $and: [{ isConsultant: true }, { status: "ACTIVE" }],
            },
            searchFields: ["name", "staffId", "cname", "email", "aliasName"],
            pageSize: 2000,
            sort: {
              by: "staffId",
              order: "asc",
            },
          },
        },

        {
          name: "consultantList",
          component: ConsultantList,
          parent: "section-consultant",
          props: {
            field: "consultants",
            mt: 0,
          },
        },
        {
          name: "confirm",
          component: FormConfirmation,
          parent: "section-confirm",
          props: {},
        },
      ],
    },
  };

  useEffect(() => {
    if (isConsultant) {
      setFormSchema({
        edit: consultantEditFormSchema,
        add: addFormSchema,
      });
    } else {
      setFormSchema({
        edit: editFormSchema,
        add: addFormSchema,
      });
    }
  }, [isConsultant]);

  useEffect(() => {
    if (
      userRoleName != "GI OPERATION" &&
      userRoleName != "OPERATION" &&
      userRoleName != "MANAGEMENT" &&
      userRoleName != "ACCOUNTING" &&
      currentURL.includes("operation")
    )
      navigate(currentURL.replace("operation", "consultant"));
  }, [userRoleName, currentURL]);

  useEffect(() => {
    switch (role) {
      case "CONSULTANT":
        const q = { "consultants._id": auth().id };
        setPreQuery(q);
        // initialValues.consultants = [auth().id];
        initialValues.consultants = [];
        break;
      default:
        setPreQuery({});
        initialValues.consultants = [];
    }
  }, [role]);

  return (
    <>
      {/* REACT_APP_CLIENT_INIT_STATUS: {process.env.REACT_APP_CLIENT_INIT_STATUS} */}
      {/* {isConsultant ? "Consultant" : "Ops"} */}
      {preQuery && (
        <PageList
          title={"Clients"}
          apiEntity={apiEntity}
          initSort={{ by: "createdAt", order: "desc" }}
          tableSchema={tableSchema}
          formSchema={formSchema}
          showImport={true}
          stats={{
            component: ClientPageStats,
            props: {
              mb: 35,
              role,
            },
          }}
        />
      )}
    </>
  );
}
