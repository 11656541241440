import { useCellRender } from "../../hooks/useCellRender";
import moment from "moment";
import _ from "lodash";
import {
  IconAlertTriangle,
  IconTrash,
  IconPencil,
  IconSettings,
  IconCircleCheck,
} from "@tabler/icons";
import ActionCellRender from "../../components/actionCellRender";
import {
  PageList,
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import FileList from "../../components/fileList";
import ActivityLog from "../../components/activityLog";
import AutoCodeInput from "../../components/autoCodeInput";
import remoteSelect2 from "../../components/remoteSelect2";
import React, { useState, useEffect } from "react";
import { useAuthUser } from "react-auth-kit";
import { useServerApi } from "../../hooks/userServerApi";
import {
  Select,
  Checkbox,
  TextInput,
  Switch,
  Text,
  List,
  ThemeIcon,
  Group,
  Badge,
} from "@mantine/core";
import OrderClientSelect from "../../components/orderClientSelect";
import QuoRequestCheckList from "../../components/quoRequestCheckList";
import FormConfirmation from "../../components/formConfirmation";
import ConsultantList from "../../components/consultantList";
import OrderApplicantDetail from "../../components/orderApplicantDetail";
import QuoRequestSummary from "../../components/quoRequestSummary";
import OrderConsultantList from "../../components/orderConsultantList";
import QuoRequestDetails from "../../components/quoRequestDetails";
import QuoRequestActionList from "../../components/quoRequstActionList";
import QuoRequestPageStats from "../../components/quoRequestPageStats";
import OrderPendingCase from "../../components/orderPendingCase";
import QuoRequestFileCheckList from "../../components/quoRequestFileCheckList";
import QuoFileList from "../../components/quofileList";
import ReactJson from "react-json-view";
const initialValues = {
  code: "",
  consultants: [],
  productType: null,
  files: [],
  checkList: [],
};

const apiEntity = "quoRequest";

export const addFormSchema = {
  name: "AddQuoRequestSchema",
  title: "New Quo Request",
  description: "Please follow the step to create a New Quo",

  display: {
    mode: ENUM_FORM_DISPLAY.MODAL,
  },
  apiEntity,
  showSaveBar: false,

  initialValues,

  layout: {
    containers: [
      {
        key: "t1",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,

        props: {
          variant: "default", //default | pills | outline
          defaultValue: "Product Type",
          orientation: "vertical",
          style: { minHeight: "400px" },
        },
        steps: [
          { key: "t1-1", label: "Product Type", icon: IconSettings },
          { key: "t1-2", label: "Client ", icon: IconSettings },
          { key: "t1-3", label: "Consultant ", icon: IconSettings },
          { key: "t1-4", label: "Information ", icon: IconSettings },
          { key: "t1-6", label: "Upload File", icon: IconSettings },
          { key: "t1-5", label: "Confirmation ", icon: IconSettings },
        ],
      },
    ],
    fields: [
      {
        name: "productType",
        component: remoteSelect2,
        parent: "t1-1",
        props: {
          apiEntity: "productType",
          required: true,
          label: "Please select the product type for quotation",
          valueField: "_id",

          allowClear: true,
          labelRender: (data) => `${data.name} ${data.cname} `,
          multiple: false,
          preQuery: { allowQuoRequest: true },
          searchFields: ["name", "code", "cname"],
          clearable: true,
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },

      {
        name: "client",
        component: remoteSelect2,
        parent: "t1-2",
        props: {
          apiEntity: "client",
          required: true,
          label: "Please select client for the quotation",
          placeholder: "Search Client By Name / HKID/ BR ",
          valueField: "_id",
          labelField: "name",
          allowClear: true,
          labelRender: (data) =>
            `${data.name} ${data.cname} [ ${data.id ?? " -- "} ]`,
          //   labelRender: (data) => `${data.cname}`,
          multiple: false,
          // preQuery: { $and: [ {isConsultant : true}, {isActive: true} ] },
          // preQuery:  {isConsultant : true} ,

          searchFields: ["name", "id", "cname"],
          clearable: true,
          onDataChange: (value, form) => {
            console.log("Client Change", value);
            //Set Consultant
            if (!value || _.isEmpty(value.consultants))
              form.setFieldValue("consultants", []);
            else {
              form.setFieldValue(
                "consultants",
                value.consultants.map((c) => c._id)
              );
            }
          },
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },
      {
        name: "client2",
        component: OrderClientSelect,
        parent: "t1-2",
        props: {},
      },

      {
        name: "consultants",
        component: remoteSelect2,
        parent: "t1-3",
        props: {
          apiEntity: "user",
          required: true,
          label: "Please select consultants ",
          placeholder: "Please select consultant(s)",
          valueField: "_id",
          labelField: "name",
          labelRender: (data) =>
            `${data.name}, ${data.aliasName} (${data.staffId ?? " -- "})`,
          multiple: true,
          preQuery: {
            $and: [{ isConsultant: true }, { status: "ACTIVE" }],
          },
          searchFields: [
            "aliasName",
            "name",
            "staffId",
            "cname",
            "email",
            "phone",
          ],
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },

      {
        name: "consultantList",
        component: ConsultantList,
        parent: "t1-3",
        props: {
          field: "consultants",
        },
      },

      {
        name: "checkList",
        component: QuoRequestCheckList,
        parent: "t1-4",
        props: {
          title: "Please fill in the form to complete the quotation request.",
        },
      },

      {
        name: "fileList",
        component: QuoRequestFileCheckList,
        parent: "t1-6",
        props: {
          title: "Please upload related files.",
        },
      },

      {
        name: "confirm",
        component: FormConfirmation,
        parent: "t1-5",
        props: {
          successText: [
            "Please confirm only if the provided information is correct.",
            "Operation Team will process your quotation once submitted.",
          ],
        },
      },
    ],
  },
};

const ConsultantQuoRequest = function ({ role = "CONSULTANT" }) {
  const [cellRender] = useCellRender();
  const [preQuery, setPreQuery] = useState();
  const auth = useAuthUser();
  const [api] = useServerApi();

  let tableSchema = {
    showActionColumn: true,
    preQuery: { consultants: auth().id },
    columns: [
      {
        field: "code",
        headerName: "Code",
        sortable: true,
        cellRender: (col, data) => cellRender.QuoRequest.status(data.status),
      },
      {
        field: "pendingCases",
        headerName: "Pending",
        sortable: true,
        cellRender: (col, order) => {
          const pendingCases =
            order.pendingCases?.filter((c) => !c.completed) ?? [];
          const completedCases =
            order.pendingCases?.filter((c) => c.completed) ?? [];
          return _.isEmpty(pendingCases) ? (
            _.isEmpty(completedCases) ? (
              ""
            ) : (
              <Badge color="green" radius={0} size="xs">
                <Group spacing={1} noWrap>
                  <IconCircleCheck size={15} />
                  {`${completedCases?.length} DONE`}
                </Group>
              </Badge>
            )
          ) : (
            <Badge color="red" radius={0} size="xs">
              <Group spacing={1} noWrap>
                <IconAlertTriangle size={15} />
                {`${pendingCases?.length} PENDING`}
              </Group>
            </Badge>
          );
        },
      },
      {
        field: "productType",
        headerName: "Product Type",
        sortable: true,
        cellRender: (col, data) => data.productType?.name ?? "",
      },
      {
        field: "client",
        headerName: "Client",
        sortable: true,
        cellRender: (col, data) => cellRender.ClientInfo(data.client),
      },

      {
        field: "consultants",
        headerName: "Consultants",
        sortable: true,
        cellRender: (col, data) => cellRender.Users(data.consultants, true),
      },

      {
        field: "updatedAt",
        headerName: "Last Update",
        sortable: true,
        cellRender: (col, data) => moment(data.updatedAt).format("LLL"),
      },

      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
        ],
        actionMenu: [
          {
            name: "copy",
            label: "Copy",
            icon: IconTrash,
            props: {
              // color: "red",
            },
          },
          {
            name: "delete",
            label: "Delete data",
            icon: IconTrash,
            props: {
              color: "red",
            },
          },
        ],
      },
    ],
    searchableFields: [
      "status",
      "code",
      "productType.code",
      "productType.name",
      "client.name",
      "consultants.name",
      "consultants.staffId",
      "consultants.aliasName",
    ],
  };

  const editFormSchema = {
    display: {
      mode: ENUM_FORM_DISPLAY.LINK,
    },
    title: "Quo Request",
    titleField: "code",
    apiEntity,
    initialValues,
    layout: {
      containers: [
        {
          key: "main-g",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
          props: {
            gutter: "xl",
            columns: 24,
          },
          cols: [
            {
              key: "main-g1",
              props: {
                span: 7,
              },
            },
            {
              key: "main-g2",
              props: {
                span: 17,
              },
            },
          ],
        },

        {
          key: "section-applicant",
          parent: "main-g1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Applicant Details",
            // titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            mt: 0,
            mb: "md",
          },
        },

        {
          key: "section-consultant",
          parent: "main-g1",
          type: ENUM_FORM_LAYOUT_CONTAINER.BOX,
          props: {
            mt: 0,
            mb: 0,
          },
        },

        {
          key: "section-action-list",
          parent: "main-g1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Action List",
            // titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: "0px",
            mt: 0,
            mb: 20,
          },
        },

        {
          key: "main-tab",
          parent: "main-g2",
          type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Overview",
          },
          tab: [
            {
              key: "tab-overview",
              label: "Overview",
              //icon: IconSettings,
            },

            {
              key: "tab-files",
              label: "Files",
              //icon: IconSettings
            },
            {
              key: "tab-log",
              label: "Logs",
              //icon: IconSettings
            },
          ],
        },

        {
          key: "section-summary",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Summary",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: 0,
            mt: 20,
            mb: 20,
          },
        },

        {
          key: "section-checkList",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Details",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: 0,
            mt: 20,
            mb: 20,
          },
        },
        {
          key: "section-quo-files",
          parent: "tab-files",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Quotation File List",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: "0px",
            mt: 20,
            mb: 20,
          },
        },
        {
          key: "section-files",
          parent: "tab-files",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Files",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: "0px",
            mt: 20,
            mb: 20,
          },
        },
        {
          key: "section-pending",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Pending Case",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            mt: 20,
            mb: 20,
          },
        },
        {
          key: "section-message",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Messages",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            mt: 20,
            mb: 20,
          },
        },

        {
          key: "section-log",
          parent: "tab-log",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Logs",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            mt: 20,
            mb: 20,
          },
        },
      ],

      fields: [
        {
          name: "fileList",
          component: QuoFileList,
          parent: "section-quo-files",
          props: {},
        },

        {
          name: "files",
          component: FileList,
          parent: "section-files",
          props: {},
        },

        {
          name: "pendingCases",
          component: OrderPendingCase,
          parent: "section-pending",
          props: {},
        },
        {
          name: "message",
          component: ActivityLog,
          parent: "section-message",
          props: {
            commentOnly: true,
          },
        },
        {
          name: "log",
          component: ActivityLog,
          parent: "section-log",
          props: {
            commentOnly: false,
          },
        },
        {
          name: "client",
          component: OrderApplicantDetail,
          parent: "section-applicant",
          props: {},
        },
        {
          name: "consultants",
          component: OrderConsultantList,
          parent: "section-consultant",
          props: {},
        },
        {
          name: "summary",
          component: QuoRequestSummary,
          parent: "section-summary",
          props: {},
        },

        {
          name: "detail",
          component: QuoRequestDetails,
          parent: "section-checkList",
          props: {},
        },
        {
          name: "actions",
          component: QuoRequestActionList,
          parent: "section-action-list",
          props: {
            role,
          },
        },
      ],
    },
  };

  const actionList = editFormSchema.layout.fields.find(
    (f) => f.name === "actions"
  );

  const formSchema = {
    // add: addFormSchema,
    add: addFormSchema,
    edit: editFormSchema,
  };

  useEffect(() => {
    const q = { consultants: auth().id };
    setPreQuery(q);
    initialValues.consultants = [auth().id];

    //Clients
    const clientField = addFormSchema.layout.fields.find(
      (f) => f.name === "client"
    );
    clientField.props.preQuery = { consultants: auth().id };
  }, []);

  return (
    <>
      {/* role:{role} */}
      {/* <ReactJson src={preQuery} style={{ background: "white" }}></ReactJson> */}

      <PageList
        title={"Quo Request"}
        apiEntity="quoRequest"
        tableSchema={tableSchema}
        formSchema={formSchema}
        initSort={{ by: "code", order: "desc" }}
        searchByTextField={true}
        stats={{
          component: QuoRequestPageStats,
          props: {
            mb: 35,
            role,
          },
        }}
      />
    </>
  );
};

export default ConsultantQuoRequest;
