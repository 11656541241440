import { TextInput, Checkbox, Switch } from "@mantine/core";
import { PageList, ENUM_FORM_DISPLAY } from "../../components/pageList";
import ActionCellRender from "../../components/actionCellRender";
import LicenceCheckList from "../../components/licenceCheckList";
import { IconTrash, IconPencil } from "@tabler/icons";
import React from "react";
import { useAuthUser } from "react-auth-kit";

const apiEntity = "productCategory";

const tableSchema = {
  showActionColumn: true,
  columns: [
    { field: "code", filter: true },
    { field: "name", filter: true },
    { field: "cname", filter: true, headerName: "Chinese Name" },
    {
      field: "action",
      cellRender: ActionCellRender,
      isAction: true,
      actions: [
        {
          name: "edit",
          icon: IconPencil,
          props: {
            //   color:"red"
          },
        },
      ],
      actionMenu: [
        {
          name: "delete",
          label: "Delete data",
          icon: IconTrash,
          props: {
            color: "red",
          },
        },
      ],
    },
  ],
  searchableFields: ["code", "name", "cname", "email"],
};

const formSchema = {
  title: "Product Category",
  isMainForm: true,
  display: {
    mode: ENUM_FORM_DISPLAY.DRAWER,
    size: "xl", //xs, sm, xl  ....100%
    position: "right",
  },
  apiEntity: "productCategory",

  initialValues: {
    code: "",
    name: "",
    cname: "",
  },

  validate: {
    email: (value) =>
      !value || /^\S+@\S+$/.test(value) ? null : "Invalid email",
  },

  layout: {
    fields: [
      {
        name: "code",
        component: TextInput,
        props: {
          required: true,
          label: "Code",
          placeholder: "AIA",
        },
      },
      {
        name: "name",
        component: TextInput,
        props: {
          required: true,
          label: "English Name",
          placeholder: "",
        },
      },
      {
        name: "cname",
        component: TextInput,
        props: {
          // required: true,
          label: "Chinese Name  ",
          placeholder: "",
        },
      },
    ],
  },
};
function ProductCategory() {
  const auth = useAuthUser();
  const userRole = auth().userRole;
  const catLink =
    userRole.entityLinks.find((l) => l.entity === "productCategory")?.link ??
    "/operation/productCategory";

  // Redirect to the correct provider link if not already there
  React.useEffect(() => {
    const currentPath = window.location.pathname;
    if (currentPath !== catLink) {
      window.location.href = catLink;
    }
  }, [catLink]);

  return (
    <PageList
      title={"Product Category"}
      apiEntity={apiEntity}
      tableSchema={tableSchema}
      formSchema={formSchema}
    />
  );
}

export default ProductCategory;
