import React, { useState, useEffect } from "react";
import ReactJson from "react-json-view";

import RemoteSelect2 from "./remoteSelect2";
import {
  Button,
  Group,
  Title,
  Container,
  Box,
  Text,
  Divider,
} from "@mantine/core";
import _ from "lodash";
import moment from "moment";
import { IconCircleCheck } from "@tabler/icons";
import { useServerApi } from "../hooks/userServerApi";
import { useTranslation } from "react-i18next";

const ProductSelection = ({
  form,
  name,
  value,
  formAction,
  style,
  title,
  showProductSnapShot = false,
  ...props
}) => {
  const [api] = useServerApi();
  const [outdated, setOutDated] = useState(false);
  const [latest, setLatest] = useState();
  const productSnapShot = _.get(form.values, "productSnapShot");
  const handleProductChange = (product) => {
    if (!product) return;
    form.setFieldValue("productSnapShot", product);
  };

  const clearSelected = () => {
    form.setFieldValue(name, null);
    form.setFieldValue("productSnapShot", null);
    setOutDated(false);
  };

  const getLatestProductInfo = async () => {
    if (!productSnapShot?._id) return;

    let result = await api.getById({
      apiEntity: "product",
      id: productSnapShot._id,
    });

    setLatest(result);

    form.setFieldValue("productSnapShot", result);
    return;

    _.isEqual(result, productSnapShot) ? setOutDated(false) : setOutDated(true);

    // console.log(
    //     "getLatestProductInfo",
    //     result.updatedAt,
    //     productSnapShot.updatedAt
    // );
  };

  const updateSnapshot = () => {
    form.setFieldValue("productSnapShot", latest);
    setOutDated(false);
  };

  useEffect(() => {
    // console.log(productSnapShot);
    getLatestProductInfo();
  }, [productSnapShot?._id]);

  const { t } = useTranslation();

  return (
    <>
      {/* <ReactJson
        src={form.values.productSnapShot}
        collapsed
        style={{ background: "white" }}
      /> */}
      {title && <Text size={"md"}>{t(title)}</Text>}
      <RemoteSelect2
        apiEntity="product"
        required={true}
        placeholder={t("Search Product By Name/ Code/ Chinese name")}
        valueField="_id"
        labelField="name"
        // labelRender={(data) =>
        // 	`[ ${data.provider.shortName ?? " -- "} ] : ${data.name} ${
        // 		data.cname
        // 	} ( ${data.code} )`
        // }
        labelRender={(data) => `[ ${data.code} ] ${data.name} ${data.cname}`}
        multiple={false}
        clearable={true}
        // preQuery: { $and: [ {isConsultant : true}, {isActive: true} ] }
        // preQuery={{ status: "ACTIVE" }}
        searchFields={["name", "code", "cname"]}
        sort={{
          by: "code",
          order: "asc",
        }}
        value={value}
        form={form}
        formAction={formAction}
        name={name}
        onDataChange={handleProductChange}
      />
      {/* <Group mt={20}>
        <Button size="xs" color={"red"} onClick={clearSelected}>
          {t("Clear")}
        </Button>
      </Group> */}

      {showProductSnapShot && productSnapShot && (
        <Box mt={30}>
          <Divider mb={20} />
          <Title order={5} mb={10}>
            Product Snapshot
          </Title>

          {outdated && (
            <>
              <Text color="dimmed" size="xs">
                Snapshot Version:{" "}
                {moment(productSnapShot.updatedAt).format("lll")}
              </Text>
              <Text color="dimmed" size="xs">
                Latest Version: {moment(latest.updatedAt).format("lll")}
              </Text>
              <Button size="xs" mt={20} onClick={updateSnapshot}>
                Update Product Snapshot
              </Button>
            </>
          )}

          {!outdated && (
            <Group>
              <IconCircleCheck color="green" />
              <Text size="xs" color="dimmed">
                Current Snapshot is most updated product version.
              </Text>
            </Group>
          )}
        </Box>
      )}
    </>
  );
};

export default ProductSelection;
