import React from "react";
import { Button, Text, TextInput, Chip, Select } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useFormRender } from "../hooks/useFormRender";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import { IconSettings } from "@tabler/icons";
import FormConfirmation from "./formConfirmation";
import RemoteSelect2 from "../components/remoteSelect2";
import ConsultantList from "../components/consultantList";
import { useTranslation } from "react-i18next";

import Chips from "../components/chips";
import {
  DATA_COUNTRY,
  DATA_EDUCATION_LEVEL,
  DATA_LANGUAGE,
  DATA_NATIONALITY,
  DATA_SALUATATION,
  DATA_ID_TYPE,
  DATA_CLIENT_TYPES,
  DATA_GENDERS,
  DATA_MARIAGE_STATUS,
} from "../data/options";

import AddressList from "../components/addressList";

const ClientUpdateActionButton = ({ mainForm }) => {
  const { t } = useTranslation();
  const formSchema = {
    title: "Update Client",
    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "1000px", //xs, sm, xl  ....100%
      // position:"right"
    },
    closeAfterSave: true,
    showSaveBar: false,
    apiEntity: "client",
    validate: {
      name: (value) =>
        value?.length < 4 ? "Name must have at least 4 letters" : null,
      id: (value, formValues) => {
        const regrex = /^[A-Z]{1,2}[0-9]{6}\([0-9A]\)$/;
        if (formValues.idType === "HKID") {
          if (!regrex.test(value)) return t("It is not a valid HKID");
        }
        return null;
      },
    },

    layout: {
      containers: [
        {
          key: "t1",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,
          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Type",
            orientation: "vertical",
            style: { minHeight: "400px" },
          },
          steps: [
            { key: "t1-1", label: "Type", icon: IconSettings },

            { key: "t1-2", label: "Client ID", icon: IconSettings },
            { key: "t1-3", label: "Basic Info", icon: IconSettings },
            { key: "t1-6", label: "Address", icon: IconSettings },
            { key: "t1-4", label: "Consultant", icon: IconSettings },

            { key: "t1-5", label: "Confirm", icon: IconSettings },
          ],
        },
        {
          key: "section-type",
          parent: "t1-1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Client Types",
            description: "Please choose type for client",
            withBorder: false,
            ml: 10,
            mt: 0,
          },
        },

        {
          key: "section-ID",
          parent: "t1-2",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Client Identity",
            titleOrder: 5,
            description: "Please enter the primary ID of client",
            withBorder: false,
            ml: 10,
            mt: 0,
            mb: 10,
          },
        },
        {
          key: "section-basicInfo",
          parent: "t1-3",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          visibleOnly: (formValues) => {
            return formValues.type != "CORPORATE";
          },
          props: {
            title: "Basic Info",
            titleOrder: 5,
            description: "Please enter basic info of client",
            withBorder: false,
            ml: 10,
            mt: 0,
            mb: 10,
          },
        },
        {
          key: "section-basicInfo-corporate",
          parent: "t1-3",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          visibleOnly: (formValues) => {
            return formValues.type == "CORPORATE";
          },
          props: {
            title: "Basic Info",
            titleOrder: 5,
            description: "Please enter basic info of client",
            withBorder: false,
            ml: 10,
            mt: 0,
            mb: 10,
          },
        },

        {
          key: "basicInfo-g",
          parent: "section-basicInfo",
          type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
          cols: [
            {
              key: "basicInfo-g1",
              props: {
                span: 6,
              },
            },
            {
              key: "basicInfo-g2",
              props: {
                span: 6,
              },
            },
          ],
        },

        {
          key: "section-consultant",
          parent: "t1-4",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Consultant",
            titleOrder: 5,
            description: "Please assign the corresponding consultant",
            withBorder: false,
            ml: 10,
            mt: 0,
            mb: 10,
          },
        },

        {
          key: "section-confirm",
          parent: "t1-5",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Confirm",
            titleOrder: 3,
            description:
              "Please confirm to add client or you can go back to revise.",
            withBorder: false,
            ml: 10,
            mt: 0,
            mb: 10,
          },
        },

        {
          key: "section-address",
          parent: "t1-6",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Address",
            titleOrder: 3,
            description: "Please fill in the address info",
            withBorder: false,
            ml: 10,
            mt: 0,
            mb: 10,
          },
        },
      ],

      fields: [
        {
          name: "type",
          component: Chips,
          parent: "section-type",
          props: {
            required: true,
            labelOrder: 5,
            description: "Please Select the type of client.",
            items: DATA_CLIENT_TYPES,
            defaultValue: "INDIVIDUAL",
            multiple: false,
          },
        },
        {
          name: "idType",
          component: Select,
          parent: "section-ID",
          props: {
            required: true,
            label: "ID Type",
            placeholder: "",
            data: DATA_ID_TYPE,
            defaultValue: "ID No.",
            searchable: true,
          },
        },
        {
          name: "id",
          component: TextInput,
          parent: "section-ID",
          props: {
            required: true,
            label: "Please enter client ID ( HKID / PASSPORT / BR )",
            placeholder: "HKID / PASSPORT / BR",
          },
        },

        {
          name: "idIssueCountry",
          component: Select,

          parent: "section-ID",
          props: {
            required: true,
            label: "ID Issue Country",
            placeholder: "",
            data: DATA_COUNTRY,
            defaultValue: "Hong Kong",
            searchable: true,
          },
        },
        {
          name: "salutation",
          component: Select,
          parent: "basicInfo-g1",
          props: {
            required: true,
            label: "Saluation",
            placeholder: "",
            data: DATA_SALUATATION,
            defaultValue: "Mr.",
            searchable: true,
          },
        },
        {
          name: "name",
          component: TextInput,
          parent: "basicInfo-g1",
          props: {
            required: true,
            placeholder: "Chan Tai Man",
            label: "English Name",
          },
        },
        {
          name: "name",
          component: TextInput,
          parent: "section-basicInfo-corporate",
          props: {
            required: true,
            placeholder: "Chan Tai Man",
            label: "English Name",
          },
        },
        {
          name: "cname",
          component: TextInput,
          parent: "basicInfo-g1",
          props: {
            required: true,
            placeholder: "陳大文",
            label: "Chinese Name",
          },
        },
        {
          name: "cname",
          component: TextInput,
          parent: "section-basicInfo-corporate",
          props: {
            required: true,
            placeholder: "陳大文",
            label: "Chinese Name",
          },
        },
        {
          name: "birthday",
          component: DatePicker,
          type: "datePicker",
          parent: "basicInfo-g1",
          props: {
            required: true,
            label: "Date of Birth",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },
        {
          name: "email",
          component: TextInput,
          parent: "basicInfo-g2",
          props: {
            // required: true,
            placeholder: "taiman@gmail.com",
            label: "Email",
          },
        },
        {
          name: "email",
          component: TextInput,
          parent: "section-basicInfo-corporate",
          props: {
            // required: true,
            placeholder: "taiman@gmail.com",
            label: "Email",
          },
        },

        {
          name: "phone",
          component: TextInput,
          parent: "section-basicInfo-corporate",

          props: {
            required: true,
            placeholder: "61210001",
            label: "Phone",
          },
        },
        {
          name: "phone",
          component: TextInput,
          parent: "basicInfo-g1",
          props: {
            required: true,
            placeholder: "61210001",
            label: "Phone",
          },
        },

        {
          name: "gender",
          component: Select,
          parent: "basicInfo-g1",
          props: {
            required: true,
            label: "Gender",
            placeholder: "",
            data: DATA_GENDERS,
            defaultValue: "Male",
            searchable: true,
          },
        },

        {
          name: "occupation",
          component: TextInput,
          parent: "basicInfo-g1",
          props: {
            required: true,
            placeholder: "Teacher",
            label: "Occupation",
          },
        },
        {
          name: "educationLevel",
          component: Select,
          parent: "basicInfo-g2",
          props: {
            required: true,
            label: "Education Level",
            placeholder: "",
            data: DATA_EDUCATION_LEVEL,
            defaultValue: "UG",
            searchable: true,
            clearable: true,
          },
        },
        {
          name: "language",
          component: Select,
          parent: "basicInfo-g2",
          props: {
            required: true,
            label: "Language",
            placeholder: "",
            data: DATA_LANGUAGE,
            defaultValue: "UG",
            searchable: true,
            clearable: true,
          },
        },

        {
          name: "maritalStatus",
          component: Select,
          parent: "basicInfo-g2",
          props: {
            required: true,
            label: "Marital Status",
            placeholder: "",
            data: DATA_MARIAGE_STATUS,
            defaultValue: "Single",
            searchable: true,
            clearable: true,
          },
        },

        {
          name: "nationality",
          component: Select,

          parent: "basicInfo-g2",
          props: {
            required: true,
            label: "Nationality",
            placeholder: "",
            data: DATA_NATIONALITY,
            defaultValue: "Chinese",
            searchable: true,
            clearable: true,
          },
        },

        {
          name: "residentialCountry",
          component: Select,

          parent: "basicInfo-g2",
          props: {
            required: true,
            label: "Residential Country",
            placeholder: "",
            data: DATA_COUNTRY,
            defaultValue: "Hong Kong",
            searchable: true,
            clearable: true,
          },
        },

        {
          name: "placeOfBirth",
          component: Select,
          parent: "basicInfo-g2",
          props: {
            required: true,
            label: "Country of Birth",
            placeholder: "",
            data: DATA_COUNTRY,
            defaultValue: "Hong Kong",
            searchable: true,
            clearable: true,
          },
        },

        {
          name: "addresses",
          component: AddressList,
          parent: "section-address",
          props: {},
        },
        {
          name: "consultants",
          component: RemoteSelect2,
          parent: "section-consultant",
          props: {
            apiEntity: "user",
            required: true,
            // label: "Consultant",
            placeholder: "Assign consultant(s)",
            valueField: "_id",
            labelField: "name",
            labelRender: (data) =>
              `${data.name} ${data.aliasName ? "," + data.aliasName : ""} ${
                data.cname
              } (${data.staffId ?? " -- "})`,
            multiple: true,
            // preQuery,
            // preQuery:  {isConsultant : true} ,
            searchFields: ["name", "staffId", "cname", "email", "aliasName"],
            sort: {
              by: "name",
              order: "desc",
            },
          },
        },

        {
          name: "consultantList",
          component: ConsultantList,
          parent: "section-consultant",
          props: {
            field: "consultants",
            mt: 0,
          },
        },

        {
          name: "confirm",
          component: FormConfirmation,
          parent: "section-confirm",
          props: {},
        },
      ],
    },
  };

  const onSubmit = ({ values, formMode }) => {
    mainForm.setValues(values);
    mainForm.saveValues(values);
  };

  const handleActionClick = () => {
    const id = mainForm?.values._id;
    if (!id) return;
    formAction.open({ mode: "edit", id, data: mainForm.values });
  };

  const onSubmitSuccess = () => {
    formAction.close();
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    onSubmitSuccess,
    onSubmit
  );
  return (
    <>
      <Button fullWidth onClick={handleActionClick}>
        {t("Update Information")}
      </Button>

      {renderForm()}
    </>
  );
};

export default ClientUpdateActionButton;
