import React, { useState } from "react";
import { Text } from "@mantine/core";
import { FormSectionCard } from "../layout/page";
import { useFormRender } from "../hooks/useFormRender";
import { useServerApi } from "../hooks/userServerApi";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Card, Group } from "@mantine/core";
import { PageList } from "./pageList";
import { TextInput, NumberInput, Badge, Input, Button } from "@mantine/core";
import AutoCodeInput from "./autoCodeInput";
import DummyWidget from "./dummyWidget";
import { Switch } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { Grid, Stack, Table } from "@mantine/core";
import ReactJson from "react-json-view";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import toast from "react-hot-toast";

import { useCellRender } from "../hooks/useCellRender";
import { useFormatter } from "../hooks/useFomatter";
import { IconTrash, IconPencil, IconRefreshDot } from "@tabler/icons";

import ActionCellRender from "./actionCellRender";
import moment from "moment";

const TranasactionMatchedPaymentList = ({ form, name }) => {
  const [payments, setPayments] = useState([]);
  const [api] = useServerApi();
  const [cellRender] = useCellRender();
  const id = form?.values?._id;

  const unmatch = async () => {
    try {
      if (
        window.confirm(
          "Are you sure to unmatch the transaction and payment? Debit Bids may assign to consultants if settlement complete."
        )
      ) {
        // console.log("unmatch", id);
        const result = await api.BankTransaction.unMatch(id);
        console.log("result", result);
        toast.success("Unmatch successfully");
        fetchData();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchData = async () => {
    try {
      if (!id) return;
      const result = await api.search({
        apiEntity: "clientPayment",
        searchQuery: {
          bankTransaction: id,
        },
      });
      // console.log("result", result);
      setPayments(result.docs);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);
  return (
    <>
      <Card withBorder>
        <Table>
          <thead>
            <tr>
              <th>Code</th>
              <th>Date</th>
              <th>Amount</th>
              <th>Debit Notes</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {payments.map((payment) => (
              <tr key={payment._id}>
                <td>{payment.code}</td>
                <td>{moment(payment.date).format("LL")}</td>
                <td>{cellRender.Currency(payment.amount)}</td>
                <td>
                  {payment.debitNoteAssignments.map((debitNote) => (
                    <div key={debitNote.debitNote}>
                      <a
                        href={`/m/gi/debitNote?id=${debitNote.debitNote._id}&mode=edit`}
                        target="_blank"
                      >
                        {debitNote.debitNote.code}
                      </a>
                    </div>
                  ))}
                </td>
                <td>{payment.status}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        {payments.length === 0 && (
          <Text color="dimmed" align="center" mt="md">
            No matched payments found
          </Text>
        )}
      </Card>
      {payments.length > 0 && (
        <Button onClick={() => unmatch()} size="xs" mt="xl" color="red">
          Unmatch
        </Button>
      )}
    </>
  );
};

const BankAccountTransactions = ({
  bankAccount,
  form,
  onImportSuccess,
  onDeleteSuccess,
}) => {
  const [cellRender] = useCellRender();
  const [formatter] = useFormatter();
  const [api] = useServerApi();

  const transactionId = form?.values?.id;

  const initialValues = {
    status: "MATCHING",
    type: "DEPOSIT",
    deposit: 0,
    withdraw: 0,
    bankAccount: bankAccount?._id,
  };

  const formSchema = {
    title: "Bank Transaction",
    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "1000px", //xs, sm, xl  ....100%
      position: "right",
    },
    closeAfterSave: true,
    apiEntity: "bankTransaction",

    initialValues,

    validate: {},

    layout: {
      containers: [
        {
          key: "main-tab",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Transaction Info",
          },
          tab: [
            {
              key: "tab1",
              label: "Transaction Info",
              //icon: IconSettings,
            },
            {
              key: "tab2",
              label: "Matched Payments",
              // icon: IconSettings,
            },
          ],
        },
      ],
      fields: [
        {
          name: "type",
          component: DummyWidget,
          parent: "tab1",
          props: {
            dummyRender: (v) => (
              <Input.Wrapper>
                <Badge
                  size="md"
                  color={v.deposit - v.withdraw >= 0 ? "green" : "red"}
                >
                  {v.deposit - v.withdraw >= 0 ? "Deposit" : "Withdrawal"}
                </Badge>

                <Badge
                  size="md"
                  color={v.deposit - v.withdraw >= 0 ? "green" : "red"}
                  ml="md"
                >
                  {cellRender.Currency(v.deposit - v.withdraw)}
                </Badge>
              </Input.Wrapper>
            ),
          },
        },

        {
          name: "code",
          component: AutoCodeInput,
          parent: "tab1",

          props: {
            required: true,
            label: "Code",
            placeholder: "Auto Code",
          },
        },

        {
          name: "date",
          component: DatePicker,
          type: "datePicker",
          parent: "tab1",

          props: {
            required: true,
            label: "Transaction Date",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },

        {
          name: "detail",
          component: TextInput,
          parent: "tab1",

          props: {
            // required: true,
            label: "Detail",
            placeholder: "",
          },
        },
        {
          name: "deposit",
          component: NumberInput,
          parent: "tab1",

          props: {
            // required: true,
            label: "Deposit",
            precision: 2,
            parser: formatter.currencyParser,
            formatter: formatter.currencyFormat,
          },
        },
        {
          name: "withdraw",
          component: NumberInput,
          parent: "tab1",

          props: {
            // required: true,
            label: "Withdrawal",
            precision: 2,
            parser: formatter.currencyParser,
            formatter: formatter.currencyFormat,
          },
        },
        {
          name: "balance",
          component: NumberInput,
          parent: "tab1",

          props: {
            // required: true,
            label: "Balance",
            precision: 2,
            parser: formatter.currencyParser,
            formatter: formatter.currencyFormat,
          },
        },

        {
          name: "remark",
          component: TextInput,
          parent: "tab1",

          props: {
            // required: true,
            label: "Remark",
            placeholder: "",
          },
        },
        {
          name: "skipMatching",
          component: Switch,
          parent: "tab1",
          props: {
            type: "checkbox",

            label: "Skip Matching",
          },
        },

        {
          name: "payments",
          component: TranasactionMatchedPaymentList,
          parent: "tab2",
          props: {
            id: transactionId,
            // required: true,
          },
        },
      ],
    },
  };
  const tableSchema = {
    showActionColumn: true,
    // preQuery: { bankAccountId: id },

    columns: [
      {
        field: "code",
        headerName: "Code",
        sortable: true,
        cellRender: (col, data) => (
          <>
            <Group>
              <Badge
                color={data.type === "DEPOSIT" ? "green" : "red"}
                size="xs"
                radius="xs"
              >
                {data.type}
              </Badge>
            </Group>
            <Text size="xs" weight={700}>
              {data.code}
            </Text>
            <Text color="dimmed" size="xs">
              {moment(data.date).format("LL")}
            </Text>
          </>
        ),
      },

      { field: "detail", headerName: "Detail", sortable: true },
      {
        field: "withdraw",
        headerName: "Withdraw",
        sortable: true,
        cellRender: (col, data) => cellRender.Currency(data.withdraw),
      },
      {
        field: "deposit",
        headerName: "Deposit",
        sortable: true,
        cellRender: (col, data) => cellRender.Currency(data.deposit),
      },
      {
        field: "balance",
        headerName: "Balance",
        sortable: true,
        cellRender: (col, data) => cellRender.Currency(data.balance),
      },

      {
        field: "status",
        headerName: "Status",
        sortable: true,
        cellRender: (col, data) =>
          data.skipMatching
            ? cellRender.BankTransactionStatus("SKIP")
            : cellRender.BankTransactionStatus(data.status),
      },

      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
        ],
        actionMenu: [
          {
            name: "delete",
            label: "Delete data",
            icon: IconTrash,
            props: {
              color: "red",
            },
          },
        ],
      },
    ],
    searchableFields: [
      "code",
      "type",
      "consultantSnapshot.name",
      "consultantSnapshot.cname",
      "consultantSnapshot.staffId",
      "remark",
      "title",
    ],
  };

  return (
    <>
      <FormSectionCard>
        <Grid gutter={200}>
          <Grid.Col span={6}>
            <Stack spacing="xs">
              <Group position="apart">
                <Text size="xs">Short Name:</Text>
                <Text size="xs">{bankAccount?.shortName}</Text>
              </Group>
              <Group position="apart">
                <Text size="xs">Account Holder Name:</Text>
                <Text size="xs">{bankAccount?.accountHolderName}</Text>
              </Group>
              <Group position="apart">
                <Text size="xs">Account Number:</Text>
                <Text size="xs">{bankAccount?.accountNumber}</Text>
              </Group>
              <Group position="apart">
                <Text size="xs">Bank Name:</Text>
                <Text size="xs">{bankAccount?.bank}</Text>
              </Group>
              <Group position="apart">
                <Text size="xs">Account Type:</Text>
                <Text size="xs">{bankAccount?.accountType}</Text>
              </Group>
            </Stack>
          </Grid.Col>
          <Grid.Col span={4}>
            <Stack spacing="xs">
              <Group position="apart">
                <Text size="xs">Current Balance:</Text>
                <Text size="xs">
                  {cellRender.Currency(bankAccount?.balance)}
                </Text>
              </Group>
              <Group position="apart">
                <Text size="xs">Last Transaction Date:</Text>
                <Text size="xs">
                  {bankAccount?.lastTransactionDate
                    ? moment(bankAccount?.lastTransactionDate).format("LL")
                    : "-"}
                </Text>
              </Group>
            </Stack>
          </Grid.Col>
        </Grid>
      </FormSectionCard>
      <PageList
        title={""}
        apiEntity={"bankTransaction"}
        showAddButton={false}
        tableSchema={tableSchema}
        formSchema={formSchema}
        showImport={true}
        showExport={true}
        importData={{ bankAccountId: bankAccount?._id }}
        // preQueryNotEmpty={true}
        preQuery={{ bankAccount: bankAccount?._id }}
        onImportSuccess={onImportSuccess}
        onDeleteSuccess={onDeleteSuccess}
        initSort={{ by: "code", order: "desc" }}
      />
    </>
  );
};

export default BankAccountTransactions;
