import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./i18n";

// Add this function to set the viewport
function setViewport() {
  const viewport = document.querySelector('meta[name="viewport"]');
  if (viewport) {
    viewport.content = "width=1500";
  } else {
    const newViewport = document.createElement("meta");
    newViewport.name = "viewport";
    newViewport.content = "width=1024";
    document.head.appendChild(newViewport);
  }
}
// Call the function before rendering
setViewport();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
