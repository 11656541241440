import React, { useState, useEffect } from "react";
import { Text } from "@mantine/core";
import { useServerApi } from "./userServerApi";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { buildSearchQuery } from "../components/pageList";
import { useLocalStorage } from "@mantine/hooks";
import { authName } from "../App";

import { functionLinks } from "../data/navLinks";
import { useAuthUser } from "react-auth-kit";
import { useLocation } from "react-router-dom";

export const useAccessRight = () => {
  const auth = useAuthUser();
  const { userRole } = auth();
  const linkPath = useLocation().pathname;

  const getCurrentNavLink = () => {
    const navLink = functionLinks?.find((f) => f.link === linkPath);
    return navLink;
  };

  const getAccessRight = (navLinkId) => {
    if (!userRole) return 0;
    if (!linkPath) return 0;

    const link = userRole.links.find((l) => l.link === navLinkId);
    // if (navLinkId === "M102") {
    //   console.log("userRole", userRole);
    //   console.log("link", link);
    //   console.log("navLinkId", navLinkId);
    // }

    return link?.accessRight ?? 0;
  };

  const currentLink = getCurrentNavLink();
  const navLinkId = currentLink?.linkId ?? 0;
  const accessRight = getAccessRight(navLinkId);
  // console.log("accessRight", accessRight);
  return [getAccessRight, accessRight, navLinkId, currentLink];
};
