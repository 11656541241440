import React from "react";

import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import { useFormRender } from "../hooks/useFormRender";
import { IconCheck, IconSettings, IconAlertCircle } from "@tabler/icons";
import { showNotification } from "@mantine/notifications";
import {
  Button,
  Text,
  TextInput,
  Select,
  Badge,
  Group,
  Textarea,
  Table,
  NumberInput,
  Checkbox,
  Divider,
  ScrollArea,
  Input,
} from "@mantine/core";
import { useState, useEffect } from "react";
import { DatePicker } from "@mantine/dates";
import UserLeaveOpeningBalance from "./userLeaveOpeningBalance";
import UserLeaveNonReleaseBid from "./userLeaveNonReleaseBid";
import UserLeaveClient from "./userLeaveClient";
import RemoteSelect2 from "./remoteSelect2";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useServerApi } from "../hooks/userServerApi";
import FormConfirmation from "./formConfirmation";
import moment from "moment";
import DummyWidget from "./dummyWidget";
import { useCellRender } from "../hooks/useCellRender";
import { useFormatter } from "../hooks/useFomatter";
import ReactJson from "react-json-view";
import { ThemeIcon } from "@mantine/core";
import { useForceUpdate } from "@mantine/hooks";

const ClientList = ({ form, label = "User", onConfirmSuccess }) => {
  const [api] = useServerApi();
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formatter] = useFormatter();
  const userId = form.values?.userId;
  const consultant = form.values?.consultant;
  const [cellRender] = useCellRender();
  const fetchClients = async () => {
    try {
      if (!userId) return;
      setLoading(true);

      const result = await api.User.getClients(userId, false);
      setLoading(false);
      // console.log("result", result);
      setClients(result?.clients ?? []);
    } catch (error) {
      console.error("Error fetching clients:", error);
      setLoading(false);
    }
  };

  const confirmTransfer = async () => {
    try {
      const selectedClientsCount = clients.filter((c) => c.checked).length;
      const confirmMessage = `Are you sure you want to transfer ${selectedClientsCount} client(s) to ${consultant?.name} (${consultant?.staffId})?`;

      const transferBidOwnershipMessage =
        "\n\n Bids OwnerShip Transfer : " +
        (form.values.transferBidOwnership ? "YES" : "NO");

      const warningMessage = form.values.transferBidOwnership
        ? "\n\nWARNING: You have selected to transfer bid ownership. This will affect uncertain orders."
        : "\n\nWARNING: You have selected to transfer client(s) without transfer bid ownership. This uncertain bid ownership will be transferred to the new consultant.";
      if (
        !window.confirm(
          confirmMessage + transferBidOwnershipMessage + warningMessage
        )
      )
        return;
      if (!clients.some((c) => c.checked)) return;
      if (!userId) return;

      if (!form.values?.consultant) return;

      const consultantId =
        form.values?.consultant?._id ?? form.values?.consultant;
      const clientIds = clients.filter((c) => c.checked).map((c) => c._id);

      const transferBidOwnership = form.values?.transferBidOwnership ?? false;

      const result = await api.User.transferClients({
        userId,
        consultantId,
        clientIds,
        transferBidOwnership,
      });

      // console.log(result);

      showNotification({
        title: "Client Transferred",
        message: "Client transferred successfully",
      });

      fetchClients();
    } catch (error) {
      console.error("Error transferring clients:", error);
      showNotification({
        title: "Error",
        message: "Failed to transfer clients",
      });
    }
  };

  useEffect(() => {
    fetchClients();
  }, [userId]);

  const forceUpdate = useForceUpdate();
  const defaultNumClients = 25;

  const randomSelectClients = () => {
    const numClients = form.values.numberOfClients || defaultNumClients;
    const method = form.values.selectionMethod || "randomWithLicence";
    const selectedConsultant = form.values.consultant;

    let selectedClients;
    const withLicenceCheck = method.includes("WithLicence");
    const eligibleClients = withLicenceCheck
      ? clients.filter((client) => {
          if (!client.licencesNeed || client.licencesNeed.length === 0)
            return false;
          return client.licencesNeed.every(
            (licence) =>
              selectedConsultant.consultantInfo.licences[licence.toLowerCase()]
          );
        })
      : clients;

    if (method.startsWith("random")) {
      selectedClients = _.sampleSize(eligibleClients, numClients);
    } else if (method.includes("topOrders")) {
      selectedClients = _.orderBy(
        eligibleClients,
        ["totalOrders"],
        ["desc"]
      ).slice(0, numClients);
    } else if (method.includes("topPremium")) {
      selectedClients = _.orderBy(
        eligibleClients,
        ["totalPremium"],
        ["desc"]
      ).slice(0, numClients);
    }

    setClients(
      clients.map((client) => ({
        ...client,
        checked: selectedClients.some((c) => c._id === client._id),
      }))
    );
    forceUpdate();
  };

  const randomSelectClientsOptions = [
    {
      value: "randomWithLicence",
      label: "Random (Licence Check)",
    },
    {
      value: "topPremiumWithLicence",
      label: "Top Premium (Licence Check)",
    },
    {
      value: "topOrdersWithLicence",
      label: "Top Orders (Licence Check)",
    },
    {
      value: "randomWithoutLicence",
      label: "Random (No Licence Check)",
    },
    {
      value: "topOrdersWithoutLicence",
      label: "Top Orders (No Licence Check)",
    },

    {
      value: "topPremiumWithoutLicence",
      label: "Top Premium (No Licence Check)",
    },
  ];
  return (
    <>
      {/* <ReactJson src={form.values} style={{ backgroundColor: "white" }} /> */}
      <Divider my="md" variant="dashed" />

      <Group align="end">
        <NumberInput
          size="xs"
          min={1}
          max={clients.length}
          defaultValue={defaultNumClients}
          label="Number of clients"
          onChange={(value) => form.setFieldValue("numberOfClients", value)}
        />
        <Select
          size="xs"
          data={randomSelectClientsOptions}
          defaultValue="randomWithLicence"
          label="Selection Method"
          onChange={(value) => form.setFieldValue("selectionMethod", value)}
          style={{ width: "300px" }} // Increased width
        />
        <Button
          size="xs"
          onClick={() => {
            randomSelectClients();
          }}
          disabled={loading || !consultant}
        >
          Select Clients
        </Button>
      </Group>

      <Divider my="md" variant="dashed" />
      <Group position="apart">
        <Group spacing={10}>
          <Group>
            <Text size="xs">Clients</Text>
            <Badge radius={0} size="xs">
              {clients.length}
            </Badge>
          </Group>
          <Group>
            <Text size="xs">Selected</Text>
            <Badge radius={0} size="xs" color="green">
              {clients.filter((c) => c.checked).length}
            </Badge>
          </Group>

          <Checkbox
            ml="md"
            label="Transfer Bids Ownership (Uncertain Orders)"
            checked={form.values.transferBidOwnership}
            size="xs"
            color="red"
            onChange={(event) =>
              form.setFieldValue(
                "transferBidOwnership",
                event.currentTarget.checked
              )
            }
          />
        </Group>

        <Group>
          <Button onClick={() => fetchClients()} size="xs" loading={loading}>
            Fetch Clients
          </Button>
          <Button
            disabled={
              !clients.some((c) => c.checked) || !form.values.consultant
            }
            onClick={() => {
              confirmTransfer();
              /* Add transfer logic here */
            }}
            size="xs"
            color="red"
          >
            Confirm Transfer
          </Button>
        </Group>
      </Group>

      <Group mt="md"></Group>

      {/* <ScrollArea style={{ width: "100%", height: "60vh" }}> */}
      <Table withBorder stickyHeader stickyHeaderOffset={60}>
        <thead>
          <tr>
            <th>
              <Checkbox
                checked={clients.every((client) => client.checked)}
                onChange={(e) => {
                  setClients(
                    clients.map((c) => ({ ...c, checked: e.target.checked }))
                  );
                }}
              />
            </th>
            <th>Consultants</th>
            <th>Client</th>
            <th>Type</th>
            <th>No. of Orders</th>
            <th>Total Premium</th>
            <th>Licences Need</th>
          </tr>
        </thead>
        <tbody>
          {clients.map((client) => (
            <tr key={client._id}>
              <td>
                <Checkbox
                  checked={client.checked}
                  onChange={(e) => {
                    setClients(
                      clients.map((c) =>
                        c._id === client._id
                          ? { ...c, checked: e.target.checked }
                          : c
                      )
                    );
                  }}
                />
              </td>
              <td>{cellRender.Users(client.consultants)}</td>
              <td
                style={{
                  maxWidth: "200px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <a
                  href={`/management/client?id=${client._id}&mode=edit`}
                  target="_blank"
                  style={{ textDecoration: "none", color: "inherit" }}
                  onMouseOver={(e) => (e.target.style.color = "teal")}
                  onMouseOut={(e) => (e.target.style.color = "inherit")}
                >
                  {client.name} {client.cname}
                </a>
              </td>
              <td>
                <Text color={client.type === "CORPORATE" ? "green" : "inherit"}>
                  {client.type}
                </Text>
              </td>
              <td>{client.totalOrders}</td>
              <td>{formatter.currency(client.totalPremimum, "HKD")}</td>
              <td>
                <Group justify="space-between">
                  <Group spacing={4}>
                    {client.licencesNeed.map((licence) => (
                      <Badge key={licence} color="blue" size="sm">
                        {licence.toUpperCase()}
                      </Badge>
                    ))}
                  </Group>
                  <Group ml="auto">
                    {client.licencesNeed.every(
                      (licence) =>
                        consultant?.consultantInfo?.licences?.[licence]
                    ) && (
                      <ThemeIcon color="green" size="xs" radius="xl">
                        <IconCheck size="1rem" />
                      </ThemeIcon>
                    )}
                  </Group>
                </Group>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* </ScrollArea> */}
    </>
  );
};

const UserCard = ({ form, label = "User" }) => {
  const [api] = useServerApi();
  const [user, setUser] = useState(null);
  const [cellRender] = useCellRender();

  const userId = form.values?.consultant?._id ?? form.values?.consultant;

  useEffect(() => {
    const fetchUser = async () => {
      try {
        if (!userId) return;
        const user = await api.getById({ apiEntity: "user", id: userId });
        // console.log("user", user);
        setUser(user);
        form.setFieldValue("consultant", user);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };
    fetchUser();
  }, [userId]);
  return (
    <>
      {/* <ReactJson src={user} /> */}
      <Group>
        {cellRender.User(user)}
        {user?.consultantInfo && (
          <Group spacing={2}>
            {Object.entries(user?.consultantInfo?.licences || {}).map(
              ([key, value]) =>
                value && (
                  <Badge key={key} color="blue" radius={"xl"} size="xs">
                    {key}
                  </Badge>
                )
            )}
          </Group>
        )}
      </Group>
    </>
  );
};

const UserTransferClientButton = ({ mainForm, ...props }) => {
  const [api] = useServerApi();

  const formSchema = {
    title: "Client Transfer",
    description: "Please follow the step to complete the client transfer",

    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "1500px", //xs, sm, xl  ....100%
    },

    showSaveBar: false,
    closeAfterSave: true,

    initialValues: {
      //client and consultant matching
      consultantClients: [],
      //selected consultants for transfer client
      consultants: [],
    },

    layout: {
      containers: [
        {
          key: "t1",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.BOX,
          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Details",
            orientation: "vertical",
            // style: { minHeight: "800px" },
          },
          // steps: [
          //   {
          //     key: "t1-1",
          //     label: "New Consultant",
          //     icon: IconSettings,
          //   },
          //   {
          //     key: "t1-2",
          //     label: "Clients",
          //     icon: IconSettings,
          //   },
          //   {
          //     key: "t1-3",
          //     label: "Confirmation",
          //     icon: IconSettings,
          //   },
          // ],
        },
      ],

      fields: [
        {
          name: "consultant",
          component: RemoteSelect2,
          parent: "t1",
          props: {
            label: "New Consultant",
            apiEntity: "user",
            required: true,
            placeholder: "Pick Consultant",
            allowFreeInput: true,
            valueField: "_id",

            preQuery: {
              $and: [{ isConsultant: true }, { status: "ACTIVE" }],
            },
            labelField: "name",
            searchFields: ["name", "email", "cname", "staffId"],
            labelRender: (data) => {
              return ` [ ${data.staffId ?? ""} ] ${data.name ?? ""} ${
                data.cname ?? ""
              } ${data.aliasName ? `, ${data.aliasName}` : ""} \t/\t ${
                data.consultantInfo?.licences
                  ? Object.entries(data.consultantInfo.licences)
                      .map(([key, value]) => (value ? key.toUpperCase() : ""))
                      .filter((x) => x)
                      .join(", ")
                  : ""
              }`;
            },
            multiple: false,
            clearable: true,
            sort: { by: "staffId", order: "asc" },
            pageSize: 200,
          },
        },
        {
          name: "consultant",
          parent: "t1",
          component: UserCard,
          props: {},
        },

        {
          name: "clients",
          parent: "t1",

          component: ClientList,
          props: {},
        },

        // {
        //   name: "effectiveDate",
        //   component: DatePicker,
        //   type: "datePicker",
        //   parent: "section-detail",
        //   props: {
        //     required: true,
        //     label: "Effective Date",
        //     zIndex: 1000,
        //     placeholder: "Pick Date",
        //     allowFreeInput: true,
        //   },
        // },
        // {
        //   name: "lastEmploymentDate",
        //   component: DatePicker,
        //   type: "datePicker",
        //   parent: "section-detail",
        //   props: {
        //     required: true,
        //     label: "Last Employment Date",
        //     zIndex: 1000,
        //     placeholder: "Pick Date",
        //     allowFreeInput: true,
        //   },
        // },

        // {
        //   name: "reason",
        //   component: Textarea,
        //   parent: "section-detail",
        //   props: {
        //     label: "Reason",
        //   },
        // },
        // {
        //   name: "balance",
        //   component: UserLeaveOpeningBalance,
        //   parent: "section-balance",
        //   props: {},
        // },
        // {
        //   name: "bids",
        //   component: UserLeaveNonReleaseBid,
        //   parent: "section-bid",
        //   props: {},
        // },

        // {
        //   name: "consultantClients",
        //   component: UserLeaveClient,
        //   parent: "section-client",
        //   props: {},
        // },

        // {
        //   name: "consultantClients",
        //   component: FormConfirmation,
        //   parent: "section-confirm",
        //   props: {},
        // },
      ],
    },
  };

  const status = _.get(mainForm.values, "status");

  const onSubmit = async ({ values, formMode }) => {
    try {
      if (!values.userId || !values.user) return;

      delete values.user;
      // console.log("onSubmit", values);

      const result = await api.User.terminate(values);
      // console.log(result);

      if (result && result.success) {
        mainForm.setFieldValue("status", result?.data?.user.status);
        showNotification({
          title: `User Terminated.`,
          icon: <IconCheck size={18} />,
          message: "Data Saved",
        });
        formAction.close();
      } else throw new Error(result.error);
    } catch (error) {
      showNotification({
        title: `User can't be terminated.`,
        icon: <IconAlertCircle size={18} />,
        message: error.message,
      });
    }
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    null,
    onSubmit
  );

  const handleClick = async () => {
    const user = mainForm?.values;
    if (!user) return;

    const userTransfer = {
      userId: user._id,
      user,
      consultantClients: [],
    };
    formAction.open({ mode: "edit", data: userTransfer });
  };

  const { t } = useTranslation();
  return (
    <>
      {/* Status: {clientStatus} */}
      <Button
        fullWidth
        onClick={handleClick}
        color={"red"}
        // disabled={status === "TERMINATED"}
      >
        {t("Transfer Clients")}
      </Button>
      {renderForm()}
    </>
  );
};

export default UserTransferClientButton;
