import React, { useState, useEffect } from "react";
import moment from "moment";
import { DatePicker } from "@mantine/dates";
import { IconExclamationMark, IconArrowBack } from "@tabler/icons";

import _ from "lodash";
import { useTranslation } from "react-i18next";
import RemoteSelect2 from "../components/remoteSelect2";

import {
  Switch,
  TextInput,
  Select,
  Textarea,
  NumberInput,
  Group,
  Text,
  Button,
  Loader,
  Badge,
} from "@mantine/core";
import FormConfirmation from "./formConfirmation";
import { showNotification } from "@mantine/notifications";
import { DATA_GENDERS, DATA_RELATIONSHIP } from "../data/options";
import { useFormRender } from "../hooks/useFormRender";

import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import LocalPageList from "./localPageList";
import FileList from "./fileList";
import ActionCellRender from "./actionCellRender";
import { useFormatter } from "../hooks/useFomatter";

import { useServerApi } from "../hooks/userServerApi";
// import ReactJson from "react-json-view";
import { useCellRender } from "../hooks/useCellRender";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import { useForceUpdate, randomId } from "@mantine/hooks";
import DataTable from "./dataTable";
import Order from "../pages/operation/order";
import AddClientPaymentButton from "./addClientPaymentButton";
import DummyWidget from "./dummyWidget";
import ServiceCheckList from "./serviceCheckList";
import { useAuthUser } from "react-auth-kit";
import DebitNotePaymentMatchingList from "./debitNotePaymentMatchingList";
import { getFileIcon, getFileIconColor, isImageFile } from "./fileList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RefundExcessPaymentButton from "./refundExcessPaymentButton";
import { Image, Tooltip } from "@mantine/core";

import ReactJson from "react-json-view";

export const ImageThumbList = ({ files, size = 20, max = 2 }) => {
  const imageFiles = files?.filter((file) => isImageFile(file.mimetype));
  const otherFiles = files?.filter((file) => !isImageFile(file.mimetype));
  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
      {imageFiles?.slice(0, max)?.map((file, index) => (
        <Tooltip
          key={index}
          zIndex={1000}
          label={
            <Image
              src={file.url}
              alt={`${file.name}`}
              fit="contain"
              height={250}
            />
          }
          position="bottom"
          withArrow
        >
          <Image
            src={file.url}
            alt={`Thumbnail ${index + 1}`}
            width={size}
            height={size}
            fit="cover"
            onClick={() => {
              window.open(file.url, "_blank");
            }}
          />
        </Tooltip>
      ))}
      {otherFiles?.length > 0 &&
        otherFiles?.slice(0, max)?.map((file, index) => (
          <FontAwesomeIcon
            alt={`${file.name}`}
            icon={getFileIcon(file.mimetype)}
            color={getFileIconColor(file.mimetype)}
            size="xl"
            onClick={() => {
              window.open(file.url, "_blank");
            }}
          ></FontAwesomeIcon>
        ))}

      {files?.length > max && (
        <Text size="sm" color="dimmed">
          +{files.length - max} more
        </Text>
      )}
    </div>
  );
};

const ClientPaymentList = ({ form: mainForm, ...props }) => {
  const dnId = _.get(mainForm.values, "_id");
  const dn = mainForm.values;
  const payments = _.get(mainForm.values, "clientPayments");
  const [formatter] = useFormatter();

  const [rows, setRows] = useState(payments);
  const [api] = useServerApi();
  const [cellRender] = useCellRender();
  const [printing, setPrinting] = useState(false);
  const clientId = _.get(mainForm.values, "client._id");

  const getDNAssignmentAmount = (payment) => {
    try {
      const assignment = payment.debitNoteAssignments.find(
        (assignment) => assignment.debitNote._id === dnId
      );
      // console.log("getDNAssignmentAmount assignment", payment, assignment);
      if (!assignment) return payment.amount;
      return assignment.paymentAmount;
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    //sort payments by date desc
    const sortedPayments = payments?.sort((a, b) => {
      return moment(a.date).diff(moment(b.date));
    });
    setRows(sortedPayments);
  }, [payments]);

  const tableSchema = {
    hidePagination: true,
    columns: [
      // {   field: '_id',       headerName:"ID"            },
      {
        field: "date",
        headerName: "Payment date",
        cellRender: (col, data) => (
          <>
            {data.date ? moment(data.date).format("YYYY-MM-DD") : ""}
            {data.type === "REFUND" && (
              <Badge color="red" size="xs" radius="0" ml={"xl"}>
                Refund
              </Badge>
            )}
          </>
        ),
      },
      {
        field: "code",
        headerName: "Code",
        cellRender: (col, data) => (
          <>
            {data.debitNotes.length > 0 && (
              <>
                <Text size="xs">{data?.code}</Text>
                {data.debitNotes.length > 1 && (
                  <Badge color="green" size="xs" radius="0">
                    {formatter.currency(data.amount)} ( {data.debitNotes.length}{" "}
                    DN )
                  </Badge>
                )}
              </>
            )}
          </>
        ),
      },
      {
        field: "status",
        headerName: "Status",
        cellRender: (col, data) => data.status,
      },

      {
        field: "bankAccount",
        headerName: "Bank Account",
        cellRender: (col, data) => data.bankAccount?.shortName ?? "",
      },
      {
        field: "amount",
        headerName: "Amount",
        cellRender: (col, data) => (
          <Text size="xs">
            {formatter.currency(getDNAssignmentAmount(data))}
          </Text>
        ),
      },
      {
        field: "files",
        headerName: "Receipts",
        cellRender: (col, data) => ImageThumbList({ files: data.files }),
      },
      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          // {
          //   name: "edit",
          //   icon: IconPencil,
          //   props: {
          //     //   color:"red"
          //   },
          // },
        ],
        actionMenu: [
          {
            name: "printReceipt",
            label: "Print Receipt",
            props: {
              size: "xs",
            },
          },
          {
            name: "edit",
            label: "Edit",
            props: {
              size: "xs",
            },
          },
          {
            name: "delete",
            label: "Delete",
            props: {
              color: "red",
              size: "xs",
            },
          },
        ],
      },
    ],
  };

  const formSchema = {
    name: "AddClientPaymentSchema",
    title: "Edit Client Payment",
    description: "Please follow the step to create a New Client Payment",

    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "1000px", //xs, sm, xl  ....100%
    },

    apiEntity: "clientPayment",
    initialValues: {},

    validate: {
      amount: (value) => {
        if (!value) return "Payment Amount is required";
        if (value <= 0) return "Payment Amount must be greater than 0";
        return null;
      },
      bankAccount: (value) => {
        if (!value) return "Bank Account is required";
        return null;
      },
      date: (value) => {
        if (!value) return "Payment Date is required";
        return null;
      },
      files: (value) => {
        if (!value) return "Receipt File is required";
        return null;
      },
    },
    showSaveBar: true,

    layout: {
      containers: [
        {
          key: "t1",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Payment Info",
          },
          tab: [
            {
              key: "t1",
              label: "Payment Info",
              //icon: IconSettings,
            },
            {
              key: "tab-multiple",
              label: "Multiple Debit Notes",
              // icon: IconSettings,
            },
          ],
        },
      ],

      fields: [
        //Payment Date
        {
          name: "date",
          component: DatePicker,
          parent: "t1",
          type: "datePicker",
          props: {
            label: "Payment Date",
            required: true,
            clearable: true,
            valueFormat: "YYYY-MM-DD",
            allowFreeInput: true,
          },
        },

        //Payment Amount
        {
          name: "amount",
          component: NumberInput,
          parent: "t1",
          props: {
            label: "Payment Amount",
            //parse as money
            parser: formatter.currencyParser,
            formatter: formatter.currencyFormat,
            precision: 2,
            onChange: (value) => {
              console.log("amount", value);
            },
          },
        },

        //Direct to Provider
        {
          name: "directToProvider",
          parent: "t1",
          component: Switch,
          props: {
            type: "checkbox",
            size: "md",
            label: "Direct to Provider ",
            mt: "md",
          },
        },

        //Bank Account
        {
          name: "bankAccount",
          component: RemoteSelect2,
          parent: "t1",
          visibleOnly: (values, accessRight) => !values.directToProvider,

          props: {
            label: "Bank Account",
            apiEntity: "bankAccount",
            required: true,
            placeholder: "Bank Account",
            valueField: "_id",
            labelField: "name",

            // allowClear: true,
            // clearable: true,
            labelRender: (data) => {
              //Get bank name from bankAccount.bank
              return ` ${data?.shortName ?? ""} : ${
                data?.accountNumber ?? ""
              } `;
            },
            multiple: false,
            searchFields: ["name", "code", "cname"],
            clearable: true,
            sort: { by: "bank", order: "asc" },
          },
        },

        //Receipt File
        {
          name: "files",
          component: FileList,
          parent: "t1",
          props: {
            title: "Receipt File",
            tableMarginBottom: 10,
            showNoFilesMessage: false,
            defaultDisplayMode: 1,
          },
        },
        {
          name: "remark",
          parent: "t1",
          component: Textarea,
          props: {
            label: "Remark",
          },
        },
        // {
        //   name: "isMultiple",
        //   parent: "section-debit-note",
        //   component: Switch,
        //   props: {
        //     label: "Pay to multiple debit notes",
        //   },
        // },
        {
          name: "debitNotes",
          parent: "tab-multiple",
          component: DebitNotePaymentMatchingList,
          props: {
            label: "Debit Notes",
            clientId,
            mode: "edit",
          },
        },
      ],
    },
  };

  const handleActionBtnClick = ({ action, data, rowIndex }) => {
    // console.log("handleActionBtnClick", action, data, rowIndex);
    switch (action) {
      case "delete":
        return deletePayment(data);
      case "printReceipt":
        return printReceipt(data);
      case "edit":
        return edit(data);
    }
  };

  const onSubmit = async ({ values, formMode }) => {
    try {
      console.log("onSubmit update payment ", values._id, values);
      const result = await api.ClientPayment.update(values._id, values);
      // console.log("onSubmit", result);
      if (result) {
        showNotification({
          title: "Success",
          message: "Payment updated",
        });
        formAction.close();
        mainForm.reload();
      }
    } catch (error) {
      console.log("onSubmit", error);
      formAction.close();
      showNotification({
        color: "red",
        icon: <IconExclamationMark size={18} />,
        title: "Error",
        message: error.msg ?? error.message,
      });
    }
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    null,
    onSubmit
  );

  const deletePayment = async (payment) => {
    try {
      if (payment.status !== "MATCHING") {
        showNotification({
          color: "red",
          icon: <IconExclamationMark size={18} />,
          title: "Error",
          message: "Only matching payment can be deleted",
        });
        return;
      }
      const result = await api.ClientPayment.remove(payment._id);
      if (result) {
        showNotification({
          title: "Success",
          message: "Payment deleted",
        });
        mainForm.reload();
      }
    } catch (error) {
      console.log("deletePayment", error);
    }
  };

  const printReceipt = (payment) => {
    console.log("printReceipt", payment);
  };

  const edit = (payment) => {
    // console.log("edit", payment);
    if (payment.status == "MATCHED") {
      showNotification({
        color: "red",
        icon: <IconExclamationMark size={18} />,
        title: "Error",
        message:
          "Payment has been matched. Please unmatch first by account department.",
      });
      return;
    }
    formAction.open({ mode: "edit", id: payment._id, data: payment });
  };

  const onAdded = (payment) => {
    // console.log("OnAdded", payment);
    mainForm.reload();
  };

  const onRefund = (payment) => {
    console.log("onRefund", payment);
    mainForm.reload();
  };

  const { t } = useTranslation();

  const handleRefund = () => {
    console.log("handleRefund");
    if (!window.confirm("Are you sure to request refund this payment?")) {
      return;
    }
  };

  const showRefundButton = dn?.payment?.amount > dn?.clientPayable;

  return (
    <>
      {/* <ReactJson
        src={payments}
        style={{ backgroundColor: "white" }}
        collapsed
      /> */}
      {!_.isEmpty(rows) && (
        <DataTable
          data={rows ?? []}
          columns={tableSchema.columns}
          hidePagination={true}
          hideSearchBar={true}
          // onRowDoubleClick={handleOnEdit}
          onActionBtnClick={handleActionBtnClick}
        />
      )}
      {!printing && (
        <Group>
          <AddClientPaymentButton mainForm={mainForm} onAdded={onAdded} />
          {showRefundButton && (
            <RefundExcessPaymentButton mainForm={mainForm} onAdded={onRefund} />
          )}
        </Group>
      )}
      {printing && (
        <Group spacing={"xs"}>
          <Loader size={"xs"} /> <Text size="xs"> Printing</Text>
        </Group>
      )}
      {renderForm()}
    </>
  );
};

export default ClientPaymentList;
