import React from "react";
import { Button, Text, TextInput, Chip, Select } from "@mantine/core";

import _ from "lodash";
import { useTranslation } from "react-i18next";

import { useServerApi } from "../hooks/userServerApi";
import { useAuthUser } from "react-auth-kit";

const CLIENT_STATUS = ["PENDING", "ACTIVE", "INACTIVE"];

const ClientActiveEnableButton = ({ mainForm, ...props }) => {
  const clientStatus = _.get(mainForm.values, "status");
  const { t } = useTranslation();
  const [api] = useServerApi();
  const auth = useAuthUser();

  const handleClick = () => {
    // console.log("handleClick", mainForm.values.status);
    if (clientStatus === "ACTIVE") {
      mainForm.values.status = "INACTIVE";
    } else {
      mainForm.values.status = "ACTIVE";
    }
    mainForm.saveValues(mainForm.values);
    api.addActivityLog({
      entityId: mainForm.values._id,
      title: `Client status changed to ${mainForm.values.status}`,
      by: auth().id,
    });
  };

  const isActive = () => {
    return clientStatus === "ACTIVE";
  };

  return (
    <>
      {/* Status: {clientStatus} */}
      <Button
        fullWidth
        onClick={handleClick}
        color={isActive() ? "red" : "green"}
      >
        {isActive() ? t("Deactive Client") : t("Active Account")}
      </Button>
    </>
  );
};

export default ClientActiveEnableButton;
