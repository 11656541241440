import React from "react";
import { Page, FormSectionCard } from "../layout/page";

import EventSection from "../components/eventSection";
import TopRankSection from "../components/topRankSection";
import ConsultantIncentvieSection from "../components/consultantIncentiveSection";
import ClientPromotionSection from "../components/clientPromotionSection";
import { Tabs } from "@mantine/core";
import { useTranslation } from "react-i18next";
import BusinessTab from "../components/businessTab";

const Home = ({}) => {
  const mt = 50;
  const { t } = useTranslation();
  const showTopRanking = process.env.REACT_APP_SHOW_TOP_RANKING === "true";

  return (
    <Page title={"Home"}>
      <EventSection title={"My Calendar"} mt={mt} />

      {/* <Tabs defaultValue={"clientPromotion"} mt={mt}>
        <Tabs.List>
          <Tabs.Tab value="clientPromotion">{t("Client Promotion")}</Tabs.Tab>
          <Tabs.Tab value="consultantIncentive">
            {t("Consultant Incentive")}
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="clientPromotion" pt="xs">
          <ClientPromotionSection />
        </Tabs.Panel>
        <Tabs.Panel value="consultantIncentive" pt="xs">
          <ConsultantIncentvieSection />
        </Tabs.Panel>
      </Tabs> */}
      <BusinessTab mt={"xl"} />
      {showTopRanking && <TopRankSection title={"Top 5 Rankings"} mt={mt} />}

      {/* <FormSectionCard title={"CPD Course Enroll"} mt={mt}></FormSectionCard> */}
      {/* <FormSectionCard title={"Internal Tranining"}></FormSectionCard> */}
    </Page>
  );
};

export default Home;
