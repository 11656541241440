import React from "react";
import InfoGridList from "./infoGridList";
import { useCellRender } from "../hooks/useCellRender";
import { DATA_DN_STATUS, DATA_DN_PAYMENT_STATUS } from "../data/options";
import { Alert, Group } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons";
import _ from "lodash";
import moment from "moment";

export default function DebitNoteTimeline({ form, name }) {
  const [cellRender] = useCellRender();
  const dn = form.values;

  const status = DATA_DN_STATUS.find((s) => s.value === dn?.status);
  const paymentStatus = DATA_DN_PAYMENT_STATUS.find(
    (s) => s.value === dn?.paymentStatus
  );

  const fields = [
    { title: "DN Date", value: dn?.date ? moment(dn?.date).format("LL") : "-" },
    {
      title: "Period From",
      value: dn?.period?.start ? moment(dn?.period?.start).format("LL") : "-",
    },
    {
      title: "Period To",
      value: dn.period?.end ? moment(dn?.period?.end).format("LL") : "-",
    },
    {
      title: "Hold Cover At",
      value: dn?.holdCover?.date
        ? moment(dn?.holdCover?.date).format("LL")
        : "-",
    },
    { title: "Created At", value: moment(dn?.createdAt).format("LLL") },
  ];

  return dn && <InfoGridList fields={fields} values={dn} />;
}
