import React, { useState, useEffect } from "react";
import {
  Group,
  Text,
  Badge,
  CopyButton,
  Tooltip,
  ActionIcon,
} from "@mantine/core";
import { useServerApi } from "../hooks/userServerApi";
import { useCellRender } from "../hooks/useCellRender";
import {
  IconPhoto,
  IconBuildingSkyscraper,
  IconSearch,
  IconLink,
  IconCopy,
  IconCheck,
} from "@tabler/icons";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import DataTable from "./dataTable";
import { FormSectionCard } from "../layout/page";
import moment from "moment";
import { ImageThumbList } from "./clientPaymentList";
import ReactJson from "react-json-view";
import { useAuthUser } from "react-auth-kit";

const PortalLoginTab = ({ provider }) => {
  const [api] = useServerApi();
  const [portalLogin, setPortalLogin] = useState([]);

  const auth = useAuthUser();

  const fetchPortalLogin = async () => {
    if (!provider?._id) return;
    const data = await api.Provider.getPublicLogin(provider._id);
    if (data) setPortalLogin(data);

    if (auth().id) {
      const personalLogin = await api.User.getPortalLogin(
        auth().id,
        provider._id
      );
      if (_.isEmpty(personalLogin)) return;
      setPortalLogin([...data, ...personalLogin]);
    }

    // const personalLogin = await api.Provider.getUserLogin(provider._id);
    // console.log("Fetch portal login", data);
  };

  useEffect(() => {
    fetchPortalLogin();
  }, [provider]);

  const portalLoginColumns = [
    {
      headerName: "Label",
      field: "label",
      cellRender: (column, data) => (
        <Group>
          <Badge color={data.isPublic ? "green" : "blue"} radius={0} size="xs">
            {data.isPublic ? "Public" : "Private"}
          </Badge>
          <Text>{data.label}</Text>
        </Group>
      ),
    },
    {
      headerName: "URL",
      field: "url",
      cellRender: (column, data) => (
        <a
          href={`${data.url}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          {data.url}
        </a>
      ),
    },
    {
      headerName: "Login Name",
      field: "username",
      cellRender: (column, data) => (
        <Group noWrap spacing="xs" justify="space-between" w="100%">
          <Text>{data.username ? data.username : "N/A"}</Text>
          <CopyButton value={data.username} timeout={2000}>
            {({ copied, copy }) => (
              <Tooltip
                label={copied ? "Copied" : "Copy"}
                withArrow
                position="right"
                size="xs"
              >
                <ActionIcon
                  color={copied ? "teal" : "gray"}
                  variant="subtle"
                  onClick={copy}
                >
                  {copied ? (
                    <IconCheck style={{ width: 16 }} />
                  ) : (
                    <IconCopy style={{ width: 16 }} />
                  )}
                </ActionIcon>
              </Tooltip>
            )}
          </CopyButton>
        </Group>
      ),
    },
    {
      headerName: "Password",
      field: "password",
      cellRender: (column, data) => (
        <Group noWrap>
          <Text>{data.password ? data.password : "N/A"}</Text>
          <CopyButton value={data.password} timeout={2000}>
            {({ copied, copy }) => (
              <Tooltip
                label={copied ? "Copied" : "Copy"}
                withArrow
                position="right"
              >
                <ActionIcon
                  color={copied ? "teal" : "gray"}
                  variant="subtle"
                  onClick={copy}
                >
                  {copied ? (
                    <IconCheck style={{ width: 16 }} />
                  ) : (
                    <IconCopy style={{ width: 16 }} />
                  )}
                </ActionIcon>
              </Tooltip>
            )}
          </CopyButton>
        </Group>
      ),
    },
  ];

  return (
    <>
      {/* <ReactJson src={auth()} style={{ background: "white" }} collapsed /> */}

      {portalLogin.length === 0 && (
        <FormSectionCard mt="xl" ml="xl">
          <Text size="xs">No Portal Login found</Text>
        </FormSectionCard>
      )}

      {portalLogin.length > 0 && (
        <FormSectionCard mt="xl" ml="xl">
          <DataTable
            data={portalLogin}
            columns={portalLoginColumns}
            hidePagination={true}
            hideSearchBar={true}
            showHeader={false}
            padding={0}
          />
        </FormSectionCard>
      )}
    </>
  );
};

export default PortalLoginTab;
