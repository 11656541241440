import React from "react";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import { useFormRender } from "../hooks/useFormRender";
import { Button, Text, TextInput, Select, Textarea } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { IconSettings } from "@tabler/icons";
import DateOffPicker from "./dateOffPicker";
import { useServerApi } from "../hooks/userServerApi";
import { DATA_POLICY_DELIVER_METHODS } from "../data/options";
import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons";

const CreditNoteDeliveryButton = ({ mainForm, label = "Deliver" }) => {
  const [api] = useServerApi();
  const formSchema = {
    title: "Deliver Credit Note",
    description: "Please follow the step",

    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "xl", //xs, sm, xl  ....100%
    },
    showSaveBar: true,
    closeAfterSave: true,

    initialValues: {
      policyDeliverMethod: "Electronic",
    },

    layout: {
      fields: [
        {
          name: "deliveryAt",
          component: DatePicker,
          parent: null,
          type: "datePicker",
          props: {
            required: true,
            label: "Deliver Date",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
            type: "datePicker",
          },
        },

        {
          name: "deliveryRemark",
          component: Textarea,
          parent: null,

          props: {
            label: "Delivery Remark",
            minRows: 4,
          },
        },
      ],
    },
  };

  const onSubmit = async ({ values, formMode }) => {
    // console.log("onSubmit", values._id);
    values.status = "DELIVERED";
    await mainForm.saveValues(values);
    formAction.close();

    await mainForm.reload();

    showNotification({
      title: `Credit Note ${values.code} deliver to client`,
      icon: <IconCheck size={18} />,
      message: "Data Saved",
    });
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    null,
    onSubmit
  );

  const handleActionClick = () => {
    const id = mainForm?.values._id;
    // console.log('%corderPrintCoverSheetActionButton.js line:86 object', 'color: #007acc;', id);
    if (!id) return;
    formAction.open({ mode: "edit", id, data: mainForm.values });
  };

  return (
    <>
      <Button fullWidth onClick={handleActionClick}>
        {label}
      </Button>

      {renderForm()}
    </>
  );
};

export default CreditNoteDeliveryButton;
