import React from "react";
import { Textarea } from "@mantine/core";
import TemplateSaveLoadButtons from "./templateSaveLoadButtons";

const MessageFiller = ({ form, name, templateCategory = "", ...props }) => {
  const loadFromTemplate = (template) => {
    console.log("loadFromTemplate", template, form.values, name);
    form.setFieldValue(name, template?.content);
  };

  return (
    <>
      <TemplateSaveLoadButtons
        form={form}
        name={name}
        templateCategory={templateCategory}
        onLoadTemplate={loadFromTemplate}
        currentContent={form.values[name]}
      />
      <Textarea {...form.getInputProps(name)} {...props} />
    </>
  );
};

export default MessageFiller;
