import React, { useState, useEffect } from "react";
import {
  Tabs,
  ScrollArea,
  Grid,
  Button,
  Card,
  UnstyledButton,
  Avatar,
  Group,
  Text,
  Stack,
  SegmentedControl,
  TextInput,
  Paper,
} from "@mantine/core";
import { useServerApi } from "../hooks/userServerApi";
import { useCellRender } from "../hooks/useCellRender";
import {
  IconPhoto,
  IconBuildingSkyscraper,
  IconSearch,
  IconLink,
} from "@tabler/icons";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import DataTable from "./dataTable";
import { FormSectionCard } from "../layout/page";
import moment from "moment";
import { ImageThumbList } from "./clientPaymentList";

const ConsultantIncentiveTab = ({ provider, consultantIncentives }) => {
  const consultantIncentivesFiltered = consultantIncentives.filter(
    (i) => i.provider._id === provider._id
  );

  return (
    <>
      {consultantIncentivesFiltered.length > 0 && (
        <DataTable
          data={consultantIncentives.filter(
            (i) => i.provider._id === provider._id
          )}
          columns={[
            {
              field: "from",
              headerName: "From",
              cellRender: (column, data) =>
                data.submitDateRange?.start
                  ? moment(data.submitDateRange.start).format("YYYY/MM/DD")
                  : "",
            },
            {
              field: "to",
              headerName: "To",
              cellRender: (column, data) =>
                data.submitDateRange?.end
                  ? moment(data.submitDateRange.end).format("YYYY/MM/DD")
                  : "",
            },
            {
              field: "title",
              headerName: "Title",
              cellRender: (column, data) => (
                <Group justify="space-between" noWrap>
                  {data.url ? (
                    <Text lineClamp={1}>
                      <a
                        href={data.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none" }}
                      >
                        {data.title}
                      </a>
                    </Text>
                  ) : (
                    <Text lineClamp={1}>{data.title}</Text>
                  )}
                </Group>
              ),
            },
            {
              field: "details",
              headerName: "Details",
              cellRender: (column, data) => (
                <ImageThumbList files={data?.files} />
              ),
            },
            //   { field: "submitDateRange", headerName: "Date Range" },
          ]}
          hideSearchBar={true}
          hidePagination={true}
        />
      )}
      {consultantIncentivesFiltered.length === 0 && (
        <FormSectionCard mt="xl" ml="xl">
          <Text size="xs">No Consultant incentives found</Text>
        </FormSectionCard>
      )}
    </>
  );
};

export default ConsultantIncentiveTab;
