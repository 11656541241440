import React, { useState, useEffect } from "react";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import { useFormRender } from "../hooks/useFormRender";
import { useTranslation } from "react-i18next";
import {
  Button,
  TextInput,
  Switch,
  Select,
  Textarea,
  ActionIcon,
  NumberInput,
  UnstyledButton,
  Group,
  Text,
} from "@mantine/core";
import {
  IconCircle,
  IconCirclePlus,
  IconExclamationMark,
  IconArrowBack,
  IconSettings,
} from "@tabler/icons";

import { DATA_BANK_WITH_CODE } from "../data/options";

import { DatePicker } from "@mantine/dates";
import FileList from "./fileList";

import { useServerApi } from "../hooks/userServerApi";
import { showNotification } from "@mantine/notifications";
import _ from "lodash";
import RemoteSelect from "../components/remoteSelect";
import RemoteSelect2 from "../components/remoteSelect2";
import { DATA_ORDER_TYPE, DATA_COUNTRY } from "../data/options";
import Chips from "./chips";

import FormConfirmation from "./formConfirmation";
import RemarkList from "./remarkList";
import ReactJson from "react-json-view";
import { useFormatter } from "../hooks/useFomatter";
import DebitNotePaymentMatchingList from "./debitNotePaymentMatchingList";

const RefundExcessPaymentButton = ({ mainForm, onAdded }) => {
  const [api] = useServerApi();
  const apiEntity = "clientPayment";
  const [formatter] = useFormatter();
  const initialValues = {};

  const debitNoteId = mainForm?.values?._id;

  const { t } = useTranslation();

  const handleActionClick = async () => {
    try {
      if (!debitNoteId) return;
      if (!window.confirm("Are you sure to refund the excess payment?")) return;
      const data = await api.DebitNote.refundExcess(debitNoteId);
      console.log(data);
      if (onAdded) onAdded(data);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <UnstyledButton onClick={() => handleActionClick()}>
        <Group spacing={"xs"}>
          <ActionIcon>
            <IconArrowBack size={"1rem"} />
          </ActionIcon>
          <Text size={"xs"}>{t("Refund Extra")}</Text>
        </Group>
      </UnstyledButton>
    </>
  );
};

export default RefundExcessPaymentButton;
