import React from "react";
import {
  Text,
  TextField,
  Group,
  TextInput,
  Checkbox,
  Divider,
  Card,
  Input,
} from "@mantine/core";
import { useForceUpdate } from "@mantine/hooks";

const LinkageRow = ({ item, form, name }) => {
  const forceUpdate = useForceUpdate();

  return (
    <Card pt={0}>
      <Group grow mt={"sm"}>
        <TextInput
          placeholder="Official Website"
          label="Label"
          value={item.content["name"]}
          onChange={(e) => {
            item.content["name"] = e.target.value;
            forceUpdate();
          }}
        />
        <TextInput
          placeholder="http://www.aia.com.hk"
          label="URL Address"
          value={item.content["url"]}
          miw={400}
          onChange={(e) => {
            item.content["url"] = e.target.value;
            forceUpdate();
          }}
        />
        <Input.Wrapper label="Show to Consultant?">
          <Checkbox
            // label="Show to Consultant? "
            checked={item.content["isPublic"]}
            onChange={(e) => {
              item.content["isPublic"] = e.target.checked;
              forceUpdate();
            }}
            type="checkbox"
          />
        </Input.Wrapper>
      </Group>
    </Card>
  );
};

export default LinkageRow;
