import React from "react";
import {
  PageList,
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";

import { useCellRender } from "../../hooks/useCellRender";
import { useFormatter } from "../../hooks/useFomatter";

import ActionCellRender from "../../components/actionCellRender";

import { IconTrash, IconPencil, IconSettings } from "@tabler/icons";
import { NumberInput, TextInput, Select, Input, Badge } from "@mantine/core";
import RemoteSelect2 from "../../components/remoteSelect2";

import _ from "lodash";
import moment from "moment";
import { DatePicker } from "@mantine/dates";
import {
  DATA_INCENTIVE_TYPE,
  DATA_BANK_TRANSACTION_TYPE,
} from "../../data/options";
import AutoCodeInput from "../../components/autoCodeInput";
import DummyWidget from "../../components/dummyWidget";

const apiEntity = "bankTransaction";

const initialValues = {
  status: "WAIT",
  type: "DEPOSIT",
  deposit: 0,
  withdraw: 0,
};

const BankTransaction = () => {
  const [cellRender] = useCellRender();
  const [formatter] = useFormatter();

  const tableSchema = {
    showActionColumn: true,
    columns: [
      // {
      //   field: "status",
      //   cellRender: (col, data) => cellRender.Status(data.status),
      // },
      {
        field: "date",
        headerName: "Transaction Date",
        sortable: true,
        cellRender: (col, data) => moment(data.issueDate).format("LL"),
      },
      { field: "type", headerName: "Type", sortable: true },
      {
        field: "withdraw",
        headerName: "Withdraw",
        sortable: true,
        cellRender: (col, data) => cellRender.Currency(data.withdraw),
      },
      {
        field: "deposit",
        headerName: "Deposit",
        sortable: true,
        cellRender: (col, data) => cellRender.Currency(data.deposit),
      },
      {
        field: "balance",
        headerName: "Balance",
        sortable: true,
        cellRender: (col, data) => cellRender.Currency(data.balance),
      },

      { field: "detail", headerName: "Detail", sortable: true },

      { field: "status", headerName: "Status", sortable: true },

      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
        ],
        actionMenu: [
          {
            name: "delete",
            label: "Delete data",
            icon: IconTrash,
            props: {
              color: "red",
            },
          },
        ],
      },
    ],
    searchableFields: [
      "code",
      "type",
      "consultantSnapshot.name",
      "consultantSnapshot.cname",
      "consultantSnapshot.staffId",
      "remark",
      "title",
    ],
  };

  const formSchema = {
    title: "Bank Transaction",
    display: {
      mode: ENUM_FORM_DISPLAY.DRAWER,
      size: "xl", //xs, sm, xl  ....100%
      position: "right",
    },
    apiEntity: "bankTransaction",

    initialValues,

    validate: {},

    layout: {
      fields: [
        {
          name: "type",
          component: DummyWidget,
          props: {
            dummyRender: (v) => (
              <Input.Wrapper>
                <Badge
                  size="md"
                  color={v.deposit - v.withdraw >= 0 ? "green" : "red"}
                >
                  {v.deposit - v.withdraw >= 0 ? "Deposit" : "Withdrawal"}
                </Badge>

                <Badge
                  size="md"
                  color={v.deposit - v.withdraw >= 0 ? "green" : "red"}
                  ml="md"
                >
                  {cellRender.Currency(v.deposit - v.withdraw)}
                </Badge>
              </Input.Wrapper>
            ),
          },
        },

        {
          name: "code",
          component: AutoCodeInput,
          props: {
            required: true,
            label: "Code",
            placeholder: "Auto Code",
          },
        },

        {
          name: "date",
          component: DatePicker,
          type: "datePicker",
          props: {
            required: true,
            label: "Transaction Date",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },

        {
          name: "accountNumber",
          component: TextInput,
          props: {
            // required: true,
            label: "Account Number",
            placeholder: "",
          },
        },
        {
          name: "detail",
          component: TextInput,
          props: {
            // required: true,
            label: "Detail",
            placeholder: "",
          },
        },
        {
          name: "deposit",
          component: NumberInput,
          props: {
            // required: true,
            label: "Deposit",
            precision: 2,
            // parser: formatter.currencyParser,
            // formatter: formatter.currencyFormat,
          },
        },
        {
          name: "withdraw",
          component: NumberInput,
          props: {
            // required: true,
            label: "Withdrawal",
            precision: 2,
            // parser: formatter.currencyParser,
            // formatter: formatter.currencyFormat,
          },
        },

        {
          name: "remark",
          component: TextInput,
          props: {
            // required: true,
            label: "Remark",
            placeholder: "",
          },
        },
      ],
    },
  };

  return (
    <>
      {/* {
				<ReactJson
					src={initialValues}
					style={{ background: "white" }}
					collapsed
				/>
			} */}

      <PageList
        title={"BankTransaction"}
        apiEntity={apiEntity}
        tableSchema={tableSchema}
        formSchema={formSchema}
        showImport={true}
      />
    </>
  );
};

export default BankTransaction;
