import React from "react";

import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import { useFormRender } from "../hooks/useFormRender";
import { IconCheck, IconSettings, IconAlertCircle } from "@tabler/icons";
import { showNotification } from "@mantine/notifications";
import { Button, Text, TextInput, Select, Textarea } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import UserLeaveOpeningBalance from "./userLeaveOpeningBalance";
import UserLeaveNonReleaseBid from "./userLeaveNonReleaseBid";
import UserLeaveClient from "./userLeaveClient";
import RemoteSelect2 from "./remoteSelect2";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useServerApi } from "../hooks/userServerApi";
import FormConfirmation from "./formConfirmation";
import moment from "moment";

const UserLeaveActionButton = ({ mainForm, ...props }) => {
  const [api] = useServerApi();

  const formSchema = {
    title: "User Resign",
    description: "Please follow the step to complete the resign process",

    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "1000px", //xs, sm, xl  ....100%
    },
    showSaveBar: false,
    closeAfterSave: true,
    initialValues: {
      //client and consultant matching
      consultantClients: [],
      //selected consultants for transfer client
      consultants: [],
      //Recuriter Installment when negative balance
      recruiterInstallment: 12,
    },

    layout: {
      containers: [
        {
          key: "t1",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,
          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Details",
            orientation: "vertical",
            // style: { minHeight: "800px" },
          },
          steps: [
            {
              key: "t1-1",
              label: "Details",
              icon: IconSettings,
            },
            {
              key: "t1-2",
              label: "Open Balance",
              icon: IconSettings,
            },
            { key: "t1-3", label: "Bids", icon: IconSettings },
            { key: "t1-4", label: "Clients", icon: IconSettings },
            {
              key: "t1-6",
              label: "Confirmation",
              icon: IconSettings,
            },
          ],
        },
        {
          key: "section-detail",
          parent: "t1-1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Leave Details",
            mt: 0,
            ml: "xl",
          },
        },
        {
          key: "section-balance",
          parent: "t1-2",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "User Opening Balance",
            mt: 0,
            ml: "xl",
          },
        },

        {
          key: "section-bid",
          parent: "t1-3",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "User Bids",
            mt: 0,
            ml: "xl",
          },
        },

        {
          key: "section-client",
          parent: "t1-4",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Clients",
            mt: 0,
            ml: "xl",
          },
        },

        // {
        //     key: "section-team",
        //     parent: "t1-5",
        //     type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        //     props: {
        //         title: "Team Members",
        //         mt: 0,
        //         ml: "xl",
        //     },
        // },

        {
          key: "section-confirm",
          parent: "t1-6",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Confirm Policy",
            description: "Please confirm to terminate the user",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },
      ],

      fields: [
        {
          name: "resignDate",
          component: DatePicker,
          type: "datePicker",
          parent: "section-detail",
          props: {
            required: true,
            label: "User Resign Date",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },

        {
          name: "effectiveDate",
          component: DatePicker,
          type: "datePicker",
          parent: "section-detail",
          props: {
            required: true,
            label: "Effective Date",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },
        {
          name: "lastEmploymentDate",
          component: DatePicker,
          type: "datePicker",
          parent: "section-detail",
          props: {
            required: true,
            label: "Last Employment Date",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },

        {
          name: "reason",
          component: Textarea,
          parent: "section-detail",
          props: {
            label: "Reason",
          },
        },
        {
          name: "balance",
          component: UserLeaveOpeningBalance,
          parent: "section-balance",
          props: {},
        },
        {
          name: "bids",
          component: UserLeaveNonReleaseBid,
          parent: "section-bid",
          props: {},
        },

        {
          name: "consultantClients",
          component: UserLeaveClient,
          parent: "section-client",
          props: {},
        },

        {
          name: "consultantClients",
          component: FormConfirmation,
          parent: "section-confirm",
          props: {},
        },
      ],
    },
  };

  const status = _.get(mainForm.values, "status");

  const onSubmit = async ({ values, formMode }) => {
    try {
      if (!values.userId || !values.user) return;

      delete values.user;
      // console.log("onSubmit", values);

      const result = await api.User.terminate(values);
      // console.log(result);

      if (result && result.success) {
        mainForm.setFieldValue("status", result?.data?.user.status);
        showNotification({
          title: `User Terminated.`,
          icon: <IconCheck size={18} />,
          message: "Data Saved",
        });
        formAction.close();
      } else throw new Error(result.error);
    } catch (error) {
      showNotification({
        title: `User can't be terminated.`,
        icon: <IconAlertCircle size={18} />,
        message: error.message,
      });
    }
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    null,
    onSubmit
  );

  const handleClick = async () => {
    const user = mainForm?.values;
    if (!user) return;

    const userLeave = {
      userId: user._id,
      user,
      consultantClients: [],
    };
    formAction.open({ mode: "edit", data: userLeave });
  };

  const { t } = useTranslation();
  return (
    <>
      {/* Status: {clientStatus} */}
      <Button
        fullWidth
        onClick={handleClick}
        color={"red"}
        // disabled={status === "TERMINATED"}
      >
        {t("Resign")}
      </Button>
      {renderForm()}
    </>
  );
};

export default UserLeaveActionButton;
