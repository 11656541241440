import React from "react";
import InfoGridList from "./infoGridList";
import { useCellRender } from "../hooks/useCellRender";
import { DATA_DN_STATUS } from "../data/options";
import { Alert, Group } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons";
import _ from "lodash";
import moment from "moment";
import { useAuthUser } from "react-auth-kit";
import ReactJson from "react-json-view";

export default function DebitNoteRelatedDocumentSection({ form, name }) {
  const [cellRender] = useCellRender();
  const dn = form.values;
  const auth = useAuthUser();
  const status = DATA_DN_STATUS.find((s) => s.value === dn?.status);
  const userRole = auth().userRole;
  // console.log(userRole);
  const orderLink =
    userRole.entityLinks.find((l) => l.entity === "order")?.link ?? "/gi/order";

  const renewalLink =
    userRole.entityLinks.find((l) => l.entity === "renewal")?.link ??
    "/gi/renewal";

  const quotationLink =
    userRole.entityLinks.find((l) => l.entity === "quotation")?.link ??
    "/gi/quotation";

  const fields = [
    {
      title: "Quotation",
      value: dn?.quotation ? (
        <a
          href={`/gi/quotation?id=${
            dn.quotation?._id ?? dn.quotation
          }&mode=${"edit"}`}
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          {dn.quotation?.code}
        </a>
      ) : (
        "-"
      ),
    },
    {
      title: "Renewal",
      value: dn?.renewal ? (
        <a
          href={`${renewalLink}?id=${dn.renewal?._id}&mode=${"edit"}`}
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          {dn.renewal?.code}
        </a>
      ) : (
        "-"
      ),
    },
    {
      title: "Order",
      value: dn?.order ? (
        <a
          href={`${orderLink}?id=${dn.order?._id}&mode=${"edit"}`}
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          {dn.order?.code}
        </a>
      ) : (
        "-"
      ),
    },
  ];

  return (
    <>
      {/* <ReactJson
        src={form.values}
        collapsed={true}
        style={{ background: "white" }}
      /> */}
      {dn && <InfoGridList fields={fields} values={dn} />}
    </>
  );
}
