import { Page } from "../layout/page";
import { Accordion, Title } from "@mantine/core";
import React, { useState, useEffect } from "react";
import CpdIAReport from "../components/cpdIAReport";
import CpdMPFReport from "../components/cpdMPFReport";
import ReportDebitNote from "../components/reportDebitNote";
import ReportOrder from "../components/reportOrder";
import ReportService from "../components/reportService";
import ReportUser from "../components/reportUser";
import ReportUserNameCard from "../components/reportUserNameCard";
import ReportProduct from "../components/reportProduct";
import ReportCommissionStatement from "../components/reportCommissionStatement";
import ReportWallet from "../components/reportWallet";
import { useTranslation } from "react-i18next";
import ReportProviderPortal from "../components/reportProviderPortal";
import ReportClient from "../components/reportClient";
import ReportIALongTermPolicies from "../components/reportIALongTermPolicies";
import ReportIAMPFAcitivity from "../components/reportIAMPFAcitivity";
import ReportRenewal from "../components/reportRenewal";

const Report = ({}) => {
  const { t } = useTranslation();

  const reports = [
    {
      title: t("CPD IA Report"),
      reportComponent: CpdIAReport,
    },
    {
      title: t("CPD MPF Report"),
      reportComponent: CpdMPFReport,
    },
    {
      title: t("GI Debit Note Report"),
      reportComponent: ReportDebitNote,
    },
    {
      title: t("GI Renewal Report"),
      reportComponent: ReportRenewal,
    },
    {
      title: t("Order Report"),
      reportComponent: ReportOrder,
    },
    {
      title: t("Order Service Report"),
      reportComponent: ReportService,
    },
    {
      title: t("User Report"),
      reportComponent: ReportUser,
    },
    {
      title: "Name Card Report",
      reportComponent: ReportUserNameCard,
    },
    {
      title: t("Product Report"),
      reportComponent: ReportProduct,
    },

    {
      title: t("Commission Statement Report"),
      reportComponent: ReportCommissionStatement,
    },

    {
      title: t("Wallet Report"),
      reportComponent: ReportWallet,
    },
    {
      title: "Provider Portal Login Report",
      reportComponent: ReportProviderPortal,
    },

    {
      title: "Client Report",
      reportComponent: ReportClient,
    },
    {
      title: "IA Encl 2 Report - Long Term Policies",
      reportComponent: ReportIALongTermPolicies,
    },
    {
      title: "IA Encl 10 Report - List of MPF Regulated Activities",
      reportComponent: ReportIAMPFAcitivity,
    },
  ];

  return (
    <Page title={t("Report")}>
      <Accordion
        variant="separated"
        radius="xs"
        defaultValue="customization"
        mt={"xl"}
      >
        {reports.map((r) => (
          <Accordion.Item value={r.title}>
            <Accordion.Control>
              <Title order={5}>{t(r.title)}</Title>
            </Accordion.Control>
            <Accordion.Panel>
              <r.reportComponent />
            </Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>
    </Page>
  );
};

export default Report;
